/*features*/
/*accounts*/
#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 330px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: block;
		width: 100%;
		text-align: left;
	}

	#account_module.form tr.required .label label:after {
		content: url("/core/public/shared/assets/images/required.gif");
		padding-left: 10px;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

/*events*/
.content .contentbox_item_image {
	width: 20px;
}

#events_module .category-wrapper {
	display: inline-block;
}

#events_module .event-category-label {
	display: inline-block;
	margin-right: 7px;
	margin-bottom: 0;
}

#events_module #fullcalendar-controls #categories {
	float: none;
}

#events_module.cms_list .cms_title h3 {
	margin-top: 0;
	margin-bottom: 10px;
	font-size: 1.35rem;
}

#events_module.cms_list .cms_list_item .cms_title {
	float: none;
	font-weight: 500;
	line-height: 1.25em;
	width: 100%;
}

#events_module.cms_list .cms_date {
	font-weight: 500;
}

#events_module.cms_list .location-label-events-module {
	font-weight: 500;
}

#events_module.cms_list div.events_module_calendar .fc-view-container .fc-event {
	border-bottom: 1px solid #000000;
}

#events_module.cms_list .cms_list_item .cms_date {
	font-size: 1rem;
	margin-top: 10px;
}

#events_module.cms_list .back-link {
	display: none;
}

#events_module.cms_form .cms_label {
	background-color: transparent;
	vertical-align: top;
}

#events_module.cms_form .cms_required {
	background-color: transparent;
	background-position: center 8px;
}

#events_module.cms_form .cms_non_required {
	background-color: transparent;
}

#events_module.cms_form .cms_field {
	background-color: transparent;
}

#events_module.cms_form textarea {
	resize: vertical;
}

#events_module.cms_entity .cms_contact_name_label,
#events_module.cms_entity .cms_contact_phone_label,
#events_module.cms_entity .cms_location_label {
	font-weight: 500;
}

#events_module.cms_entity .event-categories-label {
	color: #333;
	font-weight: 500;
}

#events_module.cms_list div.events_module_calendar .fc-view-container .fc-event .fc-time {
	font-weight: normal;
}

#events_module.cms_list .fc-daygrid .fc-daygrid-day-number {
	color: #000;
}

#events_module.cms_list .cms_divider {
	margin-bottom: 1rem;
	margin-top: 1rem;
}

@media (max-width: 991px) {
	#events_module.cms_form .cms_label {
		width: auto;
		display: inline-block;
		text-align: left;
	}

	#events_module.cms_form .cms_field {
		width: 100%;
		display: block;
	}

	#events_module.cms_form .cms_required {
		background-position: center 50%;
		width: 15px;
		display: inline-block;
	}

	#events_module.cms_form .cms_non_required {
		display: inline-block;
	}

	#events_module.cms_form .cms_field input[type="text"] {
		width: 100% !important;
	}
}

@media (max-width: 767px) {
	#events_module.cms_form .cms_info_req {
		float: none;
		margin-bottom: 10px;
		text-align: right;
	}
}

/*faqs*/
#faqs_module .cms_list_item ul,
#faqs_module .cms_list_subitem ul {
	color: #296f9e;
	list-style-type: disc;
}

#faqs_module.cms_list .cms_list_item:first-of-type {
	padding-top: 0;
}

#faqs_module.cms_list .cms_list_item:first-of-type .cms_title h3,
#faqs_module.cms_list .cms_list_subitem:first-of-type .cms_title h3 {
	margin-top: 0;
}

.box.tools .content .contentbox_item form input[type="text"] {
	width: 100% !important;
}

.box.tools .content .contentbox_item form > p {
	margin-bottom: 0;
}

.box.products .content a {
	display: block;
	margin-bottom: 5px;
}

#faqs_module.cms_list .cms_list_item .description,
#faqs_module.cms_list .cms_list_subitem .description {
	padding-top: 0;
}

#faqs_module.cms_list .cms_list_item .cms_title h2 {
	font-size: 30px;
}

#faqs_module.cms_list .cms_list_subitem {
	padding-bottom: 5px;
}

/*files*/
.field--category--title {
	background-position: 0 4px;
}

.field--category--title {
	padding-left: 22px !important;
}

.field--file--title {
	padding-left: 22px !important;
	background-position: 0 4px !important;
}

.field.field--category.field--category--title p,
.field.field--file.field--file--description p {
	margin-bottom: 0;
}

.collection.collection--categories > .node,
.collection.collection--files > .node {
	border-top: 1px solid #65aedf;
	margin: 1.5em 0 0;
	padding: 1.5em 0 0;
}

.collection.collection--categories + .collection.collection--files {
	border-top: 0 none;
	margin: 0;
}

.box.filter #filter-title {
	background-color: #fff;
	border: 1px solid #999999;
	border-radius: 3px;
	box-shadow: 0 0 6px #cccccc inset;
	color: #666;
	min-height: 32px;
	padding: 7px 10px 7px 5px;
}

.box.filter .select2-container-multi .select2-choices .select2-search-field .select2-input {
	min-height: 32px;
}

.box.filter .select2-container-multi .select2-choices .select2-search-field input {
	font-size: 0.9rem;
}

.box.filter .select2-container,
.box.filter #filter-title {
	font-size: 0.9rem;
}

.box.filter .select2-container,
.box.filter #filter-title {
	width: 100% !important;
	max-width: none;
}

.box.filter .select2-container a:hover {
	color: #444;
	text-decoration: none;
	border-bottom-color: #aaa;
}

.box.filter .select2-container .select2-choice {
	height: 34px;
}

.box.filter .select2-container .select2-choice > .select2-chosen {
	line-height: 1.988rem;
}

.box.filter .select2-container .select2-choice .select2-arrow b {
	background-position: 0 4px;
}

.box.filter .select2-container-multi .select2-choices {
	padding: 0;
}

.box.filter .ui-form-buttons {
	padding: 0;
}

/*jobs*/
#jobs_module.cms_list .cms_list_item .cms_footer {
	margin-top: 1.5em;
	margin-bottom: 0;
	border-top: 1px solid #cccfd3;
}

#jobs_module.cms_list .cms_list_item {
	margin-top: 0;
}

#jobs_module.cms_list .cms_list_item:first-of-type {
	margin-top: 0;
}

#jobs_module.cms_list .cms_list_item:first-of-type h3 {
	margin-top: 0;
}

#jobs_module.cms_list .cms_list_item h3 {
	margin-top: 1.275em;
}

#jobs_module.cms_list .cms_list_item .cms_date {
	display: none;
}

#jobs_module.cms_entity .cms_content .cms_date {
	display: none;
}

#jobs_module.cms_entity .cms_content dt {
	font-weight: 500;
}

#jobs_module.cms_list .cms_list_item .cms_date h3 {
	color: #6c6c6c;
	font-size: 0.95rem;
	font-weight: 700;
}

#jobs_module .form-fields {
	max-width: 100%;
}

#jobs_module.cms_form .cms_info_req {
	text-align: right;
	float: none;
	margin-bottom: 20px;
}

#jobs_module.cms_form input[type="text"] {
	width: 260px !important;
}

#jobs_module.cms_form form .cms_label {
	width: 30%;
	background-color: transparent;
}

#jobs_module.cms_form form .cms_required {
	width: 5%;
	background-color: transparent;
	padding-top: 0;
}

#jobs_module .cms_divider {
	display: none;
}

#jobs_module.cms_form form .cms_field {
	background-color: transparent !important;
}

#jobs_module div.application-for-employment .form-field {
	max-height: none;
	min-height: 0;
	margin-top: 0;
	margin-bottom: 0;
	padding: 0;
	border: 0 none;
	border-radius: 0;
}

#jobs_module div.application-for-employment form div.form-fields {
	margin: 0;
}

#jobs_module div.application-for-employment input[type="text"],
#jobs_module div.application-for-employment form div.form-field span.text input {
	height: 30px !important;
	font-size: 1rem !important;
}

#jobs_module div.application-for-employment form div.form-field select {
	height: 30px !important;
}

#jobs_module.cms_form .cms_error {
	color: #333;
}

#jobs_module div.application-for-employment form div.form-field label {
	font-weight: 500;
}

#jobs_module div.application-for-employment div.tabs ul li.active {
	font-weight: 500;
}

#jobs_module.cms_entity .quaternary_title {
	font-size: 30px;
	line-height: 35px;
	margin-bottom: 10px;
}

#jobs_module.cms_form .cms_form_button {
	padding: 9px 16px;
}

@media (max-width: 991px) {
	#jobs_module.cms_form input[type="text"] {
		display: block;
		width: 100% !important;
		max-width: 100%;
	}

	#jobs_module.cms_form form .cms_label {
		display: inline-block;
		text-align: left;
		width: auto;
	}

	#jobs_module.cms_form form .cms_required {
		display: inline-block;
	}

	#jobs_module.cms_form form .cms_field {
		display: block;
		width: 100%;
	}

	#jobs_module.cms_form form p[align="center"] {
		text-align: left !important;
	}

	#jobs_module div.application-for-employment * {
		width: 100% !important;
		padding-right: 10px;
	}

	#jobs_module div.application-for-employment .tabs,
	#jobs_module div.application-for-employment form,
	#jobs_module div.application-for-employment .step {
		padding-right: 0;
	}

	#jobs_module div.application-for-employment button {
		width: auto !important;
	}
}

#jobs_module div.application-for-employment div.tabs ul li {
	color: #2d2e31;
}

.right-side-col .box .content .contentbox_item tr td {
	padding-top: 0.5em;
	padding-bottom: 0.75em;
}

.right-side-col .departments_filter {
	width: 100%;
	max-width: 200px;
}

/*links*/
#links_module ul {
	color: #296f9e;
}

#links_module ul div {
	color: #333;
}

#links_module strong {
	font-weight: 500;
}

/*locations*/
@media (max-width: 991px) {
	#locations_module.list .locations_box {
		width: 100%;
		display: block;
		padding-right: 0;
	}

	#locations_module.list .locations_box .list_item {
		width: 100%;
		display: block;
		border-bottom: 1px solid #65aedf;
		margin-bottom: 0;
	}

	#locations_module.list .locations_box .column_separator {
		display: none;
	}

	#locations_module.list .locations_box .separator {
		display: none;
	}
}

/*news*/
#news_module.cms_list article h2 {
	margin-bottom: 10px;
}

#news_module.cms_list article time {
	font-weight: 500;
}

#news_module.cms_entity .cms_metadata1.cms_title {
	color: #296f9e;
	font-size: 1.6em;
	font-weight: 500;
	line-height: 1.25em;
	margin-bottom: 20px;
	margin-top: 0;
	padding: 0;
}

.pagination-wrapper .section_separator {
	border-bottom: 1px solid #65aedf;
}

.pagination-wrapper a {
	font-weight: 500 !important;
}

.pagination-wrapper span {
	font-weight: 500 !important;
	background-color: #fff;
}

@media (max-width: 767px) {
	.pagination-wrapper td {
		display: block;
		width: 100%;
		text-align: center;
	}
}

/*newsletters*/
#newsletters_module.list .title h3 {
	margin-top: 0;
	margin-bottom: 10px;
}

#newsletters_module.entity .item_divider + h3 + h4,
#newsletters_module.entity h3:first-of-type + h4 {
	font-size: 1em;
	color: #333;
	margin-top: 10px !important;
}

#newsletters_module.form .instructions {
	font-weight: 500;
}

#newsletters_module.form .moduleform_divider {
	display: none;
}

#newsletters_module.form .form_item td {
	padding-bottom: 15px;
}

#newsletters_module.list .date_issued {
	margin-top: 30px;
	margin-bottom: 20px;
	color: #296f9e;
	font-size: 1.6em;
	line-height: 1.25em;
	font-weight: 500;
}

#newsletters_module.entity h3 {
	font-size: 30px;
}

@media (max-width: 991px) {
	#newsletters_module.form .form_item.required .field {
		background: 0 none;
		display: block;
		width: 100%;
		padding-left: 0;
		max-width: 400px;
	}

	#newsletters_module.form .form_item.required .label {
		display: block;
		width: 100%;
		text-align: left;
		margin-bottom: 5px;
	}

	#newsletters_module.form .form_item.required .label label::after {
		padding-left: 5px;
		content: url("/core/public/shared/assets/images/required.gif");
	}

	#newsletters_module.form .form_buttons {
		text-align: left;
	}
}

/*partners*/
#partners_module td.image img {
	width: 150px;
	height: auto;
}

#partners_module h3 {
	margin-top: 0;
}

@media (max-width: 767px) {
	#partners_module td {
		display: block;
		width: 100% !important;
	}

	#partners_module td.image {
		margin-bottom: 10px;
	}
}

@media (max-width: 479px) {
	#partners_module.cms_entity .image {
		float: none;
	}

	#partners_module.cms_entity .image img {
		width: 100% !important;
		height: auto !important;
	}
}

.box.partner-document .content .contentbox_item {
	margin-bottom: 5px;
}

.box.partner-document .contentbox_item_image {
	width: 20px;
}

#partners_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0rem;
}

#partners_module.cms_list .cms_list_item table td.image {
	padding-right: 25px;
}

#partners_module.cms_list .cms_list_item table td.image a img {
	max-width: 150px;
}

#partners_module.cms_entity .image {
	float: none;
	margin-right: 0px;
}

#partners_module.cms_list .cms_item_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

@media (max-width: 991px) {
	#partners_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#partners_module.cms_list .cms_list_item table td.image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/*posts*/
.collection.posts-collection > .node {
	border-top: 1px solid #65aedf;
	margin: 1.5em 0 0;
	padding: 1.5em 0 0;
}

.collection.posts-collection .node.posts-node .field.posts-field.posts-field-image {
	margin-bottom: 20px;
}

.post-details-image {
	margin-bottom: 10px;
}

.posts-share {
	margin-top: 25px;
	margin-bottom: 5px;
	margin-left: -5px;
}

.collection.posts-collection .node.posts-node .field.posts-field.posts-field-image img {
	max-height: none;
}

.collection.posts-collection > .node:first-child {
	border-top: 0 none;
	margin-top: 0;
	padding-top: 0;
}

.collection.posts-collection .field.posts-field h2 {
	margin-top: 0;
	margin-bottom: 10px;
}

.collection.posts-collection .field.posts-field.posts-field-categories strong,
.collection.posts-collection .field.posts-field.posts-field-tags strong,
.node.posts-node .field.posts-field.posts-field-categories strong,
.node.posts-node .field.posts-field.posts-field-tags strong {
	font-weight: 500;
}

#posts_module.form .form_item .ui-form-input input {
	width: 265px;
}

.node.posts-node .field.posts-field.posts-field-posted {
	font-weight: 500;
}

.posts-node .post-comments-title {
	font-weight: 500;
}

.posts-node .post-comment {
	border-bottom: 1px solid #65aedf;
	padding: 20px 5px;
}

.posts-node .post-comment-author {
	font-size: 0.825rem;
}

.posts-node .posts-comments .ui-form-fieldset legend.ui-form-legend {
	font-weight: 500;
	margin-top: 20px;
}

#posts_module.form .ui-form-buttons {
	margin-top: 0;
}

.posts-blocks.posts-blocks-searches .posts-blocks-search-form input.posts-blocks-search-field[type="text"] {
	height: 39px;
}

.posts-blocks.posts-blocks-searches .posts-blocks-search-form .posts-blocks-search-button {
	border-radius: 0;
	padding: 5px 4px 4px 4px !important;
}

.posts-blocks.posts-blocks-rss-feeds .posts-blocks-rss-feed .posts-blocks-rss-feed-link {
	text-decoration: none;
}

.posts-blocks.posts-blocks-rss-feeds .posts-blocks-rss-feed .posts-blocks-rss-feed-link svg,
.posts-blocks.posts-blocks-subscribes .posts-blocks-subscribe svg,
#posts_module .rss_link_image svg {
	fill: currentColor;
}

.posts-blocks-popular-post,
.posts-blocks-author-name,
.posts-blocks-category {
	margin-bottom: 10px;
}

.collection.posts-collection .field.posts-field h2 {
	font-size: 30px;
	line-height: 35px;
}

.posts-blocks.posts-blocks-searches .posts-blocks-search-form .posts-blocks-search-button svg {
	width: 25px;
	height: 26px;
}

.posts-node .post-comments-title {
	background-color: #001446;
}

/*resources*/
#moduleDocuments_module .folder .icon {
	margin-bottom: 2px;
}

#moduleDocuments_module .cms_items .cms_description {
	background-position: left 4px;
}

#moduleDocuments_module .cms_items .cms_description h4 {
	margin-top: 0;
}

#moduleDocuments_module.cms_list .cms_link {
	color: #296f9e;
}

/*services OK*/

/*staff directory*/
#staffdirectory_module.cms_list .cms_list_subheading {
	color: #296f9e;
	font-size: 1.6em;
	font-weight: 500;
	line-height: 1.25em;
	margin-bottom: 20px;
}

#staffdirectory_module.cms_list .cms_list_item {
	display: grid;
	grid-template-columns: 1fr 3fr;
}

#staffdirectory_module.cms_list .cms_list_item .image {
	text-align: center;
	align-self: self-start;
}

#staffdirectory_module.cms_list .cms_list_item .info {
	width: auto;
	height: 100%;
	margin-left: 30px;
	margin-bottom: 0;
	font-size: inherit;
	padding: 0 10px;
	background-color: unset;
	position: inherit;
}

#staffdirectory_module.cms_list .cms_list_item .info::before {
	content: unset;
}

#staffdirectory_module.cms_entity .cms_content {
	overflow: hidden;
}

#staffdirectory_module.cms_entity .cms_metadata2.cms_title {
	font-weight: 500;
	clear: both;
}

#staffdirectory_module.cms_entity .cms_metadata1.cms_title h3 {
	margin-top: 0;
}

#staffdirectory_module.cms_list .category_header {
	font-size: 35px;
	line-height: 38px;
}

@media (max-width: 767px) {
	#staffdirectory_module.cms_list td {
		width: 100%;
		display: block;
	}

	#staffdirectory_module.cms_list td.image {
		padding-bottom: 10px;
	}

	#staffdirectory_module.cms_list .cms_list_item {
		display: flex;
	}

	#staffdirectory_module.cms_list .cms_list_item .info {
		width: 100%;
		text-align: center;
		margin-left: 0;
		padding: 10px 0;
	}

	#staffdirectory_module.cms_list .cms_list_item .info li {
		text-align: left;
	}
}


/*submission forms*/
#submissionforms_module.cms_form .cms_field,
#submissionforms_module.cms_form .cms_label {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .formSection {
	margin-bottom: 15px;
	background-color: #eee;
	font-weight: 400;
	color: #296f9e;
	font-size: 1.25em;
	font-weight: 500;
	line-height: 1.25em;
	padding: 6px 5px;
}

#submissionforms_module.cms_form .cms_steps_wrapper {
	margin-top: 10px;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last {
	height: 41px;
}

#submissionforms_module.cms_form .cms_steps_wrapper .active {
	background-color: #d1d0ce;
}

#submissionforms_module.cms_form .cms_steps_wrapper .active::before {
	height: auto;
	border-bottom: 21px solid transparent;
	border-left: 19px solid #d1d0ce;
	border-top: 21px solid transparent;
}

#submissionforms_module.cms_form .cms_steps_wrapper .active::after {
	border-bottom: 21px solid transparent;
	border-left: 20px solid #848482;
	border-top: 21px solid transparent;
	left: calc(100% + 1px);
}

#submissionforms_module.cms_form .cms_steps_wrapper .inactive::before {
	height: auto;
	border-bottom: 21px solid transparent;
	border-left: 19px solid #f6f6f6;
	border-top: 21px solid transparent;
}

#submissionforms_module.cms_form .cms_steps_wrapper .inactive::after {
	border-bottom: 21px solid transparent;
	border-left: 20px solid #848482;
	border-top: 21px solid transparent;
	left: calc(100% + 1px);
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding: 10px 25px 10px 38px;
	font-weight: 500;
}

#submissionforms_module.cms_form .cms_steps_wrapper span a {
	border-bottom: 0 none;
}

#submissionforms_module.cms_form form {
	margin-top: 15px;
}

#submissionforms_module .cms_hint {
	display: block;
}

#submissionforms_module.cms_form .cms_field .ui-datepicker-trigger {
	vertical-align: text-top;
}

.pad {
	border-right: 1px solid #cccccc;
	border-bottom: 1px solid #cccccc;
}

.sigNav a,
.sigNav a:link,
.sigNav a:visited {
	color: #6c6c6c !important;
	font-weight: 400 !important;
}

#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary {
	margin-right: 20px;
}

#submissionforms_module.cms_form .sigPad input,
#submissionforms_module.cms_form .pad {
	border-color: #333;
}

@media (max-width: 767px) {
	#submissionforms_module.cms_form .cms_buttons {
		text-align: left !important;
		padding-left: 5px !important;
	}

	#submissionforms_module tr[data-required="true"] .cms_label::after {
		content: none;
	}

	#submissionforms_module tr[data-required="true"] .cms_label div::after,
	#submissionforms_module tr[data-required="true"] .cms_label label::after {
		content: "*";
		color: #ee0000;
		padding-left: 5px;
	}

	#submissionforms_module tr[data-required="true"] .cms_label::after {
		content: "";
		display: none;
	}
}

@media (max-width: 479px) {
	#submissionforms_module.cms_form .cms_buttons {
		white-space: nowrap;
		padding-left: 0 !important;
	}

	#submissionforms_module.cms_form .cms_buttons .cms_form_button:first-of-type {
		margin-right: 10px;
	}
}

/*testimonials*/
#testimonials_module.cms_list .testimonial-link {
	border-bottom: 0 none;
}

#testimonials_module.cms_entity .cms_content .image {
	float: left;
	margin-left: 0;
	margin-right: 15px;
	margin-bottom: 10px;
}

#testimonials_module.cms_entity .cms_item_divider,
#testimonials_module.cms_list .cms_item_divider {
	margin-top: 1rem;
	margin-bottom: 1.2rem;
}

#testimonials_module.cms_list .cms_list_item .cms_author_name {
	font-weight: 500;
}

/*videos*/
#video_module.cms_list {
	margin-bottom: 20px;
}

#video_module.cms_list .cms_list_item {
	padding-bottom: 1.5em;
	margin-bottom: 1.5em;
	border-bottom: 1px solid #65aedf;
}

#video_module.cms_list .cms_list_item td.cms_video {
	padding-right: 20px;
	width: 150px;
}

#video_module.cms_list_carousel h2 {
	font-size: 35px;
	line-height: 40px;
}

@media (max-width: 767px) {
	#video_module.cms_list .cms_list_item td {
		display: block;
		width: 100%;
	}

	#video_module.cms_list .cms_list_item td.cms_video {
		padding-right: 0;
		padding-bottom: 10px;
	}
}

.right-side-col .box.categories .content br {
	margin-bottom: 10px;
}


/*dynamic content boxes*/

/*events*/
#events_module.homepage_contentbox {
	text-align: center;
}

#events_module.homepage_contentbox > br {
	display: none;
}

#events_module.homepage_contentbox td {
	display: block;
	width: 100%;
}

#events_module.homepage_contentbox .date {
	font-weight: 500;
	margin-bottom: 10px;
}

#events_module.homepage_contentbox .title {
	margin-bottom: 20px;
}

#events_module.homepage_contentbox .title a {
	font-weight: 500;
	font-size: 1.35em;
	line-height: 1.25em;
	color: #296f9e;
}

#events_module.homepage_contentbox .title div:not(.cms_description) {
	margin-bottom: 10px;
}

@media (max-width: 479px) {
	#events_module.homepage_contentbox {
		text-align: left;
	}
}

/*files*/
.home-content .collection.files-collection {
	text-align: center;
}

@media (max-width: 479px) {
	.home-content .collection.files-collection {
		text-align: left;
	}
}

#moduleDocuments_module.cms_list .cms_category_icon_title_row .cms_title {
	font-size: 33px;
	line-height: 35px;
}

#moduleDocuments_module.cms_list .cms_item_icon_title_row .cms_item_title {
	font-size: 28px;
	line-height: 30px;
}

/*news*/
#news_module.homepage_contentbox {
	text-align: center;
}

#news_module.homepage_contentbox .cms_list_item .cms_metadata1.cms_title h3 {
	margin-top: 0;
	margin-bottom: 10px;
	font-weight: 500;
	font-size: 1.35em;
	line-height: 1.25em;
	color: #296f9e;
}

#news_module.cms_list article h2 {
	font-size: 30px;
	line-height: 35px;
}

#news_module.cms_entity .cms_item_divider,
#news_module.cms_list .cms_item_divider {
	margin-bottom: 0.5rem;
	margin-top: 1.2rem;
}

@media (max-width: 479px) {
	#news_module.homepage_contentbox {
		text-align: left;
	}
}

/*partners OK*/

/*posts (default)*/
.home-content .collection.posts-collection {
	text-align: center;
}

.home-content .collection.posts-collection .node.posts-node .field.posts-field:first-of-type a {
	margin-top: 0;
	margin-bottom: 10px;
	font-weight: 500;
	font-size: 1.35em;
	line-height: 1.25em;
	color: #296f9e;
}

@media (max-width: 479px) {
	.home-content .collection.posts-collection {
		text-align: left;
	}
}

/*submission forms*/
#submissionforms_module.homepage_contentbox label {
	display: inline;
}

#submissionforms_module.homepage_contentbox .cms_label label {
	font-weight: 500;
}

#submissionforms_module.homepage_contentbox .ui-datepicker-trigger {
	vertical-align: baseline;
}

#submissionforms_module.homepage_contentbox .cms_field input[type="text"],
#submissionforms_module.homepage_contentbox .cms_field input[type="tel"],
#submissionforms_module.homepage_contentbox .cms_field input[type="password"],
#submissionforms_module.homepage_contentbox .cms_field input[type="email"],
#submissionforms_module.homepage_contentbox .cms_field input[type="date"],
#submissionforms_module.homepage_contentbox .cms_field select,
#submissionforms_module.homepage_contentbox .cms_field textarea {
	width: 100%;
}

#submissionforms_module.homepage_contentbox .cms_field input.hasDatepicker[type="text"] {
	width: calc(100% - 20px);
}

@media (min-width: 768px) {
	#submissionforms_module tr[data-required="true"] .cms_label div::after,
	#submissionforms_module tr[data-required="true"] .cms_label label::after {
		content: none;
	}
}

/*testimonials*/
#testimonials_module_contentbox.contentbox_item {
	text-align: center;
}

#testimonials_module_contentbox.contentbox_item .image-box {
	margin-bottom: 10px;
}

#testimonials_module_contentbox.contentbox_item .author-name {
	color: #296f9e;
	font-size: 1.25em;
	font-weight: 500;
	line-height: 1.5em;
	margin-top: 10px;
}

#testimonials_module_contentbox.contentbox_item .company {
	margin-top: 10px;
	color: #333;
	font-weight: 500;
}

@media (max-width: 479px) {
	#testimonials_module_contentbox.contentbox_item {
		text-align: left;
	}
}

/*videos*/
.homepage-featured-video {
	text-align: center;
	float: none;
	clear: both;
	width: 100%;
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 25px;
	height: 0;
}

.homepage-featured-video .cbTitle {
	display: none;
}

.homepage-featured-video iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.homepage-featured-video .moreVideos {
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	margin-top: 20px;
}

@media (max-width: 479px) {
	.homepage-featured-video {
		text-align: left;
	}
}

/* GDPR Module */
#cookie-consent-container .consent-actions a.consent-all-trigger {
	background-color: #00a7e1 !important;
	border-color: #00a7e1 !important;
}

#cookie-consent-container .consent-actions a.consent-all-trigger:hover {
	background-color: var(--dark-slate-grey) !important;
	border-color: var(--dark-slate-grey) !important;
}

#cookie-consent-container .consent-actions a.reject-all-trigger:hover,
#cookie-consent-container .consent-actions a.consent-all-trigger:hover {
	color: #fff !important;
}

#cookie-consent-container .consent-actions a.consent-more-trigger {
	border-bottom: 1px solid transparent;
}

.collection.search-collection {
	margin-top: 20px;
}

.collection.search-collection > .node {
	margin: 20px 0 0 0;
	padding: 20px 0 0 0;
}

/* solutions */
.solutions-container .breadcrumbs-div,
.solutions-container .content-container.full {
	max-width: 1450px;
}

.solutions-container .content-2 {
	max-width: 100%;
}

.solutions-container.action-show .content-container.full,
.solutions-container.action-show .content-2 {
	max-width: none;
	padding-left: 0px;
	padding-right: 0px;
}

.solutions-container.action-show .breadcrumbs-div {
	max-width: 1450px;
	padding-left: 4rem;
	padding-right: 4rem;
}

.solutions-container.action-show .bfc,
.solutions-container.action-show #solutions-module.node .solution-cards,
.solutions-container.action-show #solutions-module.node .solution-overview,
.solutions-container.action-show .example {
	max-width: 1450px;
	padding-left: 4rem;
	padding-right: 4rem;
	margin-left: auto;
	margin-right: auto;
}

#solutions-module.node .solution-overview .solution-intro {
	width: 100%;
}

#solutions-module.node .collection.solutions-features .node {
	display: block;
	margin: 0 auto;
	padding: 0 0;
	position: relative;
	z-index: 1;
	max-width: none;
	margin-left: 0;
	margin-right: 0;
}

#solutions-module.node .collection.solutions-features.single .node,
 
#solutions-module.node .collection.solutions-features.multiple .node.even {
	background-color: rgba(230, 235, 245, 0.2);
}

#solutions-module.node .collection.solutions-features.single .solution-card-body-description,
 
#solutions-module.node .collection.solutions-features.multiple .node.even .solution-card-body-description {
	background-color: rgba(230, 235, 245, 0.65);
}

.solutions-features.single .solution-feature-node::before {
	content: " ";
	display: block;
	background-color: #fff;
	background-image: url('/assets/images/solutions-divider-slant.svg');
	background-position: 0 0;
	background-size: cover;
	height: 110px;
	margin-bottom: -1px;
}

.solutions-features.single .solution-feature-node::after {
	content: " ";
	display: block;
	background-color: #fff;
	background-image: url('/assets/images/solutions-divider-slant.svg');
	background-position: 0 0;
	background-size: cover;
	height: 110px;
	margin-bottom: -1px;
	transform: rotate(180deg);
}

.solutions-features.single .solution-feature-node-divider {
	display: none;
}

#solutions-module.node .example,
 
.module-solutions.controller-pages-categories.action-show .solution-category-additionalInfo {
	text-align: center;
}

#solutions-module.node .solution-cards .solution-card-header {
	margin-bottom: 0;
	margin-top: 1rem;
}

#solutions-module.node .solution-cards .solution-card-header strong {
	font-weight: 500;
}

#solutions-module h2 {
	font-size: 30px;
	line-height: 35px;
}

#solutions-module.collection .node .solution-node-summary .solution-node-summary-more-details {
	font-weight: 500;
}

@media screen and (max-width: 991px) {
	.solutions-container.action-show .bfc,
	.solutions-container.action-show #solutions-module.node .solution-cards,
	.solutions-container.action-show #solutions-module.node .solution-overview,
	.solutions-container.action-show .example {
		padding-left: 2.5rem;
		padding-right: 2.5rem;
	}

	.solution-feature-node .solution-feature-node-title h3 {
		margin-top: 0;
	}
}

@media screen and (max-width: 767px) {
	#solutions-module.node .solution-overview .solution-image {
		margin-bottom: 20px;
		margin-right: auto;
		margin-left: auto;
	}

	#solutions-module.node .solution-cards .solution-cards-column.content .solution-cards-container .solution-card .solution-card-body {
		max-height: unset !important;
	}

	#solutions-module.node .solution-cards .solution-card-header {
		margin-top: 2rem;
	}
}

@media screen and (max-width: 479px) {
	.solutions-container.action-show .breadcrumbs-div,
	.solutions-container.action-show .bfc,
	.solutions-container.action-show #solutions-module.node .solution-cards,
	.solutions-container.action-show #solutions-module.node .solution-overview,
	.solutions-container.action-show .example {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}
}

/* Solution Categories sticky navigation */
.sticky-fast-travel-wrapper {
	position: -webkit-sticky;
	position: sticky;
	top: 75px;
	overflow: hidden;
	width: 100%;
	height: 60px;
	border-top: 5px solid #ce3e16;
	background-color: #2d3037;
	box-shadow: 4px 4px 7px 0 rgb(3 4 5 / 20%);
	z-index: 100;
}

.sticky-fast-travel-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: 100%;
	max-width: 1400px;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.fast-travel-heading {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	padding-right: 1rem;
	padding-left: 1rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #d43f16;
	color: #000;
	line-height: 43px;
	font-weight: 700;
}

.fast-travel-heading a,
.fast-travel-heading a:hover {
	color: #000 !important;
	background-color: #d43f16 !important;
	line-height: inherit;
	font-family: inherit;
	font-weight: inherit;
	font-size: inherit;
	letter-spacing: inherit;
	text-decoration: none;
}


.fast-travel-slider {
	position: relative;
	flex-grow: 1;
	height: 55px;
	margin-right: auto;
	margin-left: auto;
	clear: both;
	color: #e6ebf5;
}

.fast-travel-heading .icon {
	display: none;
	width: 36px;
	height: 55px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.fast-travel-mask {
	position: absolute;
	left: 0px;
	right: 0px;
	z-index: 1;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	height: 100%;
	margin-right: 40px;
	margin-left: 28px;
	white-space: nowrap;
}

.fast-travel-text {
	position: relative;
	display: inline-block;
	width: auto;
	height: 100%;
	padding: 15px 20px 5px;
	-webkit-box-ordinal-group: 4;
	-webkit-order: 3;
	-ms-flex-order: 3;
	order: 3;
	-webkit-transition: box-shadow 200ms ease;
	transition: box-shadow 200ms ease;
	color: #e6ebf5;
	font-size: 0.85rem;
	font-weight: 600;
	text-align: left;
	letter-spacing: 0.04rem;
	text-decoration: none;
	text-transform: uppercase;
	white-space: nowrap;
}

.fast-travel-text:hover {
	box-shadow: inset 0 -6px 0 0 #ce3e16;
	color: #e6ebf5;
}

.fast-travel-text.active {
	box-shadow: inset 0 -6px 0 0 #a6b0c0;
	-webkit-transition-property: all;
	transition-property: all;
	color: #ee7553;
}

.fast-travel-text.active:hover {
	background-color: #d43f16;
	color: #000;
	font-weight: 600;
}

.fast-travel-mask .fast-travel-text:last-child {
	margin-right: 40px;
}

.fast-travel-nav {
	position: absolute;
	right: 0px;
	z-index: 2;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #2d3037;
}

.fast-travel-nav,
.fast-travel-heading .icon {
	font-family: 'webflow-icons' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 2rem;
}


#fast-travel-nav-right,
#fast-travel-nav-left {
	background: transparent;
	display: inline-block;
	width: auto;
	height: 100%;
	line-height: 55px;
	padding: 0;
	margin: 0;
}

.fast-travel-heading:after {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	bottom: 0;
	width: 23px;
	right: -23px;
	background: #d43f16;
	-webkit-clip-path: polygon(100% 50%, 0 0, 0 100%);
	clip-path: polygon(100% 50%, 0 0, 0 100%);
	transform: translate(-1px, 0px);
	color: #000;
}

.fast-travel-nav:before {
	content: '';
	display: block;
	background: linear-gradient(90deg, rgba(2, 0, 36, 0) 0%, rgba(45, 48, 55, 1) 100%);
	height: 100%;
	position: absolute;
	left: -30px;
	width: 30px;
}

.sticky-fast-travel-wrapper.sticky {
	position: fixed;
}

@media screen and (min-width: 768px) {
	.sticky-fast-travel-wrapper.no-category .fast-travel-heading {
		display: none;
	}
}

@media screen and (max-width: 991px) {
	.fast-travel-mask {
		margin-left: 30px;
	}

	.fast-travel-text {
		padding-right: 15px;
		padding-left: 15px;
	}
}

@media screen and (max-width: 767px) {
	.sticky-fast-travel-wrapper {
		top: 75px;
		overflow: visible;
		height: auto;
		text-align: center;
	}

	.sticky-fast-travel-container {
		overflow: visible;
		height: 50px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		cursor: pointer;
	}

	.fast-travel-heading {
		position: static;
		z-index: 999;
		display: inline-block;
		background-color: transparent;
		color: #e6ebf5;
		font-size: 1rem;
		line-height: 50px;
	}

	.fast-travel-heading .icon {
		font-size: 1rem;
		display: inline;
	}

	.fast-travel-slider {
		position: absolute;
		top: 50px;
		z-index: 998;
		width: 100%;
		height: auto;
		background-color: #2d3037;
		transition: opacity 1s ease-out;
		opacity: 0;
		height: 0;
		overflow: hidden;
	}

	.fast-travel-mask {
		position: relative;
		overflow-x: hidden;
		overflow-y: scroll;
		width: 100%;
		height: 90vh;
		margin-right: 0px;
		margin-left: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: center;
		padding-bottom: 50px;
	}

	.sticky-fast-travel-wrapper:hover .fast-travel-slider {
		opacity: 1;
		height: auto;
	}

	.sticky-fast-travel-wrapper:hover .fast-travel-heading {
		color: #f8992a;
	}

	.fast-travel-text {
		width: 100%;
		height: auto;
		padding: 8px 10px;
		-webkit-transition-property: all;
		transition-property: all;
		font-size: 0.75rem;
		text-align: center;
	}

	.fast-travel-nav {
		display: none;
	}

	#fast-travel-nav-right,
	#fast-travel-nav-left {
		line-height: 50px;
		font-size: 1.5rem;
	}

	.fast-travel-text.active {
		box-shadow: none;
	}

	.fast-travel-text:hover {
		background-color: #f8992a;
		box-shadow: none;
		color: #17191d;
	}

	.fast-travel-heading:after {
		display: none;
	}

	.fast-travel-mask .fast-travel-text:last-child {
		margin-right: 0px;
	}
}

@media screen and (max-width: 479px) {
	.sticky-fast-travel-wrapper {
		top: 80px;
	}

	.fast-travel-text {
		white-space: nowrap;
	}
}

#products_module.cms_entity .cms_content ol,
#products_module.cms_entity .cms_content ul {
	overflow: visible;
}
