.grid-box.twothirds {
	width: 65.66%;
}

.grid-box.center {
	text-align: center;
}

.grid-box.center p {
	margin: 0 auto;
	max-width: 400px;
}

.content .fancy-h2 {
	font-weight: 300;
	text-align: center;
	text-transform: uppercase;
	font-size: 3em;
}

.center-box {
	max-width: 500px;
	text-align: center;
	margin: 0 auto;
}

.center-box a.button {
	margin-right: 0;
}

.box-icon {
	margin: 0 auto;
	margin-bottom: 10px;
}

.icon-row {
	margin-bottom: 40px;
	width: 100%;
	float: left;
}

.icon-row img {
	float: left;
	margin-right: 20px;
}

.flex-icon-row {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
}

.flex-icon-row img {
	margin-right: 20px;
}

.relative-icon-row {
	position: relative;
	margin-bottom: 40px;
	padding-left: 75px;
}

.relative-icon-row img {
	position: absolute;
	top: 0;
	left: 0;
}

@media (max-width: 767px) {
	.grid-box.twothirds {
		width: 100%;
	}

	.grid-box.third {
		width: 100%;
	}

	.grid-box-1 {
		order: 2;
	}

	.grid-box-2 {
		order: 1;
	}

	.list-box {
		margin: 0;
		padding: 0;
	}
}

@media (max-width: 630px) {
	.grid-box.third {
		order: -1;
	}
}

@media (max-width: 500px) {
	.content .fancy-h2 {
		font-size: 1.75em;
	}
}

@media (max-width: 480px) {
	.relative-icon-row {
		padding-left: 0;
	}

	.relative-icon-row img {
		position: static;
	}
}
