:root {
	--dark-slate-grey: #001446;
	--black: #181f25;
	--white: white;
	--light-blue-grip: #00a7e1;
	--grip-blue: #1a428a;
	--grip-red: #e03c31;
	--verylightgrey: #f3f6f9;
	--grip-additional-yellow: #e9ae15;
	--rem: 0.25rem;
}

.w-layout-grid {
	grid-row-gap: 16px;
	grid-column-gap: 16px;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr 1fr;
	grid-auto-columns: 1fr;
	display: grid;
}

body {
	color: var(--dark-slate-grey);
	font-family: halyard-display;
	font-size: 16px;
	line-height: 20px;
}

h1 {
	margin-top: 0;
	margin-bottom: 40px;
	font-size: 76px;
	font-weight: 500;
	line-height: 1em;
}

h2 {
	margin-top: 0;
	margin-bottom: 16px;
	font-size: 76px;
	font-weight: 500;
	line-height: 1.1em;
}

h3 {
	margin-top: 0;
	margin-bottom: 16px;
	font-size: 34px;
	font-weight: 500;
	line-height: 1em;
}

h4 {
	margin-top: 0;
	margin-bottom: 16px;
	font-size: 26px;
	font-weight: 500;
	line-height: 24px;
}

p {
	color: var(--black);
	line-height: 1.4em;
}

a {
	text-decoration: underline;
	transition: all 0.2s;
}

.header {
	z-index: 25;
	position: fixed;
	inset: 0% 0% auto;
}

.header._2 {
	z-index: 1500;
	position: sticky;
}

.header.insidepage {
	z-index: 200;
	background-color: var(--white);
	position: sticky;
	box-shadow: 0 2px 5px #0003;
}

.herosection {
	z-index: 2;
	background-color: var(--dark-slate-grey);
	background-image: none;
	position: relative;
}

.content-outer {
	z-index: 2;
	justify-content: space-between;
	align-items: stretch;
	width: 100%;
	max-width: 1250px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	position: relative;
}

.content-outer.mod--invest {
	color: var(--dark-slate-grey);
	justify-content: space-between;
	align-items: stretch;
}

.content-outer.mod--header {
	z-index: 150;
	max-width: none;
	padding-top: 1px;
	padding-bottom: 1px;
}

.content-outer.mod--news {
	z-index: 1;
	justify-content: space-between;
	padding-left: 16px;
	display: flex;
}

.content-outer.mod--footer {
	margin-top: 0;
	padding-top: 0;
	overflow: hidden;
}

.header__columns {
	justify-content: space-around;
	align-items: center;
	width: 100%;
	display: flex;
	padding-right: 20px;
}

.section {
	z-index: 1;
	padding: 110px 60px;
	position: relative;
}

.section.mod--header {
	z-index: 1000;
	justify-content: space-between;
	align-items: center;
	padding: 16px 20px;
	display: flex;
	position: sticky;
}

.section.mod--header.insidepage {
	background-color: var(--white);
	color: var(--dark-slate-grey);
	box-shadow: 1px 1px 10px -2px #0003;
}

.section.mod--hero {
	z-index: 2;
	justify-content: flex-start;
	align-items: center;
	height: 100vh;
	padding-left: 5%;
	padding-right: 5%;
	display: flex;
}

.section.mod--invest {
	z-index: 0;
	flex-direction: row;
	justify-content: center;
	align-items: stretch;
	height: auto;
	min-height: 700px;
	display: flex;
}

.section.mod--news {
	z-index: 0;
	flex-flow: column;
	padding-top: 60px;
	padding-bottom: 170px;
	display: flex;
}

.hero__columns {
	justify-content: space-between;
	width: 100%;
	padding-left: 16px;
	display: flex;
}

.herocolumns {
	justify-content: space-between;
	display: flex;
}

.herocolumns.mod--1 {
	margin-bottom: 0;
}

.logo-link {
	width: 100%;
	height: 100%;
	display: flex;
	position: relative;
}

.header__nav {
	color: var(--dark-slate-grey);
	margin-left: auto;
	margin-right: 0;
	font-size: 14px;
	display: flex;
	margin: 0px;
	padding: 0px;
}

.navigation-bar {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	background-color: #ddd0;
	justify-content: flex-end;
	align-items: center;
	min-width: 250px;
	height: 100%;
	display: flex;
}

.navigation-bar.centered {
	width: auto;
	max-width: none;
	margin-left: auto;
}

.navigation-bar.buttons {
	width: auto;
	min-width: auto;
	max-width: none;
}

.hero__col.mod--1 {
	flex: 1;
	max-width: 600px;
}

.hero__col.mod--2 {
	padding-right: 64px;
	display: flex;
}

.overflow-hidden {
	color: var(--white);
	overflow: hidden;
}

.hero__read-wrap {
	text-align: center;
	justify-content: flex-end;
	align-items: flex-end;
	width: 250px;
	margin-left: auto;
	display: flex;
	position: relative;
	top: 135px;
}

.hero__read-title {
	color: var(--white);
	text-align: left;
	margin-bottom: 16px;
	font-size: 26px;
	line-height: 1.1em;
}

.hero__circles {
	justify-content: flex-end;
	align-items: flex-start;
	display: flex;
	opacity: 0;
}

.hero__circle-wrap {
	position: relative;
}

.hero__circle-dot {
	background-color: #e03c31;
	border-radius: 100px;
	width: 24px;
	height: 24px;
	position: absolute;
	top: -4px;
	left: 58px;
}

.hero__circle-dot._2nd {
	z-index: 2;
	border: 5px solid var(--grip-blue);
	background-color: #e03c3100;
	width: 50px;
	height: 50px;
	top: 243px;
	left: 4px;
	box-shadow: 0 2px 5px #0003;
}

.hero__circle-dot._3rd {
	z-index: 3;
	background-color: #fff0;
	width: auto;
	height: auto;
	top: 98px;
	left: -317px;
}

.bg-circle {
	z-index: 0;
	opacity: 0.5;
	filter: none;
	display: block;
	position: absolute;
	top: 190px;
	right: 60px;
}

.bglogoanimated {
	z-index: 0;
	height: 100vh;
	position: absolute;
	top: 0%;
	left: 0%;
	right: 0%;
}

.invest__content {
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	display: flex;
}

.invest__heading {
	color: var(--white);
	text-align: center;
	max-width: 900px;
	margin-bottom: 40px;
	font-size: 36px;
	font-weight: 400;
	line-height: 1.3em;
}

.invest__button {
	color: #fff;
	letter-spacing: 1px;
	text-transform: capitalize;
	white-space: nowrap;
	background-color: #e03c31;
	border-radius: 50px;
	height: 40px;
	margin-left: 0;
	padding: 9px 20px 10px;
	text-decoration: none;
	display: block;
	overflow: hidden;
}

.invest__button:hover {
	color: #fff;
	background-color: #a32615;
}

.invest__button.bloop-animation {
	background-color: var(--grip-red);
	color: #000000;
	text-align: center;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	font-size: 14px;
}

.invest__button.bloop-animation:hover {
	color: var(--white);
}

.hero__read {
	-webkit-backdrop-filter: brightness(110%) blur(5px);
	backdrop-filter: brightness(110%) blur(5px);
	color: var(--dark-slate-grey);
	text-align: left;
	background-color: #ffffff29;
	border-radius: 25px;
	padding: 32px;
}

.hero__read.alt {
	z-index: 10;
	border-radius: 9px;
	max-width: 250px;
	padding-top: 32px;
	position: absolute;
	top: 65%;
	right: 64px;
	opacity: 0;
}

.news__heading-wrap {
	flex: 1;
	max-width: 200px;
}

.bg-circle-wrap {
	width: 100%;
	height: 100%;
	position: relative;
	opacity: 0;
}

.page-wrap {
	z-index: 10;
	position: relative;
}

.logowhite {
	background-image: url("/20181016082423/assets/images/grip_logo_full-white-adjusted.svg");
	background-position: 0%;
	background-repeat: no-repeat;
	background-size: contain;
	align-self: center;
	width: 250px;
	height: 60px;
	position: absolute;
}

.body {
	background-color: var(--white);
	font-family: filson-pro, sans-serif;
}

.hero__heading-wrap {
	color: #fff;
}

.hero__heading {
	margin-bottom: 0;
	font-size: 80px;
	font-weight: 700;
	line-height: 1.05em;
}

.hero__heading.mod--2 {
	color: #e9ae15;
	text-align: center;
	line-height: 70px;
	position: absolute;
	top: 100%;
	left: 0;
}

.hero__heading.mod--3 {
	color: #e03c31;
	line-height: 70px;
	position: absolute;
	top: 200%;
	left: 0;
}

.hero__heading.mod--3 {
	color: #e03c31;
	line-height: 80px;
	position: static;
}

.hero__heading.mod--2 {
	color: #e9ae15;
	text-align: left;
	line-height: 80px;
	position: static;
}

.hero__heading.light-blue {
	color: #00a9e0;
	line-height: 80px;
	height: 80px;
}

.hero__heading.light-blue p {
	font-size: inherit;
	line-height: inherit;
	font-weight: inherit;
	color: inherit;
	font-family: inherit;
}

.hero__heading.light-blue:nth-child(2) {
	color: #e9ae15;
}

.hero__heading.light-blue:nth-child(3) {
	color: #e03c31;
}

.secctionheading {
	text-align: left;
	justify-content: center;
	align-items: center;
	max-width: 700px;
	margin-top: 0;
	margin-bottom: 0;
	font-size: 3rem;
	font-weight: 400;
	line-height: 1.2;
	display: inline-flex;
}

.secctionheading.white {
	color: #fff;
}

.secctionheading.blue {
	color: var(--dark-slate-grey);
}

.secctionheading.centered {
	z-index: 5;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	position: relative;
}

.secctionheading.centered.white {
	color: var(--white);
	margin-bottom: 64px;
}

.secctionheading.second {
	font-size: 1.9rem;
	font-style: italic;
}

.secctionheading.second.mobile {
	display: none;
}

.our-work {
	z-index: 0;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	background-color: var(--white);
	background-image: none;
	background-position: 0 0;
	flex-flow: column;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	min-height: 90vh;
	margin-left: auto;
	margin-right: auto;
	padding-top: 8rem;
	padding-left: 5%;
	padding-right: 5%;
	display: flex;
	position: relative;
}

.news {
	grid-column-gap: 5rem;
	grid-row-gap: 5rem;
	background-color: #fff;
	flex-direction: column;
	padding-top: 5rem;
	padding-bottom: 5rem;
	display: flex;
}

.news.section-footer-fixed {
	z-index: 2;
	background-color: var(--dark-slate-grey);
	color: #fff;
	justify-content: space-between;
	align-items: center;
	padding-top: 10vh;
	padding-bottom: 8rem;
	position: sticky;
	inset: 0%;
	overflow: hidden;
}

.fixed-footer-visibility-block {
	z-index: 0;
	position: relative;
	overflow: auto;
}

.inner-container._574px {
	max-width: 574px;
}

.inner-container._574px.center {
	max-width: 799px;
}

.trusted-companies-strip {
	z-index: 1;
	background-color: var(--white);
	padding-top: 10rem;
	padding-bottom: 10rem;
	position: relative;
	overflow: hidden;
}

.container-default {
	justify-content: center;
	align-items: center;
	max-width: 1250px;
	margin-bottom: 4rem;
	padding-left: 24px;
	padding-right: 24px;
	display: flex;
}

.logo-strip-image {
	width: 100%;
}

.logo-strip-image.shadow {
	background-color: var(--white);
	border: 1px solid #eff0f6;
	border-radius: 16px;
	max-height: 200px;
	margin: 14px 16px;
	box-shadow: 0 1px 12px #14142b14;
}

.logo-strip-image.shadow:hover {
	filter: saturate();
}

.newssection {
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding-left: 5%;
	padding-right: 5%;
	display: flex;
	position: sticky;
}

.button-link {
	color: #fff;
	letter-spacing: 4px;
	text-transform: uppercase;
	white-space: nowrap;
	background-color: #0000;
	border-bottom: 1px solid #ffffff1a;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;
	font-size: 11px;
	text-decoration: none;
	position: relative;
}

.button-link.black {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	color: var(--dark-slate-grey);
	border-bottom-color: #00202a14;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr 1fr;
	grid-auto-columns: 1fr;
	align-self: center;
	padding: 8px 16px;
	display: inline-flex;
}

.button-link.black.footer {
	color: var(--black);
	justify-content: flex-start;
	align-self: flex-start;
	align-items: center;
	padding: 0;
	display: flex;
}

.button-link.black.footer.signoff {
	letter-spacing: 0;
	text-transform: none;
	justify-content: flex-start;
	align-items: center;
}

.button-link.nav {
	border-bottom-color: #fff0;
	padding-left: 0;
	padding-right: 10px;
}

.button-link.nav.insidepage {
	color: var(--black);
	padding-left: 10px;
	padding-right: 10px;
}

.button-link.nav.insidepage.dropdownpadding {
	color: var(--black);
	padding-right: 16px;
}

.button-link.nav.navigation {
	color: var(--dark-slate-grey);
	padding-left: 10px;
}

.button-link.nav.dropdown {
	padding-right: 16px;
}

.button-link.nav.dropdown.has-children {
	padding-right: 26px;
}

.hover-line {
	width: 100%;
	height: 1px;
	position: absolute;
	inset: auto 0% -1px;
	overflow: hidden;
}

.button-link.nav.dropdown .hover-line {
	left: -9px;
}

.hover-line-fill {
	background-color: #fff;
	width: 100%;
	height: 100%;
	display: none;
}

.hover-line-fill.black {
	background-color: var(--dark-slate-grey);
}

.hover-line-fill.insidepage {
	background-color: var(--black);
	color: var(--dark-slate-grey);
}

.text-block-3 {
	letter-spacing: 2px;
	text-transform: uppercase;
	font-size: 14px;
}

.text-block-3.footer-text-block {
	font-family: Outfit, sans-serif;
}

.text-block-3.signoff {
	letter-spacing: 0;
	text-transform: none;
}

.text-block-3.white {
	color: var(--white);
}

.nav-cover {
	z-index: -3;
	background-color: var(--white);
	opacity: 0;
	border-bottom: 1px solid #ffffff4a;
	width: 100%;
	height: 101%;
	position: absolute;
	inset: -101% 0% auto;
	box-shadow: 1px 1px 10px -2px #0000004d;
}

.logov3_component {
	padding-top: 2rem;
	display: flex;
	overflow: visible;
}

.logov3_marquee {
	grid-column-gap: 3.5rem;
	grid-row-gap: 3.5rem;
	filter: none;
	flex-direction: row;
	flex: none;
	order: 0;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
	grid-auto-columns: 1fr;
	justify-content: center;
	height: 60px;
	display: flex;
}

.logov3_marquee-2.second {
	margin-left: -40px;
}

.logov3_item {
	flex: auto;
	justify-content: center;
	align-items: center;
	display: flex;
}

.logov3_image {
	object-fit: contain;
	flex: 0 auto;
	height: 100%;
}

.logov3_image.curved {
	border-radius: 10px;
}

.logov3_image.large {
	height: 70px;
}

.certifications {
	z-index: 6;
	-webkit-backdrop-filter: none;
	backdrop-filter: none;
	border-radius: 25px;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-height: 250px;
	margin-left: auto;
	margin-right: auto;
	padding: 4rem 5% 2rem;
	display: flex;
	position: relative;
}

.solutions {
	z-index: 1;
	grid-column-gap: 2rem;
	grid-row-gap: 2rem;
	color: var(--white);
	flex-flow: column;
	justify-content: center;
	align-items: center;
	padding: 8rem 5%;
	display: flex;
	position: relative;
	overflow: hidden;
}

.footer-section {
	z-index: 5;
	background-color: #fff;
	background-image: linear-gradient(to bottom, var(--white), var(--white));
	flex-flow: column;
	justify-content: space-between;
	align-items: stretch;
	display: flex;
	position: relative;
	overflow: hidden;
}

.ths12-overlay-1 {
	z-index: 5;
	background-image: linear-gradient(135deg, #00a9e04a, #cab37480);
	background-position: 0 0;
	position: absolute;
	inset: 0%;
}

.ths12-overlay-2 {
	z-index: 5;
	background-image: url("/20181016082423/assets/images/ths12_bg_mesh_gradient_2.webp");
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
	position: absolute;
	inset: 0%;
}

.ths12-overlay-3 {
	z-index: 5;
	background-image: linear-gradient(135deg, #db080878, #cab37480);
	background-position: 0 0;
	background-repeat: repeat;
	background-size: auto;
	position: absolute;
	inset: 0%;
}

.ths12-overlay-4 {
	z-index: 5;
	background-image: linear-gradient(135deg, #72d2fb, #cab37480);
	background-position: 0 0;
	position: absolute;
	inset: 0%;
}

.ths12-overlay-5 {
	z-index: 5;
	background-image: linear-gradient(135deg, #1a428a2b, #cab37480);
	background-position: 0 0;
	position: absolute;
	inset: 0%;
}

.ths12-overlay---logo {
	z-index: 5;
	opacity: 0.16;
	background-image: url("/20181016082423/assets/images/grip-white-logo-icon.svg");
	background-position: 100% 75%;
	background-repeat: no-repeat;
	background-size: auto 80%;
	position: absolute;
	inset: 0%;
}

.footer-column-wrap {
	clear: both;
	text-align: left;
	flex-flow: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 48%;
	font-style: normal;
	display: flex;
}

.text-field {
	border-radius: 50px;
	height: 45px;
	margin-bottom: 0;
}

.footerbox {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	-webkit-backdrop-filter: brightness(107%) blur(5px);
	backdrop-filter: brightness(107%) blur(5px);
	color: var(--dark-slate-grey);
	border-radius: 10px;
	flex-flow: column;
	justify-content: flex-start;
	align-items: flex-start;
	height: 100%;
	padding: 32px;
	display: flex;
}

.footerbox.center {
	justify-content: flex-start;
	align-items: flex-start;
	height: 100%;
}

.footerbox.full-height {
	height: 100%;
}

.columns-2 {
	align-items: center;
	display: flex;
}

.footer-signup {
	width: 49%;
}

.footer-column-title {
	text-align: left;
	margin-top: 0;
	margin-bottom: 0;
	padding-bottom: 0.125em;
	font-size: 2rem;
	font-weight: 500;
	line-height: 1.25em;
	color: var(--dark-slate-grey);
	font-family: Outfit, sans-serif;
}

.signoff-div {
	color: var(--dark-slate-grey);
	border-top: 1px solid #ffffff4d;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding: 1.5rem 20px 10rem;
	display: flex;
}

.grip-logo-image {
	width: 9rem;
	min-width: 150px;
	transition: all 0.45s;
}

.footer-other-clinics-wrap {
	width: 49%;
}

.footer-wide-wrapper {
	z-index: 6;
	width: 100%;
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	display: block;
	position: relative;
}

.link {
	color: var(--dark-slate-grey);
	border-bottom: 1px solid #5472c240;
	text-decoration: none;
}

.link:hover {
	border-bottom-style: solid;
	border-bottom-color: var(--light-blue-grip);
}

.link.w--current {
	color: #28313b;
	font-weight: 700;
}

.footer-copyright-2 {
	max-width: none;
	margin-bottom: 0;
	margin-left: 0;
	margin-right: 1.5rem;
	font-family: Outfit, sans-serif;
	font-size: 14px;
	display: inline-block;
	color: var(--black);
	line-height: 1.4em;
}

.footer-copyright-2 h1 {
	display: inline;
	margin: 0px;
	padding: 0px;
	font-family: inherit;
	line-height: inherit;
	color: inherit;
	font-weight: inherit;
	font-size: inherit;
}

.footer-signoff-link-wrap {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	color: var(--black);
	white-space: nowrap;
	font-family: Outfit, sans-serif;
	display: flex;
}

.sign-off-div-wrap {
	width: 100%;
	min-width: 991px;
}

.footer-content-wrap {
	color: var(--white);
	width: 100%;
	padding: 2rem 5% 2.5rem;
	transition: all 0.2s;
}

.column-3 {
	justify-content: flex-start;
	align-items: center;
	padding-left: 0;
	padding-right: 0;
	display: flex;
}

.social-icons-wrap {
	grid-column-gap: 8px;
	grid-row-gap: 8px;
	flex-flow: row;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 16px;
	display: flex;
}

.footer-social-icon-image {
	width: 35px;
	height: 35px;
	margin: 1px;
	padding: 5px;
}

.footer-social-icon-image.small {
	width: 30px;
	height: 31px;
}

.footer-content-row {
	grid-column-gap: 2rem;
	grid-row-gap: 2rem;
	justify-content: space-between;
	display: flex;
}

.column-2 {
	justify-content: flex-end;
	display: flex;
}

.div-block-2 {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	flex-flow: row;
	justify-content: flex-start;
	align-items: center;
	display: flex;
}

.primary-btn {
	z-index: 1;
	background-color: var(--light-blue-grip);
	color: #000;
	text-align: center;
	white-space: normal;
	word-break: normal;
	border-radius: 50px;
	justify-content: center;
	align-items: center;
	min-width: 120px;
	min-height: 45px;
	margin-left: 0;
	margin-right: 0;
	padding-left: 16px;
	padding-right: 16px;
	font-size: 16px;
	font-weight: 400;
	line-height: 18px;
	text-decoration: none;
	transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	display: flex;
	overflow: hidden;
}

.primary-btn:hover {
	background-color: var(--grip-blue);
	color: var(--white);
}

.primary-btn.red {
	color: #fff;
	white-space: nowrap;
	background-color: #7e2a3d;
	border-radius: 50px;
	min-width: auto;
	overflow: visible;
}

.primary-btn.red:hover {
	background-color: #460010;
}

.primary-btn.not {
	display: none;
}

.primary-btn.alt {
	text-transform: uppercase;
	white-space: nowrap;
	min-width: auto;
	padding: 9px 16px;
	font-size: 14px;
	line-height: 18px;
}

.primary-btn.alt:hover {
	background-color: var(--dark-slate-grey);
}

.primary-btn.alt.red {
	background-color: var(--grip-red);
	color: var(--black);
}

.primary-btn.alt.red:hover {
	background-color: var(--dark-slate-grey);
}

.primary-btn.dark {
	background-color: var(--grip-blue);
	color: var(--white);
}

.primary-btn.dark:hover {
	background-color: var(--light-blue-grip);
	color: var(--black);
}

.footer-social-icon-link {
	background-color: #7f7e79;
	border-radius: 18px;
	margin: 5px 8px;
	overflow: hidden;
}

.footer-social-icon-link:hover {
	background-color: #4d4d4d;
}

.footer-social-icon-link.facebook {
	background-color: var(--grip-blue);
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 35px;
	height: 100%;
	max-height: 35px;
	display: flex;
}

.footer-social-icon-link.facebook:hover {
	background-color: var(--light-blue-grip);
}

.footer-social-icon-link.facebook.bloop-animation {
	margin-left: 0;
	margin-right: 0;
}

.footer-address-column {
	flex-flow: column;
	justify-content: space-between;
	width: 50%;
	line-height: 1.75em;
	display: flex;
}

.grip-logo-link {
	float: right;
	opacity: 1;
	text-decoration: none;
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, opacity 0.2s;
}

.grip-logo-link:hover {
	opacity: 1;
	filter: saturate();
}

.name {
	text-align: left;
	white-space: nowrap;
	margin-left: 0;
	font-size: 18px;
	font-weight: 500;
	font-family: Outfit, sans-serif;
}

.div-block-6 {
	grid-column-gap: 32px;
	grid-row-gap: 32px;
	display: flex;
}

.button {
	color: #fff;
	text-align: center;
	letter-spacing: 3px;
	text-transform: uppercase;
	background-color: #090b19;
	border-radius: 100px;
	justify-content: center;
	align-items: center;
	margin-left: 0;
	margin-right: 0;
	padding: 16px 28px;
	font-size: 11px;
	text-decoration: none;
	transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	display: flex;
	position: relative;
	overflow: hidden;
}

.button:hover {
	color: #090b19;
	background-color: #e2e7f1;
	border-color: #e2e7f1;
}

.icon-4 {
	margin-right: 15px;
}

.text-block-61 {
	font-size: 16px;
}

.icon-square {
	background-color: #fff;
	border-radius: 50px;
	flex: none;
	justify-content: flex-start;
	align-items: center;
	width: 50px;
	height: 50px;
	padding: 12px;
	display: flex;
}

.nav-content-wrap {
	grid-column-gap: 8px;
	grid-row-gap: 8px;
	flex-flow: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	display: flex;
	color: inherit;
	line-height: inherit;
	font-family: inherit;
	text-decoration: none;
	border-bottom: 1px solid transparent;
	font-weight: inherit;
	font-size: inherit;
}

.dropdown-link-1 {
	color: var(--dark-slate-grey);
	background-color: #fff;
	border-radius: 10px;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	min-height: 50px;
	padding: 8px 16px;
	font-size: 16px;
	text-decoration: none;
	display: flex;
}

.dropdown-link-1:hover {
	background-color: var(--grip-blue);
	color: var(--white);
}

.dropdown-link-1.w--open {
	grid-column-gap: 8px;
	grid-row-gap: 8px;
}

.navigation-dropdown {
	grid-column-gap: 8px;
	grid-row-gap: 8px;
	background-color: var(--verylightgrey);
	-webkit-backdrop-filter: brightness(200%) blur(5px);
	backdrop-filter: brightness(200%) blur(5px);
	border-radius: 10px;
	flex-flow: column;
	padding: 8px;
	display: flex;
	position: relative;
	overflow: visible;
}

.navigation-dropdown.w--open {
	background-color: #fff;
	min-width: 350px;
	max-height: 100vh;
	padding-top: 20px;
	left: -120px;
	box-shadow: 0 50px 25px -50px #20355a26, 8px 18px 60px 4px #00000021;
}

.navigation-dropdown-copy {
	min-width: 140px;
}

.navigation-dropdown-copy.w--open {
	z-index: 150;
	background-color: #ddd0;
	min-width: 300px;
	max-height: 100vh;
	padding-top: 35px;
	left: -120px;
}

.search-field-title,
.search-field-title a {
	color: var(--grip-blue) !important;
	font-size: 24px !important;
	font-weight: 500 !important;
	line-height: 28px !important;
}

.searchbox {
	z-index: 1000;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	opacity: 1;
	-webkit-backdrop-filter: brightness(135%) blur(5px);
	backdrop-filter: brightness(135%) blur(5px);
	background-color: #ffffff47;
	border-top: 1px #5b5b5b26;
	border-radius: 20px;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr 1fr;
	grid-auto-columns: 1fr;
	justify-content: space-between;
	align-self: center;
	align-items: center;
	width: 500px;
	margin-bottom: 0;
	padding: 16px;
	display: flex;
	position: absolute;
	inset: 100px 20px auto auto;
}

.search-input-3 {
	border: 1px solid var(--grip-blue);
	opacity: 1;
	letter-spacing: 1px;
	border-radius: 20px;
	margin-bottom: 0;
	padding-top: 22px;
	padding-bottom: 22px;
	padding-left: 22px;
	font-family: Outfit, sans-serif;
	font-size: 1rem;
}

.search-input-3:focus {
	color: #000;
}

.search-icon {
	margin-top: 5px;
	margin-left: 40px;
	margin-right: 30px;
	display: inline-block;
}

.search-icon.mobile {
	background-color: var(--light-blue-grip);
	border-radius: 50px;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	min-width: 40px;
	min-height: 40px;
	margin-top: 0;
	margin-left: 0;
	margin-right: 0;
	display: flex;
	position: relative;
}

.search-icon.mobile:hover {
	background-color: var(--grip-blue);
}

.search-icon.mobile.bloop-animation {
	margin-right: -16px;
	overflow: hidden;
}

.search-cb-wrapper {
	position: relative;
}

.search-button-icon {
	z-index: 2;
	background-color: #0000;
	background-image: url("/20181016082423/assets/images/Vector-2.svg");
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 20px;
	border: 1px #233e99;
	border-radius: 20px;
	width: 34px;
	height: 34px;
	margin-top: 0;
	position: relative;
}

.search-button-icon:hover {
	background-color: #0000;
}

.search-button-icon:active {
	background-color: #0000;
	background-image: url("/20181016082423/assets/images/search-icon-white-01.png");
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 25px;
}

.exit-cb-wrapper {
	position: relative;
	inset: 0%;
}

.exit-button-icon {
	z-index: 2;
	background-color: #0000;
	background-image: url("/20181016082423/assets/images/Vector.svg");
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 20px;
	border: 1px #233e99;
	border-radius: 20px;
	width: 20px;
	height: 34px;
	margin-top: 0;
	position: relative;
	inset: 0%;
}

.exit-button-icon:hover {
	background-color: #0000;
}

.exit-button-icon:active {
	background-color: #0000;
	background-image: url("/20181016082423/assets/images/search-icon-white-01.png");
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 25px;
}

.search-container {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-bottom: 0;
	display: flex;
}

.logov3_marquee-2 {
	z-index: 5;
	flex-direction: row;
	flex: none;
	order: 0;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
	grid-auto-columns: 1fr;
	justify-content: center;
	display: flex;
	position: relative;
	animation: scroll 55s linear infinite;
}

@media (prefers-reduced-motion) {
	.logov3_marquee-2 {
		animation-play-state: paused;
	}

	.logov3_marquee-2:hover {
		animation-play-state: running;
	}
}

@keyframes scroll {
	to {
		transform: translate(-50%);
	}
}

.logov3_component-2 {
	z-index: 5;
	grid-column-gap: 2.5rem;
	grid-row-gap: 2.5rem;
	display: flex;
	position: relative;
	overflow: visible;
}

.paragraph-3 {
	text-align: left;
	white-space: nowrap;
	margin-left: 0;
	margin-right: 0;
}

.paragraph-3._2 {
	width: 112px;
}

.background-noise {
	z-index: 0;
	opacity: 0.3;
	background-image: url("/20181016082423/assets/images/noise.webp");
	background-position: 0 0;
	background-size: auto;
	width: 100%;
	height: 100%;
	position: absolute;
	inset: 0%;
}

.live-chat-button {
	color: #fff;
	letter-spacing: 1px;
	text-transform: capitalize;
	white-space: nowrap;
	background-color: #00a9e0;
	background-image: url("/20181016082423/assets/images/chat_icon.svg");
	background-position: 94%;
	background-repeat: no-repeat;
	background-size: 20px 20px;
	border-radius: 50px;
	height: 40px;
	margin-left: 0;
	padding: 9px 35px 9px 20px;
	text-decoration: none;
	display: block;
	overflow: hidden;
}

.live-chat-button:hover {
	color: #fff;
	background-color: #0f486f;
}

.live-chat-button.bloop-animation {
	background-color: var(--light-blue-grip);
	color: #000;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	background-image: url("/20181016082423/assets/images/chat-bubblet.svg");
	background-position: 90%;
	padding-right: 45px;
	font-size: 14px;
}

.live-chat-button.bloop-animation:hover {
	color: var(--white);
}

.live-chat-button.bloop-animation.mobile {
	display: none;
}

.get-started-button {
	color: #fff;
	letter-spacing: 1px;
	text-transform: capitalize;
	white-space: nowrap;
	background-color: #e03c31;
	border-radius: 50px;
	height: 40px;
	margin-left: 0;
	padding: 9px 20px 10px;
	text-decoration: none;
	display: block;
	overflow: hidden;
}

.get-started-button:hover {
	color: #fff;
	background-color: #a32615;
}

.get-started-button.bloop-animation {
	background-color: var(--grip-red);
	color: #000000;
	text-align: center;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	font-size: 14px;
}

.get-started-button.bloop-animation:hover {
	color: var(--white);
}

.card-image {
	object-fit: cover;
	object-position: 50% 50%;
	width: 100%;
	height: 100%;
}

.card-image._1 {
	object-fit: fill;
	padding-left: 0;
	display: block;
}

.card-heading-wrapper {
	justify-content: space-around;
	align-items: center;
	height: 100%;
	display: flex;
	position: relative;
}

.grid-item-overlay {
	opacity: 1;
	background-color: #0003;
	border-radius: 150px 0 5px 5px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	display: flex;
	position: absolute;
	inset: 0%;
}

.card-link {
	border-top-left-radius: 0;
	height: 100%;
	text-decoration: none;
	display: block;
	position: relative;
}

.card-heading-background {
	background-color: #00a9e0;
	border-radius: 50px;
	width: 5px;
	height: 45px;
	display: block;
	position: absolute;
	inset: 0%;
}

.card-heading-holder .card-heading-wrapper {
	flex: 0 auto;
	justify-content: space-between;
	align-items: center;
	width: auto;
	display: flex;
	position: relative;
	overflow: hidden;
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

.card-item {
	border-radius: 25px;
	width: 100%;
	height: 20rem;
	position: relative;
	overflow: hidden;
}

.paragraph-6,
.paragraph-7 {
	text-align: left;
	margin-left: 0;
	font-family: Outfit, sans-serif;
}

.left-align-paragraph {
	color: var(--black);
	text-align: left;
	margin-left: 0;
	font-family: Outfit, sans-serif;
	line-height: 1.45rem;
}

.left-align-paragraph.link {
	border-bottom-style: none;
	font-family: Outfit, sans-serif;
}

.left-align-paragraph.link:hover {
	color: var(--light-blue-grip);
}

.logwrap {
	width: 100%;
	max-width: 300px;
	height: 100%;
}

.slidecontent {
	grid-column-gap: 32px;
	grid-row-gap: 32px;
	justify-content: center;
	align-items: flex-end;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding: 0 32px 20px;
	display: flex;
}

.slidecontent.mobile {
	display: none;
}

.image-33 {
	border-radius: 15px;
	max-width: 70%;
	max-height: 55vh;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	box-shadow: 0 6px 12px 2px #0003;
}

.image-33.case {
	border: 2px solid #f3f3f3;
	max-width: 64%;
	margin-left: 0;
	margin-right: 0;
	position: static;
}

.image-34 {
	z-index: 1;
	border-radius: 10px;
	max-width: 11%;
	max-height: 26vh;
	position: absolute;
	left: 13%;
	box-shadow: 0 6px 13px 2px #0003;
}

.image-34.case {
	border: 2px solid #f3f3f3;
	position: static;
}

.image-35 {
	border-radius: 15px;
	max-width: 21%;
	max-height: 35vh;
	position: absolute;
	right: 12%;
	box-shadow: 0 5px 13px 2px #0003;
}

.image-35.case {
	border: 2px solid #f3f3f3;
	position: static;
}

.slider {
	background-color: #ddd0;
	max-width: 1350px;
	height: 100%;
	margin-bottom: -16px;
}

.slide-nav {
	border-radius: 20px;
	justify-content: center;
	align-items: center;
	width: 100px;
	margin-left: auto;
	margin-right: auto;
	padding-top: 5px;
	display: none;
}

.bold {
	font-size: 3.5rem;
	font-weight: 800;
	line-height: 1.3em;
}

.project_date-and-link {
	justify-content: space-between;
	align-items: center;
	display: flex;
}

.project_date {
	color: #fff;
	font-size: 1.25rem;
	font-weight: 700;
	display: none;
}

.project_image-wrapper {
	background-color: #fff;
	border-radius: 15px;
	position: static;
	height: 100%;
}

.project_grid {
	grid-column-gap: 2rem;
	grid-row-gap: 2rem;
	border-radius: 10px;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr 1fr;
	height: 381px;
	margin-bottom: 16px;
}

.project_tag-award {
	color: #ffd333;
	background-color: #5c6673;
	border-radius: 100rem;
	padding: 5px 16px;
	font-size: 0.75rem;
	font-weight: 700;
}

.project_wrapper {
	flex-flow: column;
	grid-template-rows: auto;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	place-items: center;
	display: flex;
	position: relative;
}

.project_wrapper:hover {
	height: 100%;
}

.project_cta-new-webstie {
	grid-row-gap: 1rem;
	color: #fff;
	background-color: #fff;
	border-radius: 15px;
	flex-flow: row;
	justify-content: space-between;
	align-items: center;
	padding: 32px;
	text-decoration: none;
	display: flex;
	position: relative;
}

.project_cta-new-webstie.hide {
	display: none;
}

.project_heading {
	color: #fff;
	height: 70px;
	margin-top: 0;
	margin-bottom: 0;
	font-size: 1.2rem;
	font-weight: 700;
	line-height: 1.2em;
}

.project_tag-group {
	grid-column-gap: 1rem;
	grid-row-gap: 1rem;
	flex-flow: column;
	justify-content: space-between;
	align-items: flex-start;
	display: flex;
}

.margin-top-2 {
	justify-content: center;
	margin-bottom: 0;
	margin-left: 0;
	margin-right: 0;
	display: flex;
}

.margin-top-2.margin-xsmall {
	justify-content: flex-start;
}

.margin-top-2.margin-medium {
	justify-content: flex-start;
	max-width: 1000px;
	margin-top: 2rem;
	margin-left: 0;
	margin-right: 0;
}

.project_description {
	color: #fff;
	margin-bottom: 10px;
	font-size: 1rem;
	font-weight: 400;
	line-height: 23px;
}

.project_content-wrapper {
	z-index: 2;
	border: 3px solid var(--white);
	background-color: #1d428a00;
	background-image: linear-gradient(to bottom, var(--grip-blue), var(--grip-blue));
	border-radius: 15px;
	flex-direction: column;
	justify-content: space-between;
	padding: 32px;
	display: flex;
	position: absolute;
}

.project_wrapper:hover .project_content-wrapper {
	height: 100%;
}

.icon-embed-xlarge {
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 6.5rem;
	height: 6.5rem;
	display: flex;
}

.icon-embed-xlarge.absolute {
	color: #1d428a;
	margin-right: -15px;
	position: absolute;
	inset: 10% 10% auto auto;
}

.project_info {
	grid-row-gap: 0.875rem;
	flex-direction: column;
	display: flex;
}

.paragraph-2-copy {
	text-align: left;
	max-width: 80%;
	text-decoration: none;
	display: none;
}

.project_tag {
	background-color: var(--dark-slate-grey);
	color: #fff;
	white-space: nowrap;
	border-radius: 20px;
	flex-direction: row;
	flex: 0 auto;
	justify-content: flex-start;
	width: auto;
	padding: 5px 16px;
	font-size: 0.9rem;
	font-weight: 300;
	display: flex;
	overflow: visible;
}

.project_link-copy {
	display: none;
}

.newsimg {
	border-radius: 15px;
	display: inline;
}

.list {
	grid-column-gap: 8px;
	grid-row-gap: 8px;
	flex-flow: column;
	padding-left: 16px;
	display: flex;
}

.notification-banner {
	z-index: 1025;
	color: var(--white);
	text-align: center;
	background-color: #a32615;
	justify-content: space-between;
	align-self: auto;
	align-items: center;
	width: 100%;
	padding-top: 10px;
	padding-bottom: 10px;
	display: flex;
	position: relative;
	inset: 0% 0% auto;
}

.notification-banner .cms-website-content-region {
	background-color: #a32615;
}

.notification-banner.hide {
	display: none;
}

.banner-wrap {
	color: #fff;
	justify-content: center;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
	padding-left: 20px;
	padding-right: 50px;
	display: flex;
}

.text-block-10 {
	letter-spacing: 0.5px;
	margin-left: auto;
	margin-right: auto;
	font-family: Outfit, sans-serif;
	font-size: 14px;
	font-weight: 400;
}

.brix---top-bar-close-icon {
	cursor: pointer;
	align-items: center;
	margin-top: auto;
	margin-bottom: auto;
	margin-left: auto;
	padding-left: 32px;
	padding-right: 20px;
	transition: opacity 0.3s;
	display: flex;
	position: absolute;
	inset: auto 0% auto auto;
}

.brix---icon-small-hover {
	width: 20px;
	transition: transform 0.3s;
}

.brix---icon-small-hover:hover {
	transform: scale(0.9);
}

.column-scroll-content-wrapper {
	z-index: 2;
	width: 66.66vw;
	min-height: 125vh;
	margin-top: -100vh;
	padding-right: 7rem;
	position: relative;
}

.column-scroll-content {
	padding-top: 40vh;
}

.column-scroll-content-text {
	text-align: left;
	text-transform: capitalize;
	font-size: 18px;
	line-height: 1.5;
}

.section-3-column-scroll {
	background-color: var(--white);
	background-image: none;
}

.column-scroll-sticky {
	width: 100%;
	height: 100vh;
	position: sticky;
	top: 0;
}

.column-scroll-sticky-inner {
	width: 45vw;
	height: 100%;
	position: absolute;
	inset: 0% 0% 0% auto;
	overflow: hidden;
}

.overflow-hidden-4 {
	overflow: hidden;
}

.column-scroll-wrapper {
	width: 100%;
	height: 250vh;
	position: relative;
}

.column-scroll-content-copy {
	width: 100%;
	max-width: 38rem;
	margin-top: 2rem;
	line-height: 1.2;
}

.column-scroll-content-title {
	text-transform: uppercase;
	font-size: 8vw;
	font-weight: 500;
	line-height: 0.95;
}

.column-scroll-content-title.is-1,
.column-scroll-content-title.is-2 {
	color: #000645;
	font-size: 4rem;
	font-weight: 400;
	line-height: 4rem;
}

.column-scroll-item {
	flex: none;
	width: 33.3333%;
	height: 100%;
	padding-left: 32px;
	padding-right: 32px;
	position: absolute;
	inset: 0% auto 0% 0%;
	box-shadow: 1.5rem 1px 2.1rem -1px #00000042;
}

.column-scroll-item.is-1 {
	background-color: #1d428a;
	background-image: linear-gradient(#1d428a, #1d428a);
	justify-content: center;
	align-items: stretch;
	width: 33.3333vw;
	padding-left: 40px;
	padding-right: 40px;
	display: flex;
}

.column-scroll-item.is-3 {
	background-color: #e71c00;
	justify-content: center;
	align-items: stretch;
	width: 33.3333vw;
	padding-left: 40px;
	padding-right: 40px;
	display: flex;
	inset: 0% 0% 0% auto;
}

.column-scroll-item.is-2 {
	background-color: #00a9e0;
	justify-content: center;
	align-items: stretch;
	width: 33.3333vw;
	margin-left: auto;
	margin-right: auto;
	padding-left: 40px;
	padding-right: 40px;
	display: flex;
	top: auto;
	right: 0;
}

.page-padding {
	padding-left: 4rem;
	padding-right: 4rem;
}

.column {
	color: var(--light-blue-grip);
	flex-direction: column;
	justify-content: center;
	width: auto;
	margin-right: 0;
	display: flex;
	position: relative;
	top: 45px;
	left: 40px;
}

.heading-5 {
	color: #fbf9fc;
	text-align: center;
	margin-bottom: 8px;
	font-size: 130px;
}

.heading-5.followers {
	color: var(--white);
	text-align: center;
	text-shadow: 0 5px 4px #0003;
	margin-bottom: 0;
	font-size: 85px;
	font-weight: 700;
	line-height: 85px;
}

.info-container {
	background-image: url("/20181016082423/assets/images/years-white_1.svg");
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: contain;
	align-items: center;
	width: 2ch;
	height: 30px;
	margin-left: auto;
	margin-right: auto;
	font-size: 82px;
	display: flex;
}

.text-block-5 {
	color: var(--light-blue-grip);
	text-align: center;
	font-size: 25px;
	font-weight: 400;
	line-height: 1;
	display: none;
}

.hero__elem {
	z-index: 0;
	position: absolute;
	overflow: hidden;
}

.hero__elem.mod--1 {
	background-color: var(--dark-slate-grey);
	box-shadow: 17px 15px 11px -1px var(--dark-slate-grey);
	border-radius: 100%;
	width: 200px;
	height: 199px;
	top: 0;
	bottom: auto;
}

.hero__elem-circle1-wrap {
	width: 100%;
	height: 100px;
	position: absolute;
	inset: 0%;
	overflow: hidden;
}

.hero__elem-circle1-wrap.mod--1 {
	width: auto;
	height: 100px;
	overflow: hidden;
}

.hero__elem-circle1-wrap.mod--2 {
	height: 100px;
	top: auto;
}

.hero__elem-circle1 {
	background-image: url("/20181016082423/assets/images/hero_elem-circle2_1.svg");
	background-position: 50% 100%;
	background-repeat: no-repeat;
	background-size: contain;
	flex: none;
	width: 200px;
	height: 200px;
	position: absolute;
	inset: auto 0% 0;
}

.hero__elem-circle1.mod--1 {
	background-image: url("/20181016082423/assets/images/hero_elem-circle1.svg");
	background-position: 50% 0;
	width: 200px;
	height: 200px;
	top: 0;
	bottom: auto;
}

.hero__elem-circle1.mod--2 {
	background-image: url("/20181016082423/assets/images/hero_elem-circle2.svg");
	width: 200px;
	height: 200px;
	bottom: 0;
}

.firework {
	position: absolute;
	top: -107.688px;
	left: -50px;
	right: -50px;
}

.div-block-15 {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr;
	grid-auto-columns: 1fr;
	grid-auto-flow: row;
	justify-content: space-between;
	place-items: center start;
	font-family: Outfit, sans-serif;
	display: grid;
}

.div-block-17 {
	margin-top: 32px;
}

.text-block-62 {
	letter-spacing: 2px;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 400;
}

.paragraph-8 {
	color: #f5f5f7;
	text-align: left;
	margin-bottom: 0;
	font-size: 1rem;
	font-weight: 400;
	line-height: 150%;
}

.card-background-2 {
	background-color: var(--light-blue-grip);
	filter: saturate(120%);
	justify-content: center;
	align-items: center;
	height: 100%;
	padding-left: 0;
	display: flex;
	position: relative;
}

.card-background-2._1st {
	background-color: var(--light-blue-grip);
}

.card-heading-2 {
	color: #f5f5f7;
	letter-spacing: 1px;
	text-transform: capitalize;
	object-fit: fill;
	flex: 0 auto;
	justify-content: flex-start;
	align-items: center;
	height: 50px;
	padding: 0.25rem 1rem;
	font-size: 1.1rem;
	font-weight: 400;
	line-height: 1.2rem;
	text-decoration: none;
	display: flex;
	position: relative;
	overflow: hidden;
}

.cardexpandingcontent {
	z-index: 5;
	opacity: 1;
	object-fit: cover;
	background-color: #0000009c;
	flex-direction: column;
	justify-content: flex-start;
	min-height: 18rem;
	margin-top: -5.5rem;
	padding: 1.5rem 32px 1.5rem 24px;
	font-size: 0.875rem;
	display: flex;
	position: relative;
}

.solutioncardimgwrap {
	z-index: 50;
	height: 120px;
	margin-left: 0;
	position: relative;
	top: -32px;
	right: -45px;
}

.solutioncarditem {
	border-radius: 25px;
	width: 100%;
	height: 17rem;
	position: relative;
	overflow: hidden;
}

.solutionscardgrid {
	grid-column-gap: 3rem;
	grid-row-gap: 3rem;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr 1fr;
	grid-auto-columns: 1fr;
	max-width: 1250px;
	margin-left: auto;
	margin-right: auto;
	display: grid;
}

.solutionscardgrid.insidespace {
	margin-bottom: 40px;
}

.promo-text-2 {
	color: #fff;
	justify-content: flex-start;
	align-items: center;
	font-size: 40px;
	font-weight: 400;
	line-height: 50px;
	display: flex;
}

.promo-text-2.dark {
	color: #001446;
}

.right-arrow {
	background-color: var(--light-blue-grip);
	border-radius: 50px;
	width: 35px;
	height: 35px;
	font-size: 20px;
	right: 80px;
}

.right-arrow:hover {
	background-color: var(--grip-blue);
}

.left-arrow {
	background-color: var(--light-blue-grip);
	color: var(--white);
	border-radius: 50px;
	width: 35px;
	height: 35px;
	font-size: 20px;
	left: 80px;
}

.left-arrow:hover {
	background-color: var(--grip-blue);
}

.close-icon {
	background-color: var(--dark-slate-grey);
	cursor: pointer;
	border: 1px solid #ffffff36;
	border-radius: 20px;
	align-items: center;
	margin: auto;
	padding: 8px;
	transition: opacity 0.3s;
	display: flex;
	position: absolute;
	inset: -16px -16px auto auto;
}

.brix---icon-small-hover-copy {
	width: 18px;
	transition: transform 0.3s;
}

.brix---icon-small-hover-copy:hover {
	transform: scale(0.9);
}

.news__cat-arrow {
	transition: all 0.3s;
	position: relative;
}

.news__cat {
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: 16px;
	display: flex;
}

.icon-block {
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	min-height: 100px;
	display: flex;
}

.icon-block.small {
	width: 55%;
	height: 70%;
	margin-left: auto;
	margin-right: auto;
}

.icon-block.small._2 {
	width: 70%;
}

.news__block {
	color: #000;
	background-color: #00a9e0;
	border: 3px solid #00000017;
	border-radius: 25px;
	flex-direction: column;
	justify-content: space-between;
	align-items: stretch;
	height: 300px;
	padding: 25px;
	text-decoration: none;
	transition-duration: 0.2s;
	transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
	display: flex;
	overflow: hidden;
	box-shadow: 0 60px 60px -20px #00000026;
}

.news__block:hover,
.news__block:focus {
	border: 3px solid #fff;
	box-shadow: 0 60px 60px -20px #00000040;
}

.news__block.mod--3 {
	color: #000;
	background-color: #ff483c;
}

.news__block.mod--3:hover {
	box-shadow: 0 60px 60px -20px #0000004d;
}

.news__block.mod--2 {
	background-color: #e9ae15;
}

.news__block.green {
	background-color: #1ba25b;
}

.news__cat-title {
	z-index: 1;
	text-transform: capitalize;
	min-height: 40px;
	font-weight: 700;
	position: relative;
}

.news__col {
	flex: 1;
	padding-left: 16px;
	padding-right: 16px;
}

.lottie-animation {
	padding: 0;
}

.news__columns {
	flex: 1;
	justify-content: space-between;
	display: flex;
}

.news__p {
	color: #fff;
	display: none;
}

.news__p.open {
	color: #000;
	text-align: left;
	display: block;
	position: relative;
}

.mask {
	max-width: 1350px;
}

.slidecontentwrapper {
	justify-content: center;
	align-items: flex-end;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	position: relative;
}

.slidecontentwrapper.case {
	grid-column-gap: 32px;
	grid-row-gap: 32px;
	max-width: 1100px;
	margin-left: auto;
	margin-right: auto;
	padding-bottom: 50px;
}

.logo30 {
	background-image: url("/20181016082423/assets/images/logo-30-years.svg");
	background-position: 0%;
	background-repeat: no-repeat;
	background-size: contain;
	align-self: center;
	width: 250px;
	height: 60px;
	position: absolute;
}

.logo30.hidden {
	display: none;
}

.logo30.white {
	background-image: url("/20181016082423/assets/images/grip_logo_full-white-adjusted.svg");
}

.content-container {
	justify-content: center;
	width: 100%;
	max-width: 1450px;
	margin-left: auto;
	margin-right: auto;
	padding-top: 32px;
	padding-left: 32px;
	padding-right: 32px;
	display: flex;
	animation: 500ms ease-in-out fadeInUp;
}

@keyframes fadeInUp {
	0% {
		transform: translateY(100px);
		opacity: 0;
	}

	100% {
		transform: translateY(0%);
		opacity: 1;
	}
}

.left-nav-a {
	transform-origin: 0%;
	color: var(--dark-slate-grey);
	background-image: url("/20181016082423/assets/images/grip-arrow.svg");
	background-position: 0%;
	background-repeat: no-repeat;
	background-size: 0 0;
	border-radius: 10px;
	margin-top: 8px;
	margin-left: 8px;
	margin-right: 8px;
	padding: 10px 20px 10px 27px;
	padding-bottom: 11px;
	text-decoration: none;
	transition-duration: 0.2s;
	display: block;
}

.left-nav-a:hover {
	background-color: var(--grip-blue);
	color: var(--white);
	background-image: url("/20181016082423/assets/images/grip-arrow.svg");
	background-position: 12px 54%;
	background-repeat: no-repeat;
	background-size: 9px 12px;
	border-right-color: #a32615;
	border-radius: 10px;
}

.left-nav-a.subnav-a {
	color: #001446;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 32px;
	font-size: 1rem;
	font-weight: 400;
}

.left-nav-a.subnav-a:hover {
	background-color: #1a428a;
	border-left-color: #296f9e;
	border-radius: 10px;
	padding-right: 20px;
	color: #fff;
}

.left-nav-a.subnav-ab {
	color: #001446;
	padding-top: 10px;
	padding-bottom: 11px;
	padding-left: 48px;
	font-size: 1rem;
}

.left-nav-a.subnav-ab:hover {
	background-color: #1a428a;
	border-left-color: #296f9e;
	border-radius: 10px;
	padding-right: 20px;
	color: #fff;
}

.right-side-title {
	color: var(--grip-blue);
	margin-top: 0;
	margin-bottom: 0;
	font-size: 18px;
	font-weight: 700;
	line-height: 24px;
}

.right-side-title.cta {
	margin-top: 16px;
	margin-bottom: 8px;
	padding-bottom: 8px;
}

.page-title {
	color: var(--white);
	text-align: center;
	max-width: 700px;
	margin-bottom: 40px;
	font-size: 48px;
	font-weight: 700;
	line-height: 3rem;
}

.page-title.portfolio {
	color: var(--grip-blue);
	text-align: left;
	max-width: none;
	line-height: 2rem;
}

.breadcrumbs-div ol li.breadcumbs-divider {
	margin-left: 5px;
	margin-right: 5px;
	display: inline-block;
}

.right-side-col {
	grid-column-gap: 10px;
	grid-row-gap: 10px;
	color: var(--dark-slate-grey);
	border: 3px solid #e6e6e666;
	border-radius: 10px;
	flex-flow: column;
	flex: none;
	max-width: 225px;
	padding: 20px;
	display: flex;
}

.right-side-col.single {
	max-width: 285px;
}

.right-side-div {
	border-top: 1px solid #e6e6e666;
	padding-top: 10px;
}

.right-side-div.first {
	border-top-style: none;
	margin-top: 8px;
	margin-bottom: 0;
	padding-top: 0;
}

.a {
	color: #e71c00;
	font-size: 16px;
}

.a:hover {
	color: var(--grip-blue);
	text-decoration: none;
}

.content-2 {
	flex: auto;
	max-width: 800px;
}

.breadcrumb-a {
	color: #747474;
	text-transform: capitalize;
	font-family: Outfit, sans-serif;
	font-size: 1rem;
	text-decoration: none;
}

.left-nav-div {
	background-color: #f9f9f9;
	border-radius: 10px;
	min-width: 300px;
	max-width: 300px;
	margin-top: 50px;
	margin-right: 32px;
	overflow: hidden;
}

.left-nav-col {
	flex: none;
}

.left-sub-nav-div {
	background-color: #ededed;
	color: var(--white);
	border-radius: 10px;
	margin-left: 8px;
	margin-right: 8px;
}

.left-sub-nav-div.grandchild {
	background-color: #e6e6e6;
}

.inside-page-banner {
	transform-origin: 50% 100%;
	background-position: 0 0, 50%;
	background-repeat: repeat, no-repeat;
	background-size: auto, cover;
	border-radius: 10px;
	justify-content: center;
	align-items: center;
	height: 350px;
	padding-top: 32px;
	padding-left: 32px;
	padding-right: 32px;
	display: flex;
}

.breadcrumb-current {
	color: var(--grip-blue);
	text-transform: capitalize;
	font-family: Outfit, sans-serif;
	font-size: 1rem;
	display: inline-block;
}

.right-side-p {
	font-family: Outfit, sans-serif;
	line-height: 1.3em;
}

.breadcrumbs-div {
	color: #727272;
	justify-content: flex-start;
	align-items: stretch;
	width: 100%;
	max-width: 1100px;
	margin-bottom: 32px;
	margin-left: auto;
	margin-right: auto;
	font-size: 0.8em;
	font-weight: 500;
	display: flex;
	position: relative;
	flex-wrap: wrap;
}

.breadcrumbs-div.casestudy {
	z-index: 200;
	background-color: var(--white);
	border-radius: 50px;
	justify-content: space-between;
	width: 100%;
	max-width: 1100px;
	margin-left: auto;
	margin-right: auto;
	padding: 10px 10px 10px 16px;
	display: flex;
}

.content-section {
	flex-flow: column;
	flex: 1 0 auto;
	padding-bottom: 6rem;
	display: flex;
}

.content-section.casestudy {
	padding-top: 32px;
}

.content-wrap {
	grid-column-gap: 32px;
	grid-row-gap: 32px;
	align-items: flex-start;
	display: flex;
	width: 100%;
}

.content-wrap.vertical {
	flex-flow: column;
	justify-content: flex-start;
	align-items: center;
}

.content-col {
	flex: auto;
}

.content-col.full {
	max-width: 700px;
}

.content-col._2column {
	max-width: 1100px;
	margin-left: auto;
	margin-right: auto;
}

.body-2 {
	font-family: filson-pro, sans-serif;
}

.h6 {
	color: var(--grip-blue);
	font-size: 16px;
}

.h5 {
	color: var(--grip-blue);
	font-size: 18px;
	font-weight: 700;
	line-height: 22px;
}

.h5.center {
	text-align: center;
}

.h2 {
	color: var(--grip-blue);
	font-size: 46px;
	line-height: 55px;
}

.h2.extraspace {
	margin-bottom: 40px;
}

.h3 {
	color: var(--grip-blue);
}

.h3.alt {
	margin-bottom: 0;
	font-size: 16px;
}

.h4 {
	color: var(--grip-blue);
	font-size: 28px;
}

.link-2 {
	color: var(--dark-slate-grey);
}

.insidepagebannerwrapper {
	width: 100%;
	padding-top: 16px;
	padding-left: 16px;
	padding-right: 16px;
	overflow: hidden;
}

.insidepagebannerwrapper.casestudy {
	background-image: none;
	padding-top: 50px;
}

.div-block-18 {
	justify-content: center;
	align-items: flex-end;
	width: 100%;
	max-width: 1300px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
}

.img {
	background-image: url("/20181016082423/assets/images/cms_0498B289D05BE.svg");
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: contain;
	width: 100%;
	height: 300px;
	margin: 32px auto;
}

.list-2 {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	color: var(--black);
	flex-flow: column;
	justify-content: flex-start;
	align-items: flex-start;
	font-family: Outfit, sans-serif;
	display: flex;
}

.date {
	color: #747474;
	font-size: 14px;
}

.filter-text {
	color: var(--grip-blue);
	letter-spacing: normal;
	font-size: 16px;
	line-height: 30px;
}

.arrow {
	background-image: url("/20181016082423/assets/images/simple-arrow-grip-blue.svg");
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: contain;
	width: 20px;
	height: 20px;
	transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.arrow:hover {
	height: 18px;
}

.arrow.left {
	transform: rotate(180deg);
}

.link-3,
.link-4,
.link-5 {
	color: #e71c00;
}

.paragraph-11 {
	color: var(--black);
	font-family: Outfit, sans-serif;
	font-size: 16px;
	font-weight: 400;
	position: static;
}

.paragraph-13 {
	color: var(--black);
	font-weight: 400;
}

.list-item,
.list-item-2,
.list-item-3 {
	color: var(--black);
}

.heading-6 {
	color: var(--grip-blue);
}

.paragraph-14 {
	margin-bottom: 32px;
	font-family: Outfit, sans-serif;
	font-size: 16px;
}

.text-block-66 {
	text-transform: capitalize;
	font-size: 16px;
}

.nav-content-wrap-2 {
	grid-column-gap: 8px;
	grid-row-gap: 8px;
	flex-flow: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 0;
	display: flex;
}

.icon-9 {
	margin-right: 5px;
}

.icon-9.rotate {
	transform: rotate(-90deg);
}

.navigation-dropdown-copy-2 {
	min-width: 140px;
}

.navigation-dropdown-copy-2.w--open {
	background-color: #ddd0;
	max-height: 100vh;
	padding-top: 20px;
	left: auto;
}

.navigation-dropdown-copy-2._2 {
	z-index: 7;
	min-width: 200px;
	bottom: 0;
}

.navigation-dropdown-copy-2._2.w--open {
	z-index: 1000;
	padding-top: 0;
	padding-left: 16px;
	bottom: 0;
	left: 100%;
}

.icon-10,
.icon-11 {
	margin-right: 0;
	position: static;
}

.menu-button {
	justify-content: flex-end;
	align-items: center;
	padding: 0;
	display: none;
}

.menu-button.w--open {
	background-color: #fff0;
}

.d-88-menu-trigger-wrapper {
	z-index: 2;
	cursor: pointer;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	display: flex;
	position: relative;
}

.d-88-trigger-line {
	background-color: #005eb8;
	border-radius: 20px;
	width: 25px;
	height: 3px;
	display: block;
	position: absolute;
}

.dropdown-list {
	opacity: 0;
	text-align: left;
	background-color: #0a706b;
	min-width: 200px;
	max-width: 200px;
	height: 0;
	margin-left: 0.75rem;
	padding: 0.5rem;
	position: absolute;
	overflow: hidden;
}

.main-nav-link {
	color: #2d3047;
	text-transform: uppercase;
	padding: 0.6rem 0.75rem;
	font-size: 1rem;
	font-weight: 700;
	line-height: 1.7rem;
	text-decoration: none;
	transition: color 0.3s;
	display: block;
	position: relative;
}

.main-nav-link:hover,
.main-nav-link.w--current {
	color: #0a706b;
	box-shadow: inset 0 3px #0a706b;
}

.container {
	max-width: 1300px;
	margin-left: auto;
	margin-right: auto;
	display: block;
}

.container.nav-container {
	float: none;
	justify-content: center;
	margin-top: 40px;
	display: flex;
}

.dropdown-link {
	color: #fff;
	margin-top: 2px;
	margin-bottom: 2px;
	padding: 0.5rem;
	font-size: 0.9rem;
	font-weight: 400;
	line-height: 1.3rem;
	text-decoration: none;
	transition: all 0.2s;
	display: block;
}

.dropdown-link:hover {
	color: #0a706b;
	background-color: #fff;
	font-weight: 400;
}

.dropdown-link.w--current {
	color: #0a706b;
	background-color: #fff;
}

.dropdown-wrapper {
	text-align: center;
	flex-shrink: 0;
	overflow: hidden;
}

.div-block-20 {
	align-self: center;
	margin-right: auto;
	font-size: 16px;
}

.paragraph-15 {
	text-align: center;
	max-width: 700px;
}

.hero_container {
	grid-column-gap: 0rem;
	grid-row-gap: 0rem;
	flex-flow: column;
	justify-content: center;
	align-items: flex-start;
	height: auto;
	display: flex;
	position: relative;
	top: 0%;
}

.canvas-wrapper {
	z-index: 0;
	pointer-events: auto;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	height: auto;
	min-height: auto;
	display: flex;
	position: absolute;
	inset: 0%;
}

.hero__heading-wrap-2 {
	z-index: 1;
	grid-column-gap: 2rem;
	grid-row-gap: 2rem;
	pointer-events: none;
	color: #fff0;
	background-color: #fff0;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
	width: 100%;
	height: auto;
	min-height: auto;
	margin-top: 0;
	margin-bottom: 0;
	font-weight: 300;
	display: flex;
	position: relative;
}

.padding-global-5 {
	padding-left: 2.5rem;
	padding-right: 2.5rem;
	position: relative;
}

.padding-global-5.padding-section-large {
	flex: 0 auto;
}

.padding-global-5.padding-section-large.height-100 {
	height: 100%;
	padding-top: 0;
	padding-bottom: 2rem;
	padding-left: 0;
}

.hero-heading {
	grid-column-gap: 0rem;
	grid-row-gap: 0rem;
	flex-flow: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: auto;
	height: auto;
	margin-top: 0;
	display: flex;
	overflow: hidden;
}

.hero_sticky-container {
	z-index: 5;
	height: auto;
}

.hero_h1-wrapper-overflow {
	color: #fff;
	height: 100%;
	font-size: 80px;
	font-weight: 500;
	line-height: 100px;
	display: block;
	overflow: hidden;
	text-transform: lowercase;
	opacity: 0;
}

.hero_h1-wrapper {
	grid-column-gap: 2.1rem;
	grid-row-gap: 2.1rem;
	color: #fff;
	flex-flow: column;
	justify-content: flex-start;
	align-items: flex-start;
	height: 100px;
	display: flex;
}

.hero_content {
	grid-column-gap: 0.6rem;
	grid-row-gap: 0.6rem;
	flex-flow: column;
	grid-template-rows: auto;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	place-content: start center;
	place-items: flex-start end;
	width: 100%;
	max-width: none;
	font-size: 50px;
	font-weight: 300;
	display: flex;
	position: relative;
	bottom: 0%;
}

.hero__heading-2 {
	color: #fff;
	margin-bottom: 0;
	font-size: 80px;
	font-weight: 700;
	line-height: 1.05em;
	opacity: 0;
}

.text-block-67 {
	color: #494949;
	text-align: center;
	text-transform: uppercase;
	font-size: 12px;
	font-style: italic;
	text-decoration: none;
}

.paragraph-16 {
	color: #474747;
}

.bodytext {
	font-family: Outfit, sans-serif;
}

@media screen and (min-width: 1440px) {
	.navigation-bar {
		min-width: auto;
	}

	.hero__read-wrap {
		left: 101px;
	}

	.our-work {
		grid-column-gap: 32px;
		grid-row-gap: 32px;
	}

	.logwrap {
		max-width: none;
	}

	.image-33 {
		min-height: 425px;
		width: auto;
	}

	.image-34 {
		max-height: 37vh;
		left: 16%;
	}

	.image-35 {
		min-height: 250px;
		max-height: 41vh;
		right: 12%;
		height: auto;
	}

	.slider {
		margin-bottom: -24px;
	}

	.column {
		border-right-color: #161616;
		position: relative;
	}

	.text-block-5 {
		font-size: 18px;
	}

	.cardexpandingcontent {
		margin-top: -5.5rem;
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}

	.right-arrow {
		right: 0;
	}

	.left-arrow {
		left: 0;
	}

	.news__cat-title {
		z-index: 1;
		position: relative;
	}

	.slidecontentwrapper.case {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
	}
}

@media screen and (max-width: 1300px) and (min-width: 992px) {
	.get-started-button.bloop-animation {
		display: none;
	}

	.navigation-bar.action-items {
		min-width: unset;
	}

	.live-chat-button.bloop-animation {
		display: none;
	}

	.live-chat-button.bloop-animation.mobile {
		background-position: 50%;
		width: 40px;
		padding-right: 15px;
		display: block;
	}

	.logo30 {
		width: 200px;
	}

	.logowhite {
		width: 200px;
	}

	.logov3_marquee-2 {
		animation-duration: 40s;
	}
}

@media screen and (max-width: 991px) {
	.mini-menu-dropdown-toggle {
		position: relative;
		z-index: 1;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 40px;
		margin-top: 0px;
		margin-bottom: 0px;
		padding: 5px 25px;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-radius: 20px;
		background-color: #1a428a;
		color: #fff;
		font-size: 1.1em;
		line-height: 1.1em;
		letter-spacing: 1px;
		cursor: pointer;
	}

	.mini-menu-dropdown-toggle.w--open {
		background-color: #a32615;
		color: #fff;
	}

	.mini-menu-dropdown-list {
		font-size: 1rem;
		position: relative;
		overflow: hidden;
		display: none;
		background-color: #dee2e6;
	}

	.mini-menu-dropdown.active .mini-menu-dropdown-toggle {
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 0px;
	}

	.mini-menu-dropdown-list .left-sub-nav-div {
		margin-right: 10px;
	}

	.mini-menu-dropdown {
		display: block !important;
		overflow: hidden;
		max-width: 500px;
		margin-bottom: 20px;
		font-size: 0.8em;
	}

	.mini-menu-dropdown-a,
	.mini-menu-dropdown-list a {
		display: block;
		padding: 12px 20px;
		color: #353738;
		text-decoration: none;
	}

	.header.insidepage {
		box-shadow: 0 0 10px #0003;
	}

	.herosection {
		flex-flow: column;
		padding-top: 100px;
		display: flex;
	}

	.content-outer {
		align-self: flex-start;
	}

	.content-outer.mod--bg-parallax {
		max-width: none;
	}

	.content-outer.mod--invest {
		justify-content: space-between;
		align-items: flex-start;
	}

	.content-outer.mod--header {
		justify-content: space-between;
		margin-left: 0;
		margin-right: 0;
		display: block;
	}

	.content-outer.mod--footer {
		max-width: none;
	}

	.header__columns {
		justify-content: space-between;
	}

	.section.mod--header {
		justify-content: center;
		height: auto;
		padding-left: 0;
		padding-right: 0;
		display: flex;
		top: 0;
		left: 0;
		right: 0;
	}

	.section.mod--hero {
		flex-flow: column;
		justify-content: flex-start;
		align-self: center;
		align-items: center;
		margin-left: auto;
		margin-right: auto;
		padding: 40px 32px 100px;
	}

	.section.mod--invest {
		min-height: auto;
		padding-top: 60px;
		padding-bottom: 60px;
	}

	.section.mod--news {
		padding: 100px 32px;
	}

	.hero__columns {
		flex-flow: column;
		padding-left: 0;
	}

	.logo-link {
		padding-left: 16px;
	}

	.navigation-bar {
		background-color: #ddd0;
		padding-right: 16px;
		position: static;
	}

	.navigation-bar.centered {
		display: none;
	}

	.navigation-bar.buttons {
		width: auto;
	}

	.hero__col.mod--1 {
		max-width: none;
	}

	.hero__col.mod--2 {
		width: 100%;
		padding-right: 0;
		position: absolute;
		top: 25vh;
	}

	.overflow-hidden {
		width: 100%;
	}

	.hero__circles {
		justify-content: center;
		align-items: flex-start;
		width: 100%;
	}

	.hero__circle-wrap.mod--1 {
		margin-left: auto;
		margin-right: auto;
	}

	.hero__circle-dot {
		top: 116px;
		left: 220px;
	}

	.hero__circle-dot._2nd {
		left: -104px;
	}

	.hero__circle-dot._3rd {
		left: auto;
	}

	.bg-circle {
		position: static;
		top: 113px;
		right: auto;
	}

	.bglogoanimated {
		opacity: 0.41;
		max-width: 450px;
		height: auto;
		margin-left: auto;
		margin-right: auto;
		position: absolute;
		top: 20px;
	}

	.invest__heading {
		margin-top: -16px;
		font-size: 2.5rem;
	}

	.hero__read.alt {
		order: 1;
		margin-top: 60px;
		margin-left: auto;
		margin-right: auto;
		position: relative;
		top: 215px;
		left: auto;
		right: auto;
	}

	.bg-circle-wrap {
		justify-content: center;
		align-items: center;
		display: flex;
	}

	.page-wrap {
		left: 0;
		right: 0;
	}

	.hero__heading-wrap {
		max-width: 650px;
	}

	.hero__heading {
		text-align: center;
		font-size: 70px;
		line-height: 80px;
	}

	.hero__heading.mod--2,
	.hero__heading.mod--3 {
		left: auto;
	}

	.hero__heading.light-blue {
		text-align: center;
	}

	.hero__heading.mod--3 {
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		font-size: 60px;
		line-height: 70px;
		left: auto;
	}

	.hero__heading.mod--2 {
		text-align: center;
		font-size: 60px;
		line-height: 70px;
		left: auto;
	}

	.hero__heading.light-blue {
		text-align: center;
		font-size: 60px;
		line-height: 70px;
	}

	.secctionheading {
		font-size: 2.5rem;
	}

	.secctionheading.centered {
		max-width: 500px;
	}

	.secctionheading.second {
		font-size: 1.8rem;
	}

	.our-work {
		grid-column-gap: 32px;
		grid-row-gap: 32px;
		min-height: auto;
		padding-top: 100px;
		padding-bottom: 100px;
	}

	.news.section-footer-fixed {
		padding-top: 60px;
		padding-bottom: 60px;
		position: relative;
	}

	.fixed-footer-visibility-block {
		display: none;
	}

	.trusted-companies-strip {
		padding-top: 6rem;
		padding-bottom: 6rem;
		overflow: hidden;
	}

	.container-default {
		margin-bottom: 2rem;
	}

	.logo-strip-image.shadow {
		border-radius: 11px;
		width: 200px;
	}

	.newssection {
		padding-left: 32px;
		padding-right: 32px;
	}

	.button-link,
	.button-link.black {
		padding-left: 0;
		padding-right: 0;
	}

	.button-link.article {
		color: var(--dark-slate-grey);
	}

	.hover-line-fill.article {
		background-color: var(--dark-slate-grey);
	}

	.text-block-3 {
		line-height: 1.6em;
	}

	.logov3_marquee {
		grid-column-gap: 1.5rem;
		grid-row-gap: 1.5rem;
		grid-template-rows: auto;
		grid-template-columns: 1fr 1fr 1fr;
		grid-auto-columns: 1fr;
		align-items: center;
		width: 100%;
		height: auto;
		display: grid;
	}

	.logov3_item.alt {
		height: 50px;
	}

	.logov3_image.large {
		height: 60px;
	}

	.certifications {
		max-height: none;
	}

	.solutions {
		padding-top: 60px;
		padding-bottom: 100px;
	}

	.ths12-overlay---logo {
		opacity: 0.07;
		background-position: 50%;
		background-size: auto 35%;
		background-attachment: fixed;
	}

	.footer-column-wrap {
		width: 50%;
	}

	.columns-2 {
		justify-content: flex-start;
		align-items: flex-start;
	}

	.footer-signup {
		width: 100%;
	}

	.signoff-div {
		padding-left: 32px;
		padding-right: 32px;
	}

	.grip-logo-image {
		width: auto;
	}

	.footer-other-clinics-wrap {
		width: 100%;
	}

	.footer-copyright-2 {
		float: left;
		margin-bottom: 0.25rem;
		margin-right: 0;
	}

	.footer-signoff-link-wrap {
		margin-bottom: 1.5rem;
	}

	.sign-off-div-wrap {
		width: 100%;
		min-width: auto;
	}

	.footer-content-wrap {
		padding-left: 32px;
		padding-right: 32px;
	}

	.column-3 {
		flex-direction: column;
		align-items: flex-start;
		display: flex;
	}

	.footer-content-row {
		flex-direction: column;
	}

	.column-2 {
		flex-direction: column;
		align-items: flex-end;
		padding-right: 0;
	}

	.footer-address-column {
		width: 100%;
	}

	.grip-logo-link {
		float: right;
		align-self: flex-end;
		width: 100%;
		margin-bottom: 1rem;
		margin-left: 0;
		margin-right: 0;
	}

	.div-block-6 {
		width: 100%;
	}

	.searchbox {
		border-top: 1px solid #5b5b5b26;
		padding: 8px;
	}

	.search-input-3 {
		padding-left: 16px;
		font-size: 0.8rem;
	}

	.search-icon {
		margin-top: 10px;
	}

	.search-icon.mobile {
		margin-top: 0;
		margin-left: auto;
		margin-right: 2rem;
		padding-left: 0;
	}

	.search-icon.mobile.bloop-animation {
		margin-right: 0;
	}

	.card-link {
		flex-flow: column;
		display: flex;
	}

	.card-item {
		height: 30rem;
	}

	.slidecontent {
		padding-left: 0;
		padding-right: 0;
	}

	.image-34 {
		left: 8%;
	}

	.image-35 {
		right: 8%;
	}

	.slider {
		max-width: none;
	}

	.bold {
		line-height: 3.5rem;
	}

	.project_date-and-link {
		color: var(--dark-slate-grey);
	}

	.project_image-wrapper {
		justify-content: flex-start;
		align-items: flex-start;
		display: flex;
	}

	.project_grid {
		flex-flow: column;
		grid-template-columns: 1fr 1fr 1fr;
		align-content: stretch;
		place-items: stretch stretch;
		width: 100%;
		height: auto;
		display: flex;
	}

	.project_wrapper {
		border: 1px solid var(--white);
		background-color: var(--white);
		color: var(--dark-slate-grey);
		border-radius: 15px;
		flex-flow: row;
		grid-template-rows: auto auto 100%;
		place-content: stretch space-between;
		align-items: stretch;
	}

	.project_cta-new-webstie {
		height: 20rem;
	}

	.project_heading {
		color: var(--dark-slate-grey);
	}

	.margin-top-2 {
		margin-bottom: 0;
		margin-left: 0;
		margin-right: 0;
	}

	.margin-top-2.margin-medium {
		margin-bottom: 1rem;
	}

	.project_description {
		color: var(--dark-slate-grey);
	}

	.project_content-wrapper {
		background-color: #1d428a00;
		background-image: none;
		border-style: none;
		justify-content: space-between;
		align-items: flex-start;
		width: 50%;
		padding-left: 0;
		position: static;
	}

	.icon-embed-xlarge.absolute {
		position: absolute;
	}

	.notification-banner {
		align-items: center;
		display: flex;
	}

	.banner-wrap {
		margin-right: auto;
	}

	.brix---top-bar-close-icon {
		align-items: center;
		height: 30px;
		padding: 0 24px;
	}

	.brix---icon-small-hover {
		width: 20px;
		height: 20px;
	}

	.column-scroll-content-wrapper {
		justify-content: flex-start;
		align-items: flex-start;
		width: 65%;
		min-height: auto;
		margin-top: 0;
		padding-right: 2rem;
		display: flex;
	}

	.column-scroll-content {
		padding-top: 64px;
		padding-bottom: 64px;
	}

	.section-3-column-scroll {
		background-color: var(--verylightgrey);
		background-image: none;
		flex-flow: row;
		justify-content: flex-start;
		align-items: stretch;
		display: flex;
	}

	.column-scroll-sticky {
		width: auto;
		height: 100%;
		position: static;
	}

	.column-scroll-sticky-inner {
		flex-flow: column;
		justify-content: flex-start;
		align-items: flex-start;
		width: 100%;
		display: flex;
		position: static;
	}

	.overflow-hidden-4 {
		text-align: left;
	}

	.column-scroll-wrapper {
		flex-flow: column;
		order: 1;
		align-items: stretch;
		width: 100%;
		height: 100%;
		display: flex;
	}

	.column-scroll-content-copy {
		margin-top: 1rem;
	}

	.column-scroll-content-title.is-1,
	.column-scroll-content-title.is-2 {
		font-size: 3.5rem;
	}

	.column-scroll-item.is-1,
	.column-scroll-item.is-3,
	.column-scroll-item.is-2 {
		width: 100%;
		height: 33.33%;
		padding: 32px;
		position: static;
	}

	.page-padding {
		padding-left: 32px;
		padding-right: 0;
	}

	.hero__elem-circle1.mod--2 {
		width: auto;
	}

	.div-block-15 {
		grid-column-gap: 0px;
		grid-row-gap: 0px;
	}

	.card-background-2 {
		background-color: #fff;
		background-image: none;
	}

	.card-background-2._1st {
		height: 150px;
	}

	.card-heading-2 {
		padding-left: 2rem;
	}

	.cardexpandingcontent {
		background-color: var(--dark-slate-grey);
		height: 245px;
		min-height: auto;
		margin-top: 0;
		padding-top: 1.25rem;
		padding-left: 1.25rem;
		padding-right: 1.25rem;
	}

	.solutioncardimgwrap {
		height: 85px;
		top: 0;
	}

	.solutioncarditem {
		height: auto;
	}

	.solutionscardgrid {
		grid-column-gap: 2rem;
		grid-row-gap: 2rem;
		grid-template-columns: 1fr 1fr;
	}

	.solutionscardgrid.insidespace {
		grid-template-columns: 1fr 1fr 1fr;
	}

	.promo-text-2 {
		font-size: 18px;
		line-height: 28px;
	}

	.right-arrow {
		right: 0;
	}

	.left-arrow {
		left: 0;
	}

	.close-icon {
		align-items: center;
	}

	.brix---icon-small-hover-copy {
		width: 20px;
		height: 20px;
	}

	.news__col.mod--2,
	.news__col.mod--3,
	.news__col.mod--4 {
		padding-left: 0;
		padding-right: 0;
	}

	.news__columns {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		grid-template-rows: auto auto;
		grid-template-columns: 1fr 1fr;
		grid-auto-columns: 1fr;
		display: grid;
	}

	.mask {
		width: 100%;
		max-width: none;
	}

	.slidecontentwrapper.case {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		max-width: none;
	}

	.content-container {
		flex-direction: column;
		max-width: none;
	}

	.page-title {
		margin-top: 20px;
		font-size: 2.5em;
	}

	.right-side-col {
		width: 100%;
		max-width: none;
		margin-top: 30px;
		margin-left: 0;
	}

	.right-side-col.single {
		max-width: none;
	}

	.right-side-div {
		border-top-color: #d5d5d5;
	}

	.content-2 {
		width: 100%;
		max-width: none;
	}

	.left-nav-col {
		display: none;
	}

	.inside-page-banner {
		height: 250px;
	}

	.breadcrumbs-div.casestudy {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		flex-flow: column;
		justify-content: space-between;
		align-items: flex-start;
		margin-bottom: 0;
		padding-left: 32px;
		padding-right: 32px;
	}

	.content-section {
		padding-bottom: 4rem;
	}

	.content-wrap {
		flex-direction: column;
		display: block;
	}

	.h2 {
		font-size: 38px;
		line-height: 42px;
	}

	.h3 {
		font-size: 30px;
		line-height: 2rem;
	}

	.insidepagebannerwrapper {
		padding-top: 16px;
		padding-left: 16px;
		padding-right: 16px;
	}

	.insidepagebannerwrapper.casestudy {
		padding-top: 32px;
	}

	.filter-text {
		white-space: nowrap;
	}

	.menu-button {
		padding-left: 0;
		display: block;
	}

	.d-88-trigger-line {
		background-color: var(--light-blue-grip);
	}

	.main-nav-link {
		color: #1c2b3c;
		text-transform: capitalize;
		border-bottom: 1px solid #e3e5e7;
		padding: 1.3rem 1rem 1.3rem 2rem;
		line-height: 1rem;
	}

	.main-nav-link:hover {
		background-color: var(--grip-blue);
		box-shadow: none;
		color: var(--white);
	}

	.main-nav-link.w--current {
		color: #0a706b;
		background-color: #fff;
	}

	.main-nav-link.with-arrow {
		background-image: url("/20181016082423/assets/images/simple-arrow-grip-blue.svg");
		background-position: 95%;
		background-repeat: no-repeat;
		background-size: auto 18px;
	}

	.main-nav-link.with-arrow:hover {
		background-image: url("/20181016082423/assets/images/_simple-arrow-grip-white.svg");
	}

	.container {
		max-width: 991px;
	}

	.container.nav-container {
		width: 100%;
		max-width: none;
		margin-top: 0;
		display: block;
	}

	.dropdown-wrapper {
		display: none;
	}

	.div-block-20 {
		width: 100%;
	}

	.paragraph-15 {
		text-align: center;
	}

	.hero_container {
		pointer-events: auto;
	}

	.canvas-wrapper {
		pointer-events: auto;
		display: block;
	}

	.hero__heading-wrap-2 {
		pointer-events: auto;
		padding-top: 3rem;
	}

	.padding-global-5 {
		position: relative;
	}

	.hero_sticky-container {
		pointer-events: auto;
	}

	.hero_h1-wrapper-overflow {
		width: 100%;
	}

	.hero_h1-wrapper {
		justify-content: flex-start;
		align-items: center;
		width: 100%;
	}

	.hero_content {
		pointer-events: auto;
	}

	.hero__heading-2 {
		text-align: center;
		font-size: 60px;
		line-height: 70px;
	}

	.image-34 {
		max-height: 20vh;
	}

	.image-35 {
		max-height: 26vh;
	}

	.logov3_marquee-2 {
		animation-duration: 50s;
	}
}

@media screen and (max-width: 767px) {
	.image-35 {
		height: 130px;
	}

	.image-34 {
		height: 90px;
	}

	.herosection {
		padding-top: 140px;
	}

	.content-outer.mod--news {
		flex-flow: column;
		padding-left: 0;
	}

	.section.mod--hero {
		padding-top: 32px;
	}

	.logo-link {
		padding-left: 16px;
	}

	.navigation-bar {
		justify-content: flex-end;
		align-items: center;
		min-width: auto;
		padding-right: 5px;
	}

	.overflow-hidden {
		text-align: center;
	}

	.bglogoanimated {
		top: 135px;
	}

	.invest__heading {
		font-size: 1.9rem;
	}

	.hero__read.alt {
		margin-top: 0;
	}

	.news__heading-wrap {
		max-width: none;
		margin-bottom: 32px;
	}

	.hero__heading {
		font-size: 55px;
		line-height: 60px;
	}

	.hero__heading.mod--2,
	.hero__heading.mod--3 {
		line-height: 60px;
		position: static;
	}

	.hero__heading.light-blue {
		line-height: 60px;
	}

	.hero__heading.mod--3,
	.hero__heading.mod--2 {
		font-size: 50px;
		line-height: 60px;
		position: static;
	}

	.hero__heading.light-blue {
		font-size: 50px;
		line-height: 60px;
	}

	.secctionheading {
		font-size: 2.5rem;
	}

	.secctionheading.centered.white {
		margin-bottom: 16px;
	}

	.secctionheading.second {
		text-align: center;
		display: none;
	}

	.secctionheading.second.mobile {
		font-size: 1.2rem;
		color: #fff;
		display: block;
	}

	.trusted-companies-strip {
		padding-top: 100px;
		padding-bottom: 100px;
	}

	.container-default {
		margin-bottom: 2rem;
	}

	.logo-strip-image.shadow {
		width: 100px;
		margin: 8px 10px;
	}

	.logov3_marquee {
		grid-column-gap: 3rem;
		grid-row-gap: 3rem;
		grid-template-columns: 1fr 1fr 1fr;
	}

	.certifications {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.footer-column-wrap {
		width: 100%;
	}

	.columns-2 {
		flex-flow: wrap;
	}

	.footer-signup {
		width: 100%;
	}

	.grip-logo-image {
		min-width: auto;
		max-width: 200px;
		margin-left: auto;
		margin-right: auto;
	}

	.footer-other-clinics-wrap {
		width: 100%;
	}

	.footer-copyright-2 {
		float: none;
		text-align: center;
		margin-bottom: 0.5rem;
	}

	.footer-signoff-link-wrap {
		float: none;
		margin-bottom: 1rem;
	}

	.footer-content-wrap {
		padding-left: 16px;
		padding-right: 16px;
	}

	.column-3 {
		justify-content: flex-start;
		align-items: center;
		overflow: hidden;
	}

	.social-icons-wrap {
		padding-top: 1em;
		padding-bottom: 1em;
		padding-right: 0;
	}

	.footer-content-row {
		grid-column-gap: 1rem;
		grid-row-gap: 1rem;
		flex-direction: column;
	}

	.column-2 {
		padding-left: 0;
	}

	.footer-address-column {
		width: 100%;
	}

	.grip-logo-link {
		float: none;
		justify-content: center;
		align-self: center;
		align-items: center;
		margin-bottom: 0;
		display: flex;
	}

	.div-block-6 {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		flex-flow: column;
	}

	.searchbox {
		border-top: 1px solid #5b5b5b26;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		left: 20px;
	}

	.search-icon.mobile {
		margin-top: 0;
		margin-left: auto;
	}

	.search-icon.mobile.bloop-animation {
		margin-left: 0;
	}

	.exit-button-icon {
		margin-left: auto;
	}

	.logov3_marquee-2 {
		grid-template-columns: 1fr 1fr 1fr;
		animation-duration: 35s;
	}

	.live-chat-button {
		margin-left: 8px;
		padding-left: 15px;
		padding-right: 30px;
		display: block;
	}

	.live-chat-button.bloop-animation {
		display: none;
	}

	.live-chat-button.bloop-animation.mobile {
		background-position: 50%;
		width: 40px;
		padding-right: 15px;
		display: block;
	}

	.get-started-button {
		margin-left: 8px;
		padding-left: 15px;
		padding-right: 15px;
		display: none;
	}

	.get-started-button.bloop-animation {
		display: none;
	}

	.get-started-button.bloop-animation.mobile,
	.slidecontent.mobile {
		display: block;
	}

	.image-34 {
		left: 5%;
	}

	.image-35 {
		right: 3%;
	}

	.cbtext .image-35,
	.cbtext .image-34 {
		display: none;
	}

	.slider {
		display: none;
	}

	.icon-5.left {
		transform: rotate(180deg);
	}

	.bold {
		font-size: 2.5rem;
	}

	.project_image-wrapper {
		background-color: #fff0;
		justify-content: flex-start;
		align-items: flex-start;
		width: 45%;
	}

	.project_grid {
		grid-column-gap: 3rem;
		grid-row-gap: 3rem;
	}

	.project_wrapper {
		background-color: #fff0;
		border-style: none;
		flex-flow: row;
	}

	.project_cta-new-webstie {
		height: 22rem;
	}

	.project_heading {
		color: var(--white);
	}

	.margin-top-2 {
		margin-bottom: 0;
		margin-left: 0;
		margin-right: 0;
	}

	.project_description {
		color: var(--white);
	}

	.project_content-wrapper {
		flex-flow: column;
		grid-template-rows: auto auto;
		grid-template-columns: 1fr 1fr;
		grid-auto-columns: 1fr;
		width: 100%;
		padding-top: 0;
		padding-left: 16px;
		padding-right: 16px;
		display: flex;
	}

	.project_tag {
		background-color: var(--verylightgrey);
		color: var(--dark-slate-grey);
	}

	.notification-banner {
		padding: 10px 16px;
	}

	.banner-wrap {
		padding-left: 0;
	}

	.text-block-10 {
		font-weight: 700;
		line-height: 18px;
	}

	.brix---top-bar-close-icon {
		align-items: center;
		padding-left: 16px;
		padding-right: 0;
	}

	.brix---icon-small-hover {
		width: 24px;
	}

	.column-scroll-content-wrapper {
		width: 100%;
		min-height: auto;
		margin-top: 0;
		padding-right: 0;
	}

	.column-scroll-content {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.section-3-column-scroll {
		flex-flow: column;
	}

	.column-scroll-sticky {
		position: static;
	}

	.column-scroll-sticky-inner {
		width: 100%;
	}

	.column-scroll-wrapper {
		height: 500px;
	}

	.column-scroll-content-title {
		font-size: 15vw;
	}

	.page-padding {
		padding-top: 60px;
	}

	.column {
		background-image: none;
		background-position: 0 0;
		flex-direction: column;
		top: 28px;
		left: 28px;
	}

	.heading-5 {
		font-size: 100px;
	}

	.heading-5.followers {
		font-size: 65px;
	}

	.hero__elem.mod--1 {
		width: 170px;
		height: 170px;
	}

	.hero__elem-circle1-wrap {
		height: 55px;
	}

	.hero__elem-circle1-wrap.mod--1,
	.hero__elem-circle1-wrap.mod--2 {
		height: 85px;
	}

	.hero__elem-circle1 {
		width: 110px;
		height: 110px;
	}

	.hero__elem-circle1.mod--1,
	.hero__elem-circle1.mod--2 {
		width: 170px;
	}

	.card-heading-2 {
		padding-left: 0.75rem;
	}

	.cardexpandingcontent {
		align-items: flex-start;
		height: auto;
		padding: 2rem;
	}

	.solutionscardgrid {
		flex-flow: column;
		display: flex;
	}

	.solutionscardgrid.insidespace {
		grid-template-columns: 1fr;
	}

	.right-arrow {
		color: var(--white);
		justify-content: center;
		align-items: center;
		display: flex;
	}

	.left-arrow {
		justify-content: center;
		align-items: center;
		display: flex;
	}

	.close-icon {
		align-items: center;
	}

	.icon-block {
		width: 60%;
		height: 80%;
		min-height: 0;
		margin-left: auto;
		margin-right: auto;
	}

	.icon-block.small {
		width: 35%;
		height: 50%;
	}

	.icon-block.small._2 {
		width: 50%;
	}

	.news__columns {
		flex-flow: column;
		display: flex;
	}

	.logo30 {
		background-image: url("/20181016082423/assets/images/30-years-logo-icon.svg");
		min-width: 100px;
	}

	.page-title {
		margin-top: 0;
		margin-bottom: 0;
		line-height: 3rem;
	}

	.page-title.portfolio {
		margin-top: 0;
		margin-bottom: 0;
	}

	.right-side-col.single {
		max-width: none;
	}

	.left-nav-col {
		display: none;
	}

	.inside-page-banner.webdev {
		padding-top: 16px;
		padding-bottom: 16px;
	}

	.h3.alt {
		font-size: 16px;
		line-height: 1.2rem;
	}

	.div-block-18 {
		justify-content: center;
		align-items: center;
	}

	.filter-text {
		display: none;
	}

	.filter-text._2 {
		display: block;
	}

	.container {
		max-width: 767px;
	}

	.div-block-20 {
		display: none;
	}

	.paragraph-15 {
		text-align: center;
	}

	.hero_container {
		pointer-events: auto;
	}

	.canvas-wrapper {
		pointer-events: none;
		display: block;
	}

	.padding-global-5 {
		padding-left: 1.25rem;
		padding-right: 1.25rem;
	}

	.hero_sticky-container {
		pointer-events: auto;
	}

	.hero_h1-wrapper {
		height: 70px;
		grid-row-gap: 1.05rem;
	}

	.hero_h1-wrapper p {
		margin-bottom: 0;
	}

	.hero_content {
		pointer-events: auto;
	}

	.hero__heading-2 {
		font-size: 50px;
		line-height: 60px;
	}
}

@media screen and (max-width: 479px) {
	.image-35 {
		height: 150px;
	}

	.image-34 {
		height: 110px;
	}

	.mini-menu-dropdown-toggle {
		font-size: 1em;
	}

	.content-outer.mod--header {
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.section.mod--header {
		flex-flow: column;
		justify-content: flex-end;
		align-items: stretch;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.section.mod--hero {
		width: 100%;
		height: auto;
		padding-top: 0;
		padding-left: 16px;
		padding-right: 16px;
	}

	.section.mod--invest {
		padding: 60px 32px;
	}

	.section.mod--news {
		padding-top: 60px;
		padding-bottom: 60px;
	}

	.logo-link {
		width: auto;
	}

	.navigation-bar {
		justify-content: flex-end;
		align-items: center;
	}

	.hero__col.mod--2 {
		justify-content: center;
		align-self: center;
		align-items: flex-start;
		display: none;
		position: static;
	}

	.hero__circles {
		justify-content: center;
		align-self: center;
		align-items: flex-start;
		width: auto;
		display: block;
	}

	.hero__circle-wrap.mod--1 {
		margin-left: auto;
	}

	.hero__circle-dot {
		top: 31px;
		left: auto;
		right: 25px;
	}

	.hero__circle-dot._2nd {
		top: 153px;
		left: 47px;
	}

	.hero__circle-dot._3rd {
		width: 170px;
		height: 170px;
		margin-left: auto;
		margin-right: auto;
		position: static;
		top: 0;
	}

	.bglogoanimated {
		max-width: 80%;
	}

	.invest__heading {
		font-size: 1.5rem;
	}

	.hero__read.alt {
		top: 48px;
	}

	.logowhite {
		background-image: url("/20181016082423/assets/images/30-years-logo-icon---white.svg");
	}

	.hero__heading {
		font-size: 45px;
		line-height: 55px;
	}

	.hero__heading.mod--3,
	.hero__heading.mod--2,
	.hero__heading.light-blue {
		font-size: 40px;
		line-height: 50px;
	}

	.secctionheading {
		text-align: center;
	}

	.secctionheading.centered {
		font-size: 2rem;
	}

	.our-work {
		padding-top: 60px;
		padding-bottom: 0;
	}

	.news.section-footer-fixed {
		padding-top: 60px;
	}

	.trusted-companies-strip {
		padding-top: 60px;
		padding-bottom: 60px;
	}

	.container-default {
		padding-left: 20px;
		padding-right: 20px;
	}

	.logo-strip-image.shadow {
		width: 80px;
	}

	.button-link {
		margin-right: 0.8rem;
	}

	.button-link.black.footer {
		width: auto;
		align-self: center;
	}

	.button-link.article {
		color: var(--white);
	}

	.hover-line-fill.article {
		background-color: var(--white);
	}

	.text-block-3 {
		letter-spacing: 1px;
	}

	.logov3_component {
		width: 100%;
	}

	.logov3_marquee {
		grid-column-gap: 2rem;
		grid-row-gap: 2rem;
		flex-flow: column;
		grid-template-rows: auto auto auto;
		grid-template-columns: 1fr;
		grid-auto-flow: row;
		justify-content: flex-start;
		place-items: center;
		width: 100%;
		max-width: 100%;
		display: flex;
	}

	.logov3_item.alt {
		width: 200px;
		height: 60px;
	}

	.logov3_item.alt._2 {
		width: 125px;
	}

	.logov3_item._3cx {
		width: 115px;
	}

	.logov3_item.mob-adjustment {
		max-width: 225px;
	}

	.logov3_image.large {
		height: 100%;
	}

	.certifications {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}

	.solutions {
		padding-top: 60px;
		padding-bottom: 60px;
	}

	.ths12-overlay---logo {
		opacity: 0.1;
		background-image: url("/20181016082423/assets/images/grip-white-logo-icon.svg");
		background-repeat: no-repeat;
		background-size: auto 35%;
		background-attachment: fixed;
	}

	.footer-column-wrap {
		width: 100%;
		align-items: center;
	}

	.footerbox {
		height: auto;
		padding: 24px;
	}

	.footerbox.full-height {
		justify-content: flex-start;
		align-items: flex-start;
	}

	.footer-column-title {
		text-align: left;
		font-size: 1.6rem;
	}

	.footer-column-title.mobcenter {
		text-align: center;
	}

	.signoff-div {
		padding-left: 24px;
		padding-right: 24px;
	}

	.grip-logo-image {
		min-width: 175px;
		margin-left: 0;
		margin-right: 0;
	}

	.footer-copyright-2 {
		text-align: center;
	}

	.column-3 {
		text-align: left;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 0.5rem;
	}

	.social-icons-wrap {
		float: none;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		margin-bottom: 0;
		padding-top: 0;
		padding-bottom: 0;
		overflow: hidden;
	}

	.column-2 {
		flex-flow: row;
		justify-content: center;
		align-items: flex-start;
		padding-left: 0;
	}

	.div-block-2 {
		flex-flow: row;
		margin-left: auto;
		margin-right: auto;
	}

	.primary-btn {
		min-width: auto;
	}

	.primary-btn.dark {
		white-space: nowrap;
	}

	.footer-address-column {
		flex-direction: column;
	}

	.grip-logo-link {
		float: none;
		flex-flow: column;
		justify-content: flex-start;
		align-self: auto;
		align-items: center;
		width: auto;
		margin-left: auto;
		margin-right: auto;
	}

	.div-block-6 {
		grid-column-gap: 32px;
		grid-row-gap: 32px;
	}

	.searchbox {
		grid-column-gap: 8px;
		grid-row-gap: 8px;
		border-top: 1px solid #5b5b5b26;
		align-items: center;
		width: auto;
		max-width: 380px;
		padding: 8px;
		display: flex;
		top: 90px;
		left: 16px;
		right: 16px;
	}

	.search-icon {
		margin-left: 10px;
		margin-right: 10px;
	}

	.search-icon.mobile {
		margin-left: auto;
		margin-right: 0.75rem;
		display: block;
	}

	.search-icon.mobile.bloop-animation {
		padding-bottom: 0;
	}

	.search-cb-wrapper {
		justify-content: center;
		align-items: center;
		display: flex;
	}

	.search-button-icon {
		width: 40px;
		height: 40px;
	}

	.search-button-icon:hover {
		border-radius: 20px;
	}

	.exit-cb-wrapper {
		justify-content: center;
		align-items: center;
		padding-bottom: 0;
		position: static;
	}

	.exit-button-icon {
		width: 40px;
		height: 40px;
		margin-left: 0;
	}

	.logov3_marquee-2 {
		grid-template-columns: 1fr;
		animation-duration: 40s;
	}

	.live-chat-button,
	.get-started-button {
		display: none;
	}

	.card-heading-wrapper {
		justify-content: space-around;
		align-items: center;
		margin-bottom: 16px;
	}

	.grid-item-overlay {
		margin-top: auto;
		margin-left: auto;
	}

	.card-link {
		border-radius: 15px;
		height: auto;
	}

	.card-heading-background {
		height: auto;
	}

	.card-heading-holder {
		flex: 0 auto;
		display: block;
	}

	.card-item {
		height: 25rem;
	}

	.image-33 {
		border-radius: 10px;
		min-width: 350px;
	}

	.image-33.case {
		max-width: 100%;
	}

	.image-34 {
		border-radius: 5px;
		max-width: 17%;
		left: 0%;
	}

	.image-34.case {
		display: none;
	}

	.image-35 {
		border-radius: 10px;
		max-width: 30%;
		right: 0%;
	}

	.image-35.case {
		display: none;
	}

	.bold {
		font-size: 2rem;
		line-height: 3rem;
	}

	.project_date-and-link {
		grid-row-gap: 1rem;
		flex-direction: column;
		align-items: flex-start;
	}

	.project_image-wrapper {
		justify-content: center;
		align-items: flex-start;
		width: 100%;
	}

	.project_grid {
		grid-column-gap: 2rem;
		grid-row-gap: 2rem;
	}

	.project_wrapper {
		flex-flow: column;
		justify-content: space-between;
		align-items: stretch;
	}

	.project_cta-new-webstie {
		height: 25rem;
	}

	.project_heading {
		height: auto;
		font-size: 1.5rem;
	}

	.project_tag-group {
		grid-row-gap: 0.625rem;
		flex-direction: column;
		align-items: flex-start;
	}

	.margin-top-2 {
		margin-bottom: 0;
		margin-left: 0;
		margin-right: 0;
	}

	.project_description {
		max-width: 100%;
		font-size: 0.875rem;
	}

	.project_content-wrapper {
		color: var(--white);
		padding-top: 32px;
		padding-left: 0;
		padding-right: 0;
	}

	.project_info {
		max-width: 316px;
	}

	.paragraph-2-copy {
		max-width: 100%;
	}

	.notification-banner {
		padding-left: 16px;
		padding-right: 16px;
	}

	.text-block-10 {
		font-size: 12px;
	}

	.brix---top-bar-close-icon {
		padding-left: 12px;
		padding-right: 8px;
	}

	.brix---icon-small-hover {
		width: 20px;
	}

	.column-scroll-content {
		padding-top: 0;
	}

	.column-scroll-sticky {
		height: auto;
	}

	.column-scroll-sticky-inner {
		position: relative;
	}

	.column-scroll-wrapper {
		height: auto;
	}

	.column-scroll-content-title.is-1,
	.column-scroll-content-title.is-2 {
		font-size: 2rem;
		line-height: 2.4rem;
	}

	.column-scroll-item {
		aspect-ratio: 3 / 2;
		width: 100%;
		height: auto;
		position: relative;
	}

	.column-scroll-item.is-1 {
		aspect-ratio: auto;
		height: auto;
	}

	.column-scroll-item.is-3 {
		aspect-ratio: 2.39;
	}

	.column-scroll-item.is-2 {
		aspect-ratio: auto;
		height: auto;
	}

	.page-padding {
		padding-right: 32px;
	}

	.column {
		background-image: none;
		background-position: 0 0;
		background-size: auto;
		left: 0;
	}

	.heading-5 {
		font-size: 80px;
	}

	.text-block-5 {
		font-size: 14px;
	}

	.card-background-2 {
		filter: saturate(125%);
		margin-top: 0;
	}

	.card-background-2._1st {
		justify-content: center;
		align-items: center;
	}

	.card-heading-2 {
		height: auto;
		margin-bottom: 0;
		padding-top: 0.7rem;
		padding-bottom: 0.7rem;
		padding-left: 1rem;
		font-size: 1.5rem;
		line-height: 1.5rem;
	}

	.solutioncardimgwrap {
		z-index: 50;
		position: absolute;
		top: auto;
		left: auto;
		right: auto;
	}

	.solutioncarditem {
		border-radius: 15px;
	}

	.promo-text-2 {
		justify-content: flex-start;
		align-items: flex-start;
	}

	.icon-block {
		width: 80%;
	}

	.icon-block.small {
		width: 45%;
	}

	.icon-block.small._2 {
		width: 60%;
	}

	.slidecontentwrapper {
		margin-left: 0;
		margin-right: 0;
	}

	.slidecontentwrapper.case {
		flex-flow: column;
		justify-content: flex-start;
		align-items: center;
		padding-bottom: 32px;
	}

	.logo30 {
		background-image: url("/20181016082423/assets/images/30-years-logo-icon.svg");
	}

	.content-container.left {
		padding-top: 32px;
	}

	.page-title {
		width: 100%;
		margin-top: 0;
		margin-bottom: 0;
		font-size: 2rem;
		line-height: 2.5rem;
	}

	.page-title.portfolio {
		margin-bottom: 16px;
	}

	.right-side-col.single {
		max-width: none;
	}

	.content-section {
		width: 100%;
		margin-top: 0;
	}

	.content-wrap {
		width: 100%;
	}

	.content-col._2column._1 {
		width: 100%;
	}

	.h2 {
		font-size: 32px;
		font-weight: 400;
		line-height: 36px;
	}

	.h3.alt {
		font-size: 16px;
	}

	.insidepagebannerwrapper.casestudy {
		flex-flow: column;
		display: flex;
	}

	.img {
		height: 200px;
	}

	.filter-text {
		margin-left: 0;
		display: none;
	}

	.container,
	.container.nav-container {
		max-width: none;
	}

	.paragraph-15 {
		text-align: center;
	}

	.hero_container {
		pointer-events: auto;
		justify-content: flex-start;
		align-items: center;
		padding-top: 5rem;
	}

	.canvas-wrapper {
		pointer-events: none;
		height: 100vh;
		display: block;
		inset: auto 0% 0%;
	}

	.hero__heading-wrap-2 {
		pointer-events: auto;
		margin-top: -4.5rem;
		padding-top: 0;
	}

	.padding-global-5.padding-section-large.height-100 {
		width: 100%;
		padding-bottom: 0;
		padding-right: 0;
	}

	.hero-heading {
		grid-column-gap: 0rem;
		grid-row-gap: 0rem;
		pointer-events: auto;
		flex-flow: column;
		justify-content: center;
		align-items: center;
		height: auto;
	}

	.hero_sticky-container {
		pointer-events: auto;
	}

	.hero_h1-wrapper-overflow {
		flex: 0 auto;
		height: 4rem;
		line-height: 1.5;
	}

	.hero_h1-wrapper {
		height: 60px;
		row-gap: 0.75rem;
	}

	.hero_content {
		grid-column-gap: 0rem;
		grid-row-gap: 0rem;
		pointer-events: auto;
		justify-content: center;
		align-items: center;
	}

	.hero__heading-2 {
		font-size: 40px;
		line-height: 48px;
	}
}

.mini-menu-dropdown-toggle:after {
	content: '\f078';
	font-family: 'FontAwesome';
	font-size: 0.9em;
	display: block;
}

#w-node-f0936d22-5a0e-ad7a-c0a9-4c4ae49ad67b-3ec42efe,
#w-node-f0936d22-5a0e-ad7a-c0a9-4c4ae49ad67d-3ec42efe,
#w-node-f0936d22-5a0e-ad7a-c0a9-4c4ae49ad67f-3ec42efe,
#w-node-f0936d22-5a0e-ad7a-c0a9-4c4ae49ad681-3ec42efe,
#w-node-f0936d22-5a0e-ad7a-c0a9-4c4ae49ad683-3ec42efe,
#w-node-f0936d22-5a0e-ad7a-c0a9-4c4ae49ad685-3ec42efe,
#w-node-de826189-3447-bc7a-6f64-2731227d9d7a-3ec42efe,
#w-node-de826189-3447-bc7a-6f64-2731227d9d7c-3ec42efe,
#w-node-de826189-3447-bc7a-6f64-2731227d9d7e-3ec42efe,
#w-node-de826189-3447-bc7a-6f64-2731227d9d80-3ec42efe,
#w-node-de826189-3447-bc7a-6f64-2731227d9d82-3ec42efe,
#w-node-de826189-3447-bc7a-6f64-2731227d9d84-3ec42efe,
#w-node-_6cb572f2-5062-02d2-b42b-6b2048143df4-3ec42efe,
#w-node-_6cb572f2-5062-02d2-b42b-6b2048143df5-3ec42efe {
	grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_6cb572f2-5062-02d2-b42b-6b2048143df6-3ec42efe,
#w-node-_6cb572f2-5062-02d2-b42b-6b2048143df8-3ec42efe {
	grid-area: 1 / 1 / 2 / 2;
}

#w-node-_6cb572f2-5062-02d2-b42b-6b2048143e0d-3ec42efe {
	grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_6cb572f2-5062-02d2-b42b-6b2048143e0e-3ec42efe,
#w-node-_6cb572f2-5062-02d2-b42b-6b2048143e10-3ec42efe {
	grid-area: 1 / 1 / 2 / 2;
}

#w-node-_6cb572f2-5062-02d2-b42b-6b2048143e25-3ec42efe {
	grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_6cb572f2-5062-02d2-b42b-6b2048143e26-3ec42efe,
#w-node-_6cb572f2-5062-02d2-b42b-6b2048143e28-3ec42efe {
	grid-area: 1 / 1 / 2 / 2;
}

#w-node-_6cb572f2-5062-02d2-b42b-6b2048143e3c-3ec42efe,
#w-node-_6cb572f2-5062-02d2-b42b-6b2048143e3d-3ec42efe {
	grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_6cb572f2-5062-02d2-b42b-6b2048143e3e-3ec42efe,
#w-node-_6cb572f2-5062-02d2-b42b-6b2048143e40-3ec42efe {
	grid-area: 1 / 1 / 2 / 2;
}

#w-node-_7b6d945a-0109-72f8-feba-d41885a2ac59-85a2ac53,
#w-node-_7b6d945a-0109-72f8-feba-d41885a2ac5b-85a2ac53,
#w-node-_7b6d945a-0109-72f8-feba-d41885a2ac5d-85a2ac53,
#w-node-_7b6d945a-0109-72f8-feba-d41885a2ac5f-85a2ac53,
#w-node-_7b6d945a-0109-72f8-feba-d41885a2ac61-85a2ac53,
#w-node-_7b6d945a-0109-72f8-feba-d41885a2ac63-85a2ac53 {
	grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_7b6d945a-0109-72f8-feba-d41885a2ac8c-85a2ac53 {
	grid-area: span 1 / span 1 / span 1 / span 1;
	justify-self: start;
}

#w-node-_5ab66106-aa62-f51a-22a9-f267f8233188-3ec42f01,
#w-node-_555a0f00-76ed-7add-cf30-6f609d7be834-3ec42f01,
#w-node-bd935e23-2634-f370-261c-8c92080dd06b-3ec42f01,
#w-node-_8090ba22-e185-959f-e828-7b0e99094951-3ec42f01,
#w-node-cf993667-b782-0a14-b288-c3e43a58f3d0-3ec42f01,
#w-node-_3bc0f643-d194-559a-7a5c-e148e71a4c53-3ec42f01,
#w-node-_7963670d-9e99-be69-c4a4-7062744b0eb9-3ec42f01,
#w-node-_70375280-0655-2d1a-eebe-f47b2a5db50f-3ec42f01,
#w-node-_9e795f51-e540-20fc-3791-c149c2a62f6b-3ec42f01 {
	justify-self: center;
}

@media screen and (max-width: 991px) {
	#w-node-_6cb572f2-5062-02d2-b42b-6b2048143df6-3ec42efe {
		align-self: auto;
	}

	#w-node-_6cb572f2-5062-02d2-b42b-6b2048143df8-3ec42efe {
		grid-area: 2 / 1 / 3 / 2;
		align-self: auto;
	}

	#w-node-_6cb572f2-5062-02d2-b42b-6b2048143e0e-3ec42efe {
		align-self: auto;
	}

	#w-node-_6cb572f2-5062-02d2-b42b-6b2048143e10-3ec42efe {
		grid-area: 2 / 1 / 3 / 2;
		align-self: auto;
	}

	#w-node-_6cb572f2-5062-02d2-b42b-6b2048143e26-3ec42efe {
		align-self: auto;
	}

	#w-node-_6cb572f2-5062-02d2-b42b-6b2048143e28-3ec42efe {
		grid-area: 2 / 1 / 3 / 2;
		align-self: auto;
	}

	#w-node-_6cb572f2-5062-02d2-b42b-6b2048143e3e-3ec42efe {
		align-self: auto;
	}

	#w-node-_6cb572f2-5062-02d2-b42b-6b2048143e40-3ec42efe {
		grid-area: 2 / 1 / 3 / 2;
		align-self: auto;
	}

	#w-node-_7b6d945a-0109-72f8-feba-d41885a2ac5f-85a2ac53,
	#w-node-_7b6d945a-0109-72f8-feba-d41885a2ac63-85a2ac53 {
		justify-self: center;
	}
}

@media screen and (max-width: 767px) {
	#w-node-_6cb572f2-5062-02d2-b42b-6b2048143df9-3ec42efe {
		grid-area: span 1 / span 2 / span 1 / span 2;
	}
}

/***************** Old CSS *******************************/
/*********************************************************/
@media (max-width: 39.9375em) {
	.tablesaw.styled tr {
		border-bottom: 2px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr td {
		border-right: 0px solid;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr:last-child {
		border-bottom: none;
	}

	.tablesaw.styled td .tablesaw-cell-label {
		background-color: #0f486f;
		color: #fff;
		padding: 10px 20px;
		margin: -10px 0px 15px -20px;
		width: calc(100% + 40px);
	}
}

.tablesaw-stack td .tablesaw-cell-label,
.tablesaw-stack th .tablesaw-cell-label {
	width: 100%;
}

.tablesaw-cell-content {
	width: 100%;
	max-width: none;
}

.grid {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: -1%;
	margin-bottom: 20px;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.grid-box {
	margin-right: 1%;
	margin-bottom: 15px;
	padding: 20px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.grid-box h3 {
	font-size: 18px;
	line-height: 22px;
	margin-top: 5px;
}

.grid-box.half {
	width: 49%;
}

.grid-box.third {
	width: 32.33%;
}

.grid-box.quarter {
	width: 24%;
}

.grid-box.fifth {
	width: 19%;
}

.clear {
	margin-bottom: 20px;
}

.info {
	position: relative;
	margin-bottom: 20px;
	padding: 20px 20px 20px 60px;
	border-radius: 3px;
	background-color: #b4e1ff;
	font-size: 1.1em;
}

.success {
	position: relative;
	margin-bottom: 20px;
	padding: 20px 20px 20px 60px;
	border-radius: 3px;
	background-color: #7dda8f;
	font-size: 1.1em;
}

.warning,
.error {
	position: relative;
	margin-bottom: 20px;
	padding: 20px 20px 20px 60px;
	border-radius: 3px;
	background-color: #ffa93b;
	font-size: 1.1em;
}

.caption-wrap {
	display: inline-block;
}

.image-caption {
	max-width: 100%;
	padding-top: 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid silver;
	font-size: 0.9em;
	line-height: 1.45em;
}

.check {
	position: relative;
	margin-left: -20px;
	padding-left: 20px;
	list-style-type: none;
}

.x {
	position: relative;
	margin-left: -20px;
	padding-left: 20px;
	list-style-type: none;
}

.mini-menu-dropdown {
	display: none;
}

#submissionforms_module.cms_form .cms_field.currency:before,
.responsive #submissionforms_module.cms_form .cms_field.currency:before {
	top: 35%;
	left: 7px;
}

@media (max-width: 732px) {
	br.spare-break {
		display: none;
	}
}

@media (max-width: 515px) and (min-width: 479px) {
	.get-started-button {
		margin-left: 7px;
		padding: 9px 9px 9px 12px;
		width: 136px;
	}
}

@media (max-width: 991px) {
	.grid-box.quarter {
		width: 49%;
	}

	.grid-box.fifth {
		width: 49%;
	}
}

@media (max-width: 767px) {
	.grid-box.half {
		width: 100%;
	}

	.grid-box.third {
		width: 49%;
	}

	.grid {
		margin-left: -20px;
		margin-right: calc(-20px + 1%);
	}
}

@media (max-width: 479px) {
	.grid-box {
		margin-right: 0%;
	}

	.grid-box.half {
		width: 100%;
	}

	.grid-box.third {
		width: 100%;
	}

	.grid-box.quarter {
		width: 100%;
	}

	.grid-box.fifth {
		width: 100%;
	}
}

.grid-box.center {
	text-align: center;
}

.get-started-button,
.live-chat-button,
.section-button,
.social-media-icon,
.posts-homepage-block {
	position: relative;
}

.get-started-button:before,
.live-chat-button:before,
.section-button:before,
.solution-icon:before,
.social-media-icon:before,
.posts-homepage-block:before {
	content: "";
	height: 100px;
	width: 100px;
	position: absolute;
	margin: 0 auto;
	top: -50%;
	right: 0;
	left: 0;
	border-radius: 50%;
	background-color: #ffffff;
	display: none;
}

.section-button:before,
.posts-homepage-block:before {
	height: 200px;
	width: 200px;
}

.solution-icon:before {
	height: 125px;
	width: 125px;
	top: 0;
}

.social-media-icon:before {
	height: 40px;
	width: 40px;
	top: -5px;
	left: -5px;
}

.get-started-button:hover:before,
.live-chat-button:hover:before,
.section-button:hover:before,
.social-media-link:hover .social-media-icon:before,
.posts-homepage-block:hover:before {
	display: block;
	animation: bloop 300ms linear;
	animation-fill-mode: forwards;
}

@keyframes bloop {
	0% {
		transform: scale(0);
		opacity: 0.5;
	}

	100% {
		transform: scale(1);
		opacity: 0;
	}
}

@keyframes rotate {
	0% {
		opacity: 0;
	}

	2% {
		opacity: 0;
		transform: translateY(-30px);
	}

	5% {
		opacity: 1;
		transform: translateY(0px);
	}

	17% {
		opacity: 1;
		transform: translateY(0px);
	}

	20% {
		opacity: 0;
		transform: translateY(30px);
	}

	80% {
		opacity: 0;
	}

	100% {
		opacity: 0;
	}
}

/* --- Clear --- */
.clear:after {
	content: "";
	display: block;
	clear: both;
}

/* --- Image Caption --- */
.caption-wrap {
	display: table;
}

.image-caption {
	display: table-caption;
	caption-side: bottom;
}

/* --- Alert Boxes --- */
.info:before,
.success:before,
.warning:before,
.error:before {
	content: "";
	font-family: "FontAwesome";
	position: absolute;
	top: 20px;
	left: 20px;
	font-size: 1.3em;
	color: white;
}

.info:before {
	content: "\f05a";
}

.success:before {
	content: "\f058";
}

.warning:before,
.error:before {
	content: "\f071";
}

/* --- Check List --- */
li.check:before,
li.x:before {
	content: "";
	font-family: "FontAwesome";
	width: 16px;
	position: absolute;
	top: -1px;
	left: 0;
	font-size: 0.9em;
}

li.check:before {
	content: "\f00c";
	color: #19ad19;
}

li.x:before {
	content: "\f00d";
	color: #c02424;
}

.big-list {
	font-size: 1.2em;
	line-height: 1.35em;
}

ul.big-list li {
	padding-left: 28px;
}

@media (max-width: 479px) {
	li.check,
	li.x {
		margin-left: -10px;
	}
}

a[name]:not([name=""]),
h2[name]:not([name=""]),
h3[name]:not([name=""]),
h4[name]:not([name=""]),
h5[name]:not([name=""]) {
	scroll-margin-top: 100px;
}


/*** Top navigaton *****/
.div-block-12 {
	display: flex;
	width: 40%;
	justify-content: flex-end;
	align-items: flex-start;
	float: right;
}

.div-block-13 {
	margin-right: 10px;
}

.text-block-7 {
	padding-top: 3px;
}

.dropdown.topnavigation {
	display: block;
	width: auto;
	margin-top: 0px;
	padding: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 50px;
	background-color: #cbe9f3;
	background-image: url("/20160907154343/assets/images/arrow-icon-blue-01.png");
	background-position: 90% 50%;
	background-size: 15px;
	background-repeat: no-repeat;
	font-size: 0.9em;
	position: static;
	line-height: 1.15em;
}

.dropdown.topnavigation.active {
	background-color: #001446;
	background-image: url("/20160907154343/assets/images/arrow-icon-01.png");
}

.topnavigation-link,
.topnavigation-link:hover,
.topnavigation.open .topnavigation-link {
	display: block;
	margin-top: -2px;
	padding: 9px 35px 6px 15px;
	color: #222222;
	font-size: 1em;
	line-height: 1.15em;
	font-weight: 400;
	letter-spacing: 1px;
	text-decoration: none;
}

.dropdown.topnavigation.active .topnavigation-link,
.dropdown.topnavigation.active .topnavigation-link:hover,
.topnavigation.open.active .topnavigation-link {
	color: #fff;
}

.topnavigation-dropdown-list {
	left: auto;
	right: 0;
	z-index: 1000;
	overflow: hidden;
	width: auto;
	height: auto;
	padding-top: 1.8rem;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
	color: #fff;
	opacity: 0;
	position: absolute;
	transition: opacity 200ms ease 0s;
}

.dropdown.topnavigation:hover .topnavigation-dropdown-list,
.topnavigation.open .topnavigation-dropdown-list {
	opacity: 1;
	width: 100%;
}

.topnavigation-dropdown-list .topnavigation-dropdown-list-inner {
	background-color: #f6f7f7;
	box-shadow: 0 2px 5px #0003;
	display: none;
	max-width: 1100px;
	border-radius: 10px;
	padding: 30px 30px;
}

.dropdown.topnavigation:hover .topnavigation-dropdown-list .topnavigation-dropdown-list-inner,
.topnavigation.open .topnavigation-dropdown-list .topnavigation-dropdown-list-inner {
	display: block;
}

.or-text-block {
	margin-right: 10px;
	margin-left: 10px;
}

.text-block-8 {
	padding-top: 3px;
}

.div-block-12 div.or-text-block:last-child {
	display: none;
}

.topnavigation-dropdown-list-inner ul {
	margin: 0px;
	padding: 0px;
	columns: 3;
	width: 100%;
	list-style-type: none;
}

.topnavigation-dropdown-list-inner ul li {
	margin: 0px;
	padding: 0px;
	width: 100%;
}

.topnavigation-dropdown-list-inner ul li a {
	display: inline-block;
	width: 100%;
	padding: 10px 20px;
	color: #222222;
	font-size: 0.9rem;
	line-height: 1.25em;
	font-weight: 400;
	text-decoration: none;
	text-transform: none;
}

.topnavigation-dropdown-list-inner ul li a:hover,
.topnavigation-dropdown-list-inner ul li a.w--current {
	background-color: #e3e5e7;
	color: #222222;
	border-radius: 5px;
}

@media screen and (max-width: 767px) {
	.div-block-12 {
		width: 100%;
		float: none;
		align-items: center;
		margin-top: 15px;
		justify-content: center;
		margin-bottom: 10px;
	}

	.portfolio-title-container {
		margin-bottom: 30px;
	}

	.topnavigation-dropdown-list {
		padding-top: 20px;
	}

	.topnavigation-dropdown-list-inner ul {
		columns: 1;
	}
}

@media screen and (max-width: 479px) {
	.div-block-13 {
		display: none;
	}

	.breadcrumbs-div {
		padding-bottom: 0;
	}

	.breadcrumbs-div ol {
		display: flex;
		flex-wrap: nowrap;
		width: 100%;
	}

	.breadcrumbs-div ol li.breadcrumb-wrapper {
		white-space: nowrap;
		overflow: hidden;
		display: block !important;
		min-width: 26px;
	}

	.breadcrumbs-div ol li.home-breadcrumb-container {
		min-width: 34px;
	}

	.breadcrumbs-div ol li.breadcumbs-divider {
		min-width: 8px;
	}

	.breadcrumbs-div ol li .breadcrumb-a,
	.breadcrumbs-div ol li .breadcrumb-current {
		font-size: 0.75rem;
	}

	.breadcrumbs-div ol li.breadcrumb-hide-on-mobile {
		display: none !important;
	}

	.breadcrumbs-div ol li .breadcrumb-a,
	.breadcrumbs-div ol li .breadcrumb-current {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100%;
		display: inline-block;
	}
}

/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and (min-width: 991px) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and (min-width: 767px) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/***************** Print ***********************/
/*************************************************/
#printFooter {
	display: none;
	visibility: hidden;
}

#printHeader {
	display: none;
	visibility: hidden;
}

/*********************** Webflow **********************/
/*****************************************************/
@media (min-width: 992px) {
	html.w-mod-js:not(.w-mod-ix) [data-w-id="c6c1d6fd-a2d0-eba7-d8b2-641ed4a2b66f"] {
		-webkit-transform: translate3d(null, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		-moz-transform: translate3d(null, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		-ms-transform: translate3d(null, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		transform: translate3d(null, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="a6d2fbc5-20b3-dd46-8955-ae3952777e1e"] {
		-webkit-transform: translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		-moz-transform: translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		-ms-transform: translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		transform: translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="75d34ff7-817b-4752-c68b-3a7b0d05c8ca"] {
		-webkit-transform: translate3d(null, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		-moz-transform: translate3d(null, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		-ms-transform: translate3d(null, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		transform: translate3d(null, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="6cb572f2-5062-02d2-b42b-6b2048143e10"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="6cb572f2-5062-02d2-b42b-6b2048143e11"] {
		-webkit-transform: translate3d(null, -2rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		-moz-transform: translate3d(null, -2rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		-ms-transform: translate3d(null, -2rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		transform: translate3d(null, -2rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="6cb572f2-5062-02d2-b42b-6b2048143e1a"] {
		-webkit-transform: translate3d(0, 2rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		-moz-transform: translate3d(0, 2rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		-ms-transform: translate3d(0, 2rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		transform: translate3d(0, 2rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="6cb572f2-5062-02d2-b42b-6b2048143e28"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="6cb572f2-5062-02d2-b42b-6b2048143e29"] {
		-webkit-transform: translate3d(null, -2rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		-moz-transform: translate3d(null, -2rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		-ms-transform: translate3d(null, -2rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		transform: translate3d(null, -2rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="6cb572f2-5062-02d2-b42b-6b2048143e32"] {
		-webkit-transform: translate3d(0, 2rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		-moz-transform: translate3d(0, 2rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		-ms-transform: translate3d(0, 2rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		transform: translate3d(0, 2rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="6cb572f2-5062-02d2-b42b-6b2048143df8"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="6cb572f2-5062-02d2-b42b-6b2048143df9"] {
		-webkit-transform: translate3d(null, -2rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		-moz-transform: translate3d(null, -2rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		-ms-transform: translate3d(null, -2rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		transform: translate3d(null, -2rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="6cb572f2-5062-02d2-b42b-6b2048143e02"] {
		-webkit-transform: translate3d(0, 2rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		-moz-transform: translate3d(0, 2rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		-ms-transform: translate3d(0, 2rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		transform: translate3d(0, 2rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="6cb572f2-5062-02d2-b42b-6b2048143e40"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="6cb572f2-5062-02d2-b42b-6b2048143e41"] {
		-webkit-transform: translate3d(null, -2rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		-moz-transform: translate3d(null, -2rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		-ms-transform: translate3d(null, -2rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		transform: translate3d(null, -2rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="6cb572f2-5062-02d2-b42b-6b2048143e4e"] {
		-webkit-transform: translate3d(0, 2rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		-moz-transform: translate3d(0, 2rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		-ms-transform: translate3d(0, 2rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		transform: translate3d(0, 2rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="47a634ae-664c-4493-d2aa-dd61b405059f"] {
		-webkit-transform: translate3d(null, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		-moz-transform: translate3d(null, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		-ms-transform: translate3d(null, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		transform: translate3d(null, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="47a634ae-664c-4493-d2aa-dd61b40505a0"] {
		-webkit-transform: translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		-moz-transform: translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		-ms-transform: translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		transform: translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="47a634ae-664c-4493-d2aa-dd61b40505a1"] {
		-webkit-transform: translate3d(null, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		-moz-transform: translate3d(null, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		-ms-transform: translate3d(null, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		transform: translate3d(null, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
	}
}

@media (max-width: 991px) and (min-width: 768px) {
	html.w-mod-js:not(.w-mod-ix) [data-w-id="c5f658dd-5043-c45f-97b7-01f583c558dc"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="c5f658dd-5043-c45f-97b7-01f583c558cb"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="c5f658dd-5043-c45f-97b7-01f583c558d0"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="c5f658dd-5043-c45f-97b7-01f583c558d5"] {
		opacity: 0;
	}
}

@media (max-width: 767px) and (min-width: 480px) {
	html.w-mod-js:not(.w-mod-ix) [data-w-id="c5f658dd-5043-c45f-97b7-01f583c558dc"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="c5f658dd-5043-c45f-97b7-01f583c558cb"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="c5f658dd-5043-c45f-97b7-01f583c558d0"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="c5f658dd-5043-c45f-97b7-01f583c558d5"] {
		opacity: 0;
	}
}

@media (max-width: 479px) {
	html.w-mod-js:not(.w-mod-ix) [data-w-id="c5f658dd-5043-c45f-97b7-01f583c558dc"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="c5f658dd-5043-c45f-97b7-01f583c558cb"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="c5f658dd-5043-c45f-97b7-01f583c558d0"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="c5f658dd-5043-c45f-97b7-01f583c558d5"] {
		opacity: 0;
	}
}

.w-webflow-badge {
	display: none !important;
}

::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

::-webkit-scrollbar-thumb {
	background: #00a9e0;
}

::-webkit-scrollbar-track {
	background: #fff;
}

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

input {
	-webkit-user-select: text; /* Chrome, Opera, Safari */
	-moz-user-select: text; /* Firefox 2+ */
	-ms-user-select: text; /* IE 10+ */
	user-select: text; /* Standard syntax */
}

input::-webkit-input-placeholder {
	transition: 0.2s;
}

input:focus::-webkit-input-placeholder {
	opacity: 0;
}

input:-moz-placeholder {
	transition: 0.2s;
}

input:focus:-moz-placeholder {
	opacity: 0;
}

input::-moz-placeholder {
	transition: 0.2s;
}

input:focus::-moz-placeholder {
	opacity: 0;
}

input:-ms-input-placeholder {
	transition: 0.2s;
}

input:focus:-ms-input-placeholder {
	opacity: 0;
}

/* Bloop Animation */
.bloop-animation {
	position: relative;
}

.bloop-animation::before {
	content: '';
	height: 100px;
	width: 100px;
	position: absolute;
	margin: 0 auto;
	top: -50%;
	right: 0;
	left: 0;
	border-radius: 50%;
	background-color: #ffffff;
	display: none;
}

.section-button::before {
	height: 200px;
	width: 200px;
}

.solution-icon::before {
	height: 125px;
	width: 125px;
	top: 0;
}

.social-media-icon::before {
	height: 40px;
	width: 40px;
	top: -5px;
	left: -5px;
}

.search-icon::before {
	height: 60px;
	width: 60px;
	top: -10%;
	left: -10px;
}

.footer-social-icon-link::before {
	height: 50px;
	width: 50px;
	top: -10%;
	left: -9px;
}

.bloop-animation:hover:before {
	display: block;
	animation: bloop 300ms linear;
	animation-fill-mode: forwards;
}

@keyframes bloop {
	0% {
		transform: scale(0);
		opacity: 0.5;
	}

	100% {
		transform: scale(1);
		opacity: 0;
	}
}

[data-anim="roundtxt"] {
	-webkit-animation: spin 10s linear infinite;
	-moz-animation: spin 10s linear infinite;
	animation: spin 10s linear infinite;
}

@keyframes spin {
	100% {
		-moz-transform: rotate(-360deg);
		-webkit-transform: rotate(-360deg);
		transform: rotate(-360deg);
	}
}

.header__nav-link:after,
.footer__nav-link:after {
	content: "";
	background: rgba(#fff);
	display: block;
	position: relative;
	margin: -1px auto 0px auto;
	transition: all 300ms ease;
	height: 1px;
	width: 0%;
}

.header__nav-link:hover:after,
.footer__nav-link:hover:after {
	width: 100%;
}

[data-anim="link"]:after {
	content: "";
	background: rgba(#fff);
	display: block;
	position: relative;
	margin: -2px auto 0px auto;
	transition: all 300ms ease;
	height: 1px;
	width: 100%;
}

[data-anim="link"]:hover:after {
	width: 0%;
}

.invest__experts-link > div:after {
	content: "";
	background: rgba(#fff);
	display: block;
	position: relative;
	margin: -2px auto 0px auto;
	transition: all 300ms ease;
	height: 1px;
	width: 100%;
}

.invest__experts-link:hover > div:after {
	width: 0%;
}

.news__block:hover .news__cat-arrow {
	-webkit-transform: translate(6px, -6px);
	-ms-transform: translate(6px, -6px);
	transform: translate(6px, -6px);
}

.invest__parallax-img-wrap:hover .invest__parallax-arrow {
	top: 10px;
	right: 10px;
}

[data-anim="arrows-btn"]:hover [data-anim="arrows"] {
	left: 30px;
}

@keyframes color {
	0% {
		background-color: #007da6;
	}

	2% {
		background-color: #e9ae15;
	}

	33% {
		background-color: #e9ae15;
	}

	35% {
		background-color: #a32515;
	}

	66% {
		background-color: #a32515;
	}

	69% {
		background-color: #007da6;
	}

	100% {
		background-color: #007da6;
	}
}

.form__submit-wrap:hover .overflow__anim {
	right: -1.5rem;
}

.w-slider-dot {
	background-color: #00aee0;
	width: 0.7rem;
	height: 0.7rem;
}

/* Change color of the active dot by replacing the hex code*/
.w-slider-dot.w-active {
	background-color: #1a428a;
	width: 0.85rem;
	height: 0.85rem;
}

body {
	padding-right: 0px !important;
}

.w-form-done {
	display: none !important;
}

.sp-form-outer {
	display: none;
}

/*********************** Header **********************/
/*****************************************************/
.alert-section {
	display: none;
}

.search-form-wrapper {
	display: none;
}

.exit-cb-wrapper {
	display: none;
}

.banner-wrap p {
	color: inherit;
	line-height: inherit;
	font-family: inherit;
	font-size: inherit;
	font-weight: 500;
}

.banner-wrap .cms-website-content-region p:last-child {
	margin-bottom: 0px;
}

.banner-wrap a,
.banner-wrap span,
.banner-wrap td,
.banner-wrap li,
.banner-wrap h1,
.banner-wrap h2,
.banner-wrap h3,
.banner-wrap h4,
.banner-wrap h5,
.banner-wrap h5 {
	color: #ffff !important;
}

.body .header__columns {
	animation-name: header-fade;
	animation-duration: 1.5s;
	animation-delay: 0.2s;
	opacity: 0;
	transform: translate3d(0px, -130px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}

.logo30 {
	opacity: 0;
}

@keyframes header-fade {
	100% {
		opacity: 1;
		transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
	}
}


/***************** Accesible menu **********************/
/*****************************************************/
.header__nav li.dropdown .navigation-dropdown-copy ul li a.nav-content-wrap .icon-square {
	display: none;
}

.header__nav li.dropdown#products .navigation-dropdown-copy ul li a.nav-content-wrap .icon-square,
.header__nav li.dropdown#services .navigation-dropdown-copy ul li a.nav-content-wrap .icon-square {
	display: flex;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: 25px 25px;
}

.header__nav li.dropdown#products .navigation-dropdown-copy ul li:nth-child(1) a.nav-content-wrap .icon-square {
	background-image: url('/assets/images/cms.svg');
}

.header__nav li.dropdown#products .navigation-dropdown-copy ul li:nth-child(2) a.nav-content-wrap .icon-square {
	background-image: url('/assets/images/Asset-16.svg');
}

.header__nav li.dropdown#products .navigation-dropdown-copy ul li:nth-child(3) a.nav-content-wrap .icon-square {
	background-image: url('/assets/images/siteviz-connect.svg');
}

.header__nav li.dropdown#products .navigation-dropdown-copy ul li:nth-child(4) a.nav-content-wrap .icon-square {
	background-image: url('/assets/images/Asset-18.svg');
}

.header__nav li.dropdown#products .navigation-dropdown-copy ul li:nth-child(5) a.nav-content-wrap .icon-square {
	background-image: url('/assets/images/Asset-23.svg');
}

.header__nav li.dropdown#products .navigation-dropdown-copy ul li:nth-child(6) a.nav-content-wrap .icon-square {
	background-image: url('/assets/images/Asset-17.svg');
}

.header__nav li.dropdown#services .navigation-dropdown-copy ul li:nth-child(1) a.nav-content-wrap .icon-square {
	background-image: url('/assets/images/Asset-15.svg');
}

.header__nav li.dropdown#services .navigation-dropdown-copy ul li:nth-child(2) a.nav-content-wrap .icon-square {
	background-image: url('/assets/images/Asset-14.svg');
}

.header__nav li.dropdown#services .navigation-dropdown-copy ul li:nth-child(3) a.nav-content-wrap .icon-square {
	background-image: url('/assets/images/it-repair.svg');
}

.header__nav li.dropdown#services .navigation-dropdown-copy ul li:nth-child(4) a.nav-content-wrap .icon-square {
	background-image: url('/assets/images/hosting_1.svg');
}

.header__nav li.dropdown#services .navigation-dropdown-copy ul li:nth-child(5) a.nav-content-wrap .icon-square {
	background-image: url('/assets/images/Asset-20.svg');
}

.button-link.w-dropdown-toggle.has-children .w-icon-dropdown-toggle:before {
	top: 1.4px;
	position: relative;
}

.navigation-dropdown-copy-2 {
	position: absolute;
	left: 100%;
	background-color: transparent;
}

.navigation-dropdown._2 {
	margin-left: 14px;
	margin-bottom: 0px;
}

.text-block-66 {
	font-family: inherit;
	line-height: inherit;
	color: inherit;
	font-size: inherit;
	font-weight: inherit;
	border-bottom: 1px solid transparent;
	text-decoration: none;
}

.dropdown-link-1:hover .navigation-dropdown-copy-2 {
	display: block;
}

.navigation-dropdown-copy-2._2 {
	bottom: auto;
}

/****************** Mobile menu **********************/
/*****************************************************/
@media (min-width: 992px) {
	.mm-ocd,
	.mm-ocd .mobile-navigation-menu,
	.mm-ocd--open .mobile-navigation-menu,
	.mobile-navigation-menu {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.mm-ocd .mobile-navigation-menu,
	.mobile-navigation-menu {
		display: none !important;
	}

	.mm-ocd--open .mobile-navigation-menu {
		display: block !important;
	}
}


@media screen and (min-width: 767px) {
	.mobile-navigation-menu li[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and (min-width: 767px) {
	.mobile-navigation-menu li[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

.mobile-navigation-menu li[data-breakpoints] {
	display: none !important;
}

.mobile-navigation-menu li[data-breakpoints*="xs,sm"] {
	display: block !important;
}

.mobile-navigation-menu li a.nav-link {
	text-align: left;
	color: #1c2b3c;
	text-transform: capitalize;
	padding: 1.3rem 1rem 1.3rem 2rem;
	line-height: 1rem;
	font-size: 1rem;
	font-weight: 700;
	border-bottom: 1px solid transparent;
	text-decoration: none;
	transition: color 0.3s;
}

.mm-spn.mm-spn--navbar:after {
	text-align: left;
	color: #1c2b3c;
	text-transform: capitalize;
	opacity: 1;
	font-size: 1rem;
	font-weight: 700;
	transition: color 0.3s;
}

.mm-ocd__content {
	min-width: 100%;
	max-width: 100%;
	width: 100%;
	z-index: 10000;
}

.mm-spn li:before,
.mm-spn.mm-spn--navbar:before {
	opacity: 1;
	width: 12px;
	height: 12px;
	border-color: #1a428a;
}

.mm-spn li {
	border-bottom: 1px solid #e3e5e7;
}

.mm-spn a:not(:last-child):after {
	color: transparent;
}

ul.mm-spn--open:not(.mm-spn--parent) li.active,
ul.mm-spn--open:not(.mm-spn--parent) li:hover {
	background-color: var(--grip-blue);
}

ul.mm-spn--open:not(.mm-spn--parent) li.active a.nav-link,
ul.mm-spn--open:not(.mm-spn--parent) li:hover a.nav-link {
	color: #fff;
}

ul.mm-spn--open:not(.mm-spn--parent) li.active:before,
ul.mm-spn--open:not(.mm-spn--parent) li:hover:before {
	border-color: #fff;
}

.mm-spn.mm-spn--navbar.mm-spn--main:after {
	display: none;
}

.mm-spn.mm-spn--navbar.mm-spn--main ul {
	top: 0;
}

/********************* Homepage *********************/
/*****************************************************/
.cbtext p {
	color: inherit;
	line-height: inherit;
	font-weight: inherit;
	font-family: inherit;
	font-size: inherit !important;
}

.invest__heading.h2 {
	width: 70%;
}

.card-heading-holder {
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

.our-work .cbtext {
	width: 100%;
	margin: 0 auto;
	max-width: 1350px;
}

.our-work .cbtext .slider {
	width: 100%;
}

.portfolios-content-box.slider .icon-10.w-icon-slider-right {
	margin-left: 9px;
}

.hero__read.alt p {
	color: #fff;
	text-align: left;
	margin-bottom: 16px;
	font-size: 26px;
	line-height: 1.1em;
}

.hero__read.alt a {
	color: #fff;
	letter-spacing: 2px;
	text-transform: uppercase;
	font-size: 14px;
	line-height: 19px;
	white-space: nowrap;
	padding: 8px 16px;
	text-decoration: none;
	display: inline-block;
	border-bottom: 1px solid #ffffff1a;
	position: relative;
}

.hero__read.alt a:after {
	content: "";
	display: inline-block;
	height: 1px;
	width: 0;
	position: absolute;
	inset: auto 0 -1px;
	overflow: hidden;
	background-color: transparent;
	transition: all 0.5s ease;
}

.hero__read.alt a:hover:after {
	width: 100%;
	background-color: #fff;
}

@media screen and (min-width: 992px) {
	.bloglinkcontainer {
		height: 381px;
	}

	.project_wrapper,
	.newsimg {
		height: 100%;
	}

	.project_image-wrapper {
		border-radius: 20px;
	}

	.project_content-wrapper {
		border-radius: 20px;
	}

	.newsimg {
		border-radius: 15px;
	}

	.project_content-wrapper {
		height: 100%;
		opacity: 0;
	}
}

@media screen and (max-width: 991px) {
	.project_wrapper {
		height: 100%;
		max-height: 312px;
	}

	.newsimg {
		max-height: 100%;
		width: auto;
	}
}

@media screen and (max-width: 479px) {
	.project_wrapper {
		height: auto;
		max-height: none;
	}

	.overflow-hidden-4 {
		text-align: center;
	}

	.card-image {
		width: 85px;
		height: auto;
	}
}

@media screen and (max-width: 767px) {
	.portfolios-content-box.slider {
		display: block;
	}

	.portfolios-content-box.slider .slidecontent {
		display: none;
	}

	.portfolios-content-box.slider .slidecontent.mobile {
		display: block;
	}

	.portfolios-content-box.slider .left-arrow {
		top: 100%;
		left: calc(50% - 45px);
		margin-top: 10px;
	}

	.portfolios-content-box.slider .right-arrow {
		top: 100%;
		right: calc(50% - 45px);
		margin-top: 10px;
	}

	.our-work .button-link.black {
		margin-top: 45px;
		margin-right: 0px;
	}
}

@media screen and (max-width: 479px) {
	.invest__heading.h2 {
		width: 85%;
	}
}

/*********************** Slideshow ********************/
/*****************************************************/
.slider-text p {
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
	font-weight: inherit;
	color: inherit;
}

.slider-text p:last-child {
	margin-bottom: 0px;
}


.hero__heading.mod--2 {
	display: flex;
}

.hero__heading.mod--2 p {
	display: inline;
	margin-bottom: 0px;
	height: 100px;
	overflow: hidden;
	width: 100%;
	font-size: inherit;
	line-height: inherit;
	font-size: inherit;
	font-family: inherit;
}

.hero__heading.mod--2._1 p {
	color: #00a9e0;
}

.hero__heading.mod--2._2 p {
	color: #e9ae15;
}

.hero__heading.mod--2._3 p {
	color: #e03c31;
}
/****************** Inside Page ******************/
/*************************************************/
@media screen and (max-width: 991px) {
	iframe[src*="youtube"] {
		aspect-ratio: 16 / 9;
		height: auto;
		max-width: 100%;
	}
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

.breadcrumbs-div ol {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
}

.breadcrumbs-div ol li {
	display: inline-block;
	margin: 0px;
	padding: 0px;
}

.left-navigation-list {
	list-style-type: none;
	margin: 0px;
	padding: 0px;
	padding-bottom: 0;
	font-family: Outfit, sans-serif;
}

.body-2 .button-link {
	color: #181f25 !important;
}

.body-2 .logo30 {
	opacity: 1 !important;
}

.body-2 .logowhite {
	opacity: 0 !important;
}

.content-container.full {
	max-width: 800px;
}

.content-container.right .content-wrap {
	justify-content: flex-end;
}

.content-container.right .breadcrumbs-div {
	max-width: 1055px;
	float: right;
}

.left-sub-nav-div {
	list-style-type: none;
	margin: 0px;
	padding: 0px;
	margin-left: 8px;
	margin-right: 8px;
	padding-bottom: 0;
}

.left-nav-a {
	color: #181f25;
}

.left-nav-a.active {
	background-color: #ededed;
	color: #181f25;
	background-image: url('/20181016082423/assets/images/grip-arrow.svg');
	background-position: 12px 54%;
	background-repeat: no-repeat;
	background-size: 9px 12px;
	border-right-color: #a32615;
	border-radius: 10px;
	margin-bottom: 8px;
}

.left-nav-a.subnav-a {
	color: #181f25;
	font-size: 15px;
	padding-left: 32px;
	padding-right: 16px;
}

.left-nav-a.subnav-a.active {
	background-color: #ededed;
	border-left-color: #296f9e;
	border-radius: 10px;
	padding-right: 20px;
	color: #181f25;
}

.left-nav-a.subnav-ab {
	color: #181f25;
	font-size: 14px;
	padding-left: 32px;
	padding-right: 16px;
}

.left-nav-a.subnav-ab.active {
	background-color: #ededed;
	border-left-color: #296f9e;
	border-radius: 10px;
	padding-right: 20px;
	color: #181f25;
}

.left-navigation-list li {
	display: inline-block;
	width: 100%;
}

.left-nav-div {
	background-color: #f9f9f9;
	border-radius: 10px;
	padding-top: 8px;
	padding-bottom: 8px;
	font-family: Outfit, sans-serif;
	font-weight: 400;
}

.left-nav-a {
	transform-origin: 0%;
	color: #181f25;
	background-position: 0%;
	background-repeat: no-repeat;
	background-size: 0 0;
	border-radius: 10px;
	margin-left: 8px;
	margin-right: 8px;
	padding: 10px 20px 10px 27px;
	text-decoration: none;
	transition-duration: 0.2s;
	margin-top: 0px;
}

.left-nav-a:hover {
	background-color: #1a428a;
	color: #fff;
	background-image: url('/assets/images/grip-arrow.svg');
	background-position: 12px 54%;
	background-repeat: no-repeat;
	background-size: 9px 12px;
	border-right-color: #a32615;
	border-radius: 10px;
}

.left-nav-a.active {
	color: #181f25;
	background-color: #ededed;
	background-position: 4% 54%;
	background-size: 9px 12px;
	margin-top: 0;
	font-size: 16px;
	margin-bottom: 0px;
}

.has-children .left-nav-a.active {
	background-color: #1a428a;
	color: #fff;
	background-image: none;
	background-repeat: repeat;
	background-size: auto;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	margin-left: 8px;
	margin-right: 8px;
	margin-bottom: 0px;
}

.left-sub-nav-div {
	background-color: #ededed;
	border-right: 0px;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
	margin-left: 8px;
	margin-right: 8px;
	padding-top: 8px;
	padding-bottom: 8px;
}

.has-children li .left-nav-a.subnav-a {
	margin-bottom: 8px;
}

.has-children ul.left-sub-nav-div li:last-child .left-nav-a.subnav-a {
	margin-bottom: 0px;
}

.has-children li .left-nav-a.subnav-a.active {
	background-color: #e6e6e6;
	font-size: 15px;
	border-radius: 10px;
	color: #181f25;
	background-position: 4% 54%;
	background-size: 9px 12px;
	background-image: url('/assets/images/grip-arrow.svg');
	background-repeat: no-repeat;
}

.has-children li.has-children .left-nav-a.subnav-a.active {
	background-color: #e6e6e6;
	background-image: none;
	background-repeat: repeat;
	background-size: auto;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	margin-bottom: 0;
	font-size: 15px;
	font-weight: 500;
}

.left-sub-nav-div.grandchild {
	background-color: #e6e6e6;
	border: 1px solid #e6e6e6;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	padding-top: 0px;
	font-family: Outfit, sans-serif;
}

.left-nav-a.subnav-ab {
	padding: 10px 16px 10px 32px;
	font-size: 14px;
	line-height: 18px;
}

.has-children .subnav-ab.active,
.subnav-ab.active {
	color: #1d2329;
	background-color: #ededed;
	background-image: url('/assets/images/grip-arrow.svg');
	background-repeat: no-repeat;
	background-size: 9px 12px;
	margin-bottom: 0;
	padding-left: 32px;
	font-size: 14px;
	font-weight: 400;
}

@media screen and (max-width: 991px) {
	.content-container.right .right-side-col {
		max-width: 100%;
	}

	.content-container.full {
		margin-left: 0px;
		max-width: 100%;
	}

	.left-nav-a:hover {
		background-position: 5px 54%;
	}

	.left-nav-a.active {
		background-position: 5px 54%;
	}
}

/************** Right Sidebar ********************/
/*************************************************/
.right-side-col td img {
	max-width: none;
}

.right-side-col .box {
	border-top: 0px;
	border-bottom: 1px solid #e6e6e666;
	padding-bottom: 10px;
	display: block;
}

.right-side-col .box.tools {
	padding: 17px;
}

.right-side-col .box p {
	font-weight: 400;
	font-size: 16px;
	color: #454545;
	font-family: Outfit, sans-serif;
	line-height: 1.3em;
}

.right-side-col .box h4,
.right-side-col .box h3 {
	color: var(--grip-blue);
	margin-top: 0;
	margin-bottom: 2px;
	font-size: 18px;
	font-weight: 700;
	line-height: 24px;
}

.right-side-col .box td {
	vertical-align: top;
	padding-bottom: 12px;
}

.right-side-col .box .contentbox_item_image {
	width: 24px;
}

.right-side-col .box.documents td:first-child {
	padding-right: 7px;
}

.right-side-col .box.tools td:first-child img {
	margin-right: 5px;
}

.right-side-col a {
	color: var(--dark-slate-grey);
	text-decoration: underline;
}

.right-side-col a:hover {
	color: var(--dark-slate-grey);
	text-decoration: underline;
}

.right-side-col .box:last-child {
	border-bottom: 0px;
}

.right-side-col .box p:last-child {
	margin-bottom: 0px;
}

.right-side-col .box.posts-blocks a {
	display: inline-block;
	margin-bottom: 5px;
	line-height: initial;
}

.right-side-col .box.posts-blocks a:hover {
	text-decoration: none;
}

/*************** Left Sidebar ********************/
/************************************************/
.left-sidebar {
	width: 300px;
}

.left-sidebar .box {
	margin-top: 20px;
}

.left-sidebar .box,
.left-sidebar .box p {
	font-weight: 400;
	font-size: 16px;
	color: #454545;
	font-family: Outfit, sans-serif;
	line-height: 1.3em;
}

.left-sidebar a {
	color: var(--dark-slate-grey);
	text-decoration: underline;
}

.left-sidebar a:hover {
	color: var(--dark-slate-grey);
	text-decoration: underline;
}

/****************** Inside Page Styles ***************/
/*****************************************************/
.content-2 a {
	color: #e71c00;
	text-decoration: underline;
}

.content-2 a:hover {
	color: var(--grip-blue);
	text-decoration: none;
}

.content-2 li {
	margin-bottom: 5px;
}

.content-2 ol li:last-child,
.content-2 ul li:last-child {
	margin-bottom: 0px;
}

h1 {
	color: var(--grip-blue);
	font-size: 48px;
	line-height: 3rem;
}

h2 {
	color: var(--grip-blue);
	font-size: 36px;
	line-height: 40px;
}

h3 {
	color: var(--grip-blue);
	font-size: 28px;
}

h4 {
	color: var(--grip-blue);
	font-size: 24px;
}

h5 {
	color: var(--grip-blue);
	font-size: 18px;
	font-weight: 700;
	line-height: 22px;
}

h6 {
	color: var(--grip-blue);
	font-size: 16px;
}

@media screen and (max-width: 991px) {
	h2 {
		font-size: 38px;
		line-height: 42px;
	}

	h3 {
		font-size: 30px;
		line-height: 2rem;
	}
}

@media screen and (max-width: 479px) {
	h2 {
		font-size: 32px;
		font-weight: 400;
		line-height: 36px;
	}
}

h1 a {
	color: var(--grip-blue) !important;
}

h2 a {
	color: var(--grip-blue) !important;
}

h3 a {
	color: var(--grip-blue) !important;
}

h4 a {
	color: var(--grip-blue) !important;
}

h5 a {
	color: var(--grip-blue) !important;
}

h6 a {
	color: var(--grip-blue) !important;
}

.content-2 p {
	font-weight: 400;
	font-size: 16px;
	color: #454545;
	font-family: Outfit, sans-serif;
}

/* buttons */
.inside-row .button,
.inside-row a.primary,
.homeboxes a.primary,
.primary,
.inside-row button[type="submit"],
.inside-row .cms_form_button.primary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary,
.ui-form-buttons .ui-form-button button,
.module-search button {
	display: inline-block;
	text-transform: uppercase;
	color: #fff !important;
	background-color: #1a428a !important;
	border-radius: 50px;
	padding: 13px 16px;
	font-family: filson-pro, sans-serif;
	font-size: 14px;
	line-height: 18px;
	transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	width: auto;
	text-decoration: none !important;
	margin: 5px 5px 5px 0px;
	cursor: pointer;
	font-weight: 400;
}

.inside-row .button:hover,
.inside-row a.primary:hover,
.homeboxes a.primary:hover,
.primary:hover,
.inside-row button[type="submit"]:hover,
.inside-row .cms_form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
.ui-form-buttons .ui-form-button button:hover,
.module-search button:hover {
	color: #fff !important;
	background-color: #e03c31 !important;
}

.secondary,
.inside-row a.secondary,
.homeboxes a.secondary,
.inside-row button[type="submit"].secondary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary,
.inside-row .cms_form_button.secondary,
.inside-row .button.secondary,
#cookie-consent-form-container form .consent-footer .consent-form-dismiss-trigger {
	display: inline-block;
	text-transform: uppercase;
	color: #000 !important;
	background-color: #00a7e1 !important;
	border-radius: 50px;
	padding: 13px 16px;
	font-family: filson-pro, sans-serif;
	font-size: 14px;
	line-height: 18px;
	transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	width: auto;
	text-decoration: none !important;
	margin: 5px 5px 5px 0px;
	cursor: pointer;
	font-weight: 400;
}

.secondary:hover,
.inside-row a.secondary:hover,
.inside-row button[type="submit"].secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover,
.inside-row .cms_form_button.secondary:hover,
.inside-row .button:hover,
.homeboxes a.secondary:hover,
#cookie-consent-form-container form .consent-footer .consent-form-dismiss-trigger:hover {
	color: #fff !important;
	background-color: #001446 !important;
	text-decoration: none !important;
}

.tertiary,
.inside-row button[type="submit"].tertiary,
.cms_form_button.tertiary,
.inside-row a.tertiary,
.homeboxes a.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	display: inline-block;
	color: #001446 !important;
	background-color: #fff !important;
	border-radius: 0;
	background-image: url('/20181016082423/assets/images/arrow-right-336-svgrepo-com.svg');
	background-position: 97%;
	background-repeat: no-repeat;
	background-size: auto 12px;
	padding: 0 22px 0 0;
	font-family: Outfit, sans-serif;
	line-height: 16px;
	text-decoration: none;
	transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.tertiary:hover,
.inside-row a.tertiary:hover,
.inside-row button[type="submit"].tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover,
.homeboxes a.tertiary:hover {
	color: #001446 !important;
	background-color: #fff !important;
	text-decoration: none !important;
	background-position: 102%;
	text-decoration: underline;
}

/* messages */
#message.success {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	background-color: #7dda8f;
	border-left: 6px solid #7dda8f;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.2rem;
	padding-left: 60px;
}

#message.error {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	background-color: #ffa93b;
	border-left: 6px solid #ffa93b;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.2rem;
	padding-left: 60px;
}

#message.success p {
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.2rem;
	color: #000;
}

#message.error p {
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.2rem;
	color: #000;
}

#message.error p:last-child,
#message.success p:last-child {
	margin-bottom: 0;
}

/* tables */
table.styled {
	border-spacing: 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid #fff;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 1rem 1rem;
	text-align: left;
	background-color: #1d428a;
	opacity: 100%;
	color: white;
}

table.styled thead tr th p {
	color: #fff;
}

table.styled th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.75rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #00a7e1;
	opacity: 100%;
	color: #000000;
}

table.styled th a,
table.styled th a:visited {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
	border-bottom: 0px;
	background-color: transparent;
	transition: none;
}

table.styled th a:hover {
	color: inherit;
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
	font-family: Outfit, sans-serif;
	font-weight: 400;
}

table.styled.striped tr:nth-child(even) {
	background: #f4f6f9;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

.gr-form {
	background-color: #fff;
}

/* forms */
.inside-row input[type="text"],
.inside-row input[type="tel"],
.inside-row input[type="password"],
.inside-row input[type="email"],
.inside-row select,
.inside-row textarea,
.inside-row input[type="search"] {
	max-width: 100%;
	vertical-align: middle;
	padding: 8px 10px;
	border-style: solid;
	border-width: 1px;
	border-color: #333;
	font-size: 0.875rem;
	margin-bottom: 10px;
	background-color: #fff;
	color: #000;
	font-weight: normal;
}

.inside-row textarea {
	resize: vertical;
}

.inside-row label {
	font-weight: 400;
}

.inside-row option {
	font-weight: 600;
}

@media (max-width: 1149px) {
	.inside-row input[type="text"],
	.inside-row input[type="tel"],
	.inside-row input[type="password"],
	.inside-row input[type="email"],
	.inside-row select,
	.inside-row textarea,
	.inside-row input[type="search"] {
		width: 100% !important;
	}
}

.grip-grid > div {
	background-color: transparent !important;
}

hr {
	border-color: #65aedf;
	border: 0px;
	color: #65aedf;
	margin-bottom: 1.5em;
	margin-top: 1.5em;
	border-top: 1px solid;
}

blockquote {
	border-left: 5px solid #e2e2e2;
	padding: 10px 20px;
	margin-bottom: 20px;
	margin-left: 0;
}

blockquote p {
	color: #616161;
	font-size: 1.15em;
	line-height: 1.35em;
	margin-bottom: 0;
	margin-top: 0;
	font-family: filson-pro, sans-serif;
}

.accent_p,
.accent_p {
	display: block;
	border-bottom: 1px solid #65aedf;
	border-top: 1px solid #65aedf;
	color: #333;
	font-size: 1.25em;
	font-weight: 300;
	line-height: 1.55em;
	padding-bottom: 15px;
	padding-top: 15px;
	margin-bottom: 10px;
	margin-top: 0;
	font-family: filson-pro, sans-serif;
}

/********************** Footer **********************/
/****************************************************/
@-moz-document url-prefix('') {
	body {
		margin-bottom: 0px !important;
	}
}

.phone-desktop {
	display: inline-block;
}

.phone-mobile {
	display: none;
	color: #fff;
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

#GRIPFooterLogo {
	padding-top: 0px !important;
	line-height: 11px;
	float: right;
}

.footerbox .cms-website-content-region,
.footerbox .cms-website-content-region p {
	color: var(--black);
	text-align: left;
	margin-left: 0;
	font-family: Outfit, sans-serif;
	line-height: 1.45rem;
}

.footerbox .cms-website-content-region p {
	margin-bottom: 20px;
}

.footerbox .cms-website-content-region ul {
	grid-column-gap: 8px;
	grid-row-gap: 8px;
	flex-flow: column;
	padding-left: 16px;
	display: inline-block;
	margin-top: 2px;
	margin-bottom: 5px;
}

.footerbox .cms-website-content-region ul li {
	line-height: 1.2rem;
}

.footerbox .cms-website-content-region ul li a {
	color: var(--black);
	text-align: left;
	margin-left: 0;
	font-family: Outfit, sans-serif;
	border-bottom: 1px solid transparent;
	text-decoration: none;
	line-height: 1.6rem;
	display: inline-block;
	margin-bottom: 1px;
}

.footerbox .cms-website-content-region ul li a:hover {
	color: var(--light-blue-grip);
}

@media screen and (min-width: 767px) {
	.map-desktop {
		margin-top: 25px;
	}
}

@media screen and (max-width: 991px) {
	.phone-desktop {
		display: none;
	}

	.phone-mobile {
		display: inline-block;
	}

	#GRIPFooterLogo {
		margin-top: 2rem !important;
		float: none;
	}
}

@media screen and (max-width: 479px) {
	#GRIPFooterLogo {
		margin-left: auto;
		margin-right: auto;
	}
}

/********************** GDPR **********************/
/****************************************************/
#cookie-consent-container {
	background-color: #1a428a !important;
}


#cookie-consent-container.minimized {
	border: 0.5px solid var(--light-blue-grip);
}

#cookie-consent-form-container form .consent-header {
	text-align: center;
}

#cookie-consent-form-container form .consent-header img {
	float: none;
	margin: 0 auto;
}

@media screen and (max-width: 1200px) {
	#cookie-consent-container .consent-actions .consent-actions-container {
		flex-direction: row !important;
		min-width: 230px;
	}
}

@media screen and (max-width: 991px) {
	#cookie-consent-container {
		display: flex !important;
		flex-direction: column !important;
	}

	#cookie-consent-container .consent-actions {
		order: 2 !important;
		width: 100% !important;
	}

	#cookie-consent-container .consent-actions .consent-dismiss-trigger-container {
		position: absolute !important;
		top: 8px;
		right: 6px;
	}

	#cookie-consent-container .consent-actions a {
		margin-top: 5px;
	}

	#cookie-consent-container .consent-disclaimer {
		width: 100% !important;
		padding-right: 0px !important;
	}

	#cookie-consent-container .consent-actions .consent-actions-container {
		justify-content: center !important;
		align-items: center !important;
		flex-direction: row !important;
	}
}

@media screen and (max-width: 479px) {
	#cookie-consent-container .consent-actions a.consent-all-trigger,
	#cookie-consent-container .consent-actions a.reject-all-trigger,
	#cookie-consent-container .consent-actions,
	#cookie-consent-container .consent-disclaimer,
	#cookie-consent-container .consent-disclaimer p {
		font-size: 0.8rem !important;
	}

	#cookie-consent-container .consent-disclaimer {
		margin-top: 20px;
	}
}

/********************** Portfolios **********************/
/****************************************************/
.portfolios-container .content-container {
	max-width: 1100px !important;
}

.portfolios-container .content-2 {
	max-width: 100% !important;
}

.portfolios-container .content-col {
	position: relative;
}

.portfolios-container .portfolio-title {
	color: var(--grip-blue);
	width: 60%;
	float: left;
}

.portfolios-container #portfolio_module.cms_entity .portfolio-title {
	float: none;
	width: 100%;
}

.portfolios-container #portfolio_module.cms_entity .portfolio-side .cms-website-content-region p {
	font-family: Outfit, sans-serif;
	font-size: 16px;
}

.portfolios-container #portfolio_module.cms_entity .portfolio-section .title h2 {
	color: var(--grip-blue);
}

.portfolios-container #portfolio_module.cms_list .item .more-link,
.portfolios-container .recent-portfolios .item .more-link {
	color: var(--grip-blue);
	background-image: url('/assets/images/simple-arrow-grip-blue.svg');
	background-size: auto 12px;
	font-family: Outfit, sans-serif;
	transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
	background-position: 97%;
	background-repeat: no-repeat;
}

.portfolios-container #portfolio_module.cms_list .item .challenge,
.portfolios-container .recent-portfolios .item .challenge {
	font-family: Outfit, sans-serif;
}

.portfolios-container #portfolio_module.cms_entity .portfolio-section.websiteDiv a {
	background-color: #00a7e1 !important;
	color: #000 !important;
	padding-left: 5px !important;
	padding-right: 5px !important;
}

.portfolios-container #portfolio_module.cms_entity .portfolio-section.websiteDiv a:hover {
	background-color: #001446 !important;
	color: #fff !important;
}

#portfolio_module.cms_entity .portfolio-side .secondary {
	color: #000000 !important;
	background-color: #e03c31 !important;
	padding-left: 5px !important;
	padding-right: 5px !important;
	min-width: 170px;
	text-align: center;
}

@media screen and (max-width: 991px) {
	.portfolios-container .portfolio-title {
		margin-top: 0px;
	}
}

@media screen and (max-width: 767px) {
	.portfolios-container .portfolio-title {
		width: 100%;
		float: none;
	}
}

/***************** Gid web development **************/
/****************************************************/
.grid.grid-web-development .grid-box {
	background-color: #e6e6e666;
	border-radius: 10px;
	outline: 1px solid #fff;
	flex-flow: column;
	justify-content: flex-start;
	align-items: center;
	padding-top: 16px;
	padding-left: 16px;
	padding-right: 16px;
	transition: all 0.3s cubic-bezier(0.86, 0, 0.07, 1);
	margin-bottom: 2rem;
}

.grid.grid-web-development .grid-box.third {
	width: calc(33.33% - 2rem);
	margin-right: 2rem;
}

.grid.grid-web-development .grid-box:hover {
	outline-color: #e9ae15;
	outline-offset: -1px;
	border: 1px #000;
	outline-width: 1px;
	outline-style: solid;
	transform: translate(0, -10px);
}

.grid.grid-web-development .grid-box h3 {
	color: #1a428a;
	font-size: 18px;
	font-weight: 700;
	line-height: 22px;
	margin-bottom: 10px;
}

.grid.grid-web-development .grid-box h3 a {
	border-bottom: 1px solid transparent;
	text-decoration: none;
}

.grid.grid-web-development .grid-box p {
	text-align: center;
	margin-bottom: 5px;
	font-family: Outfit, sans-serif;
	font-size: 16px;
}

.grid.grid-web-development .grid-box p a {
	color: var(--black);
}

.grid.grid-web-development .grid-box img {
	max-width: 100px;
	max-height: 100px;
	margin: 0px 0px 15px 0px;
}

@media screen and (max-width: 767px) {
	.grid.grid-web-development .grid-box.third {
		width: 100%;
		margin-right: 0;
	}
}
